import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@eng-ds/translate';
import { EmptyRouteComponent } from './components';
import {
  ClickStopPropagationDirective,
  ColorStatusCheckDirective,
  ColorStatusDirective,
  PopoverHostDirective,
} from './directives';
import { FormModule } from './form';
import { AssetUrlPipe } from './pipes';

@NgModule({
  imports: [FormModule, TranslateModule.forChild()],
  declarations: [
    AssetUrlPipe,
    EmptyRouteComponent,
    ColorStatusDirective,
    PopoverHostDirective,
    ColorStatusCheckDirective,
    ClickStopPropagationDirective,
  ],
  exports: [
    CommonModule,
    AssetUrlPipe,
    FormModule,
    ColorStatusDirective,
    ColorStatusCheckDirective,
    PopoverHostDirective,
    ClickStopPropagationDirective,
    TranslateModule,
  ],
})
export class SharedModule {}
