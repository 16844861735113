import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
import { LOCALE_ID, NgModule, StaticClassProvider } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateService } from '@eng-ds/translate';
import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { DynamicLocaleId } from './core/services/dynamic-locale-id';
import { SharedModule } from './shared/shared.module';
import { ThemeModule } from './theme/theme.module';

registerLocaleData(localeIt);

export const LocaleProvider: StaticClassProvider = {
  provide: LOCALE_ID,
  useClass: DynamicLocaleId,
  deps: [TranslateService],
};

export function storageFactory(): OAuthStorage {
  return localStorage;
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule.forRoot(),
    ThemeModule.forRoot(),
    SharedModule,
    OAuthModule.forRoot(),
  ],
  providers: [
    LocaleProvider,
    { provide: OAuthStorage, useFactory: storageFactory },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
