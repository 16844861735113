export const IT = {
  TITLE_PAGE_SCRIVANIA: 'Scrivania',
  TITLE_PAGE_LIST_PRATICHE: 'Lista pratiche',
  TITLE_PAGE_INFO_PRATICA: 'Info pratica',
  TITLE_PAGE_DETAIL_PRATICA: 'Dettaglio pratica',
  TITLE_PAGE_STATO_PRATICA: 'Stato pratica',
  TITLE_PAGE_PANN_SOGGETTI: 'Pannello soggetti',
  TITLE_PAGE_LIST_PAGAMENTI: 'Lista pagamenti',
  TITLE_PAGE_LIST_NOTIFICHE: 'Lista notifiche',
  TITLE_PAGE_LIST_DOCUMENTI: 'Lista documenti',
  TITLE_PAGE_AGENDA: 'Agenda',
  TITLE_PAGE_LIST_DELEGHE: 'Lista deleghe',
  TITLE_PAGE_NEW_DELEGA: 'Nuova delega',
  TITLE_PAGE_EDIT_DELEGA: 'Modifica delega',
  TITLE_PAGE_DETAIL_DELEGA: 'Dettaglio delega',
  TITLE_PAGE_SCRIVANIA_ASSOCIA_PROCEDIMENTO: 'Associa procedimento',
  TITLE_PAGE_SCRIVANIA_PANNELLO_SOGGETTI: 'Pannello soggetti',
  TITLE_PAGE_SCRIVANIA_AGGIUNGI_SOGGETTO: 'Aggiungi soggetti',

  AUTOCOMPLETE_TRANSLATE_PLACEHOLDER_ALL: 'Tutte',
  VALIDATIONS_REQUIRED: 'Campo richiesto',
  VALIDATIONS_PATTERN_EMAIL: 'Email non corretta',
  VALIDATIONS_PATTERN_PEC: 'Pec non corretta',
  VALIDATIONS_PATTERN_PHONE: 'Numero non valido',
  VALIDATIONS_PATTERN_DATE: 'Data non valida',
  VALIDATIONS_PATTERN_NAME: 'Inserire solo lettere alfabetiche',
  VALIDATIONS_PATTERN_CAP: 'CAP non valido',
  FORM_SAVE: 'Salva',
  FORM_FILTER: 'Filtra',
  FORM_SEARCH: 'Cerca',
  FORM_RESET: 'Cancella',
  UTILS_CANCEL: 'Annulla',
  UTILS_ERROR: 'Errore',
  UTILS_WARNING: 'Attenzione',
  UTILS_NOT_OTHER: 'No per conto terzi',
  UTILS_YES: 'Si',
  UTILS_NO: 'No',

  UTILS_LAST_UPDATE: 'Ultimo aggiornamento',
  UTILS_FACOLTATIVO: '(Facoltativo)',
  UTILS_REMOVE: 'Rimuovi',
  UTILS_CONFIRM: 'Conferma',
  UTILS_FOUND_RESULTS: 'risultati trovati',
  UTILS_TABLE_EMPTY: 'Nessun risultato trovato',
  UTILS_FORM_LABEL_NOT_MANDATORY: 'facoltativo',
  UTILS_TOASTR_ERROR_GENERIC_TITLE: 'Errore',

  UTILS_AUTOCOMPLETE_NOT_FOUND: 'Nessun risultato trovato',

  FUNZIONE_: '---',
  FUNZIONE_CapoUfficio: 'Capo Ufficio',
  FUNZIONE_Amministratore: 'Amministratore',
  FUNZIONE_RappresentanteLegale: 'Rappresentante Legale',
  DESKTOP_AGENDA_BREADCRUMB_HOME: 'Home',
  DESKTOP_AGENDA_BREADCRUMB_DESKTOP: 'Scrivania',
  DESKTOP_AGENDA_BREADCRUMB_AGENDA: 'Agenda',
  PROFILE_MALE_SEX_OPTION: 'Maschio',
  PROFILE_FEMALE_SEX_OPTION: 'Femmina',
  PROFILE_FORM_INPUT_CITY_LABEL: 'Città estera',
  PROFILE_FORM_INPUT_CITY_PLACEHOLDER: 'Inserisci città estera',
  PROFILE_FORM_INPUT_RESIDENTIAL_PROVINCE: 'Provincia di residenza',
  PROFILE_FORM_INPUT_RESIDENTIAL_CITY: 'Comune di residenza',
  PROCEDIBILE: 'Procedibile',
  NON_PROCEDIBILE: 'Non procedibile',
  NON_VALIDA: 'Non valida',
  ACCETTO: 'Accetto',
  NON_ACCETTO: 'Non accetto',
  REGOLARIZZATA: 'Regolarizzata',
  NON_REGOLARIZZATA: 'Non regolarizzata',
  INTEGRAZIONE_NON_IDONEA: 'Integrazione non idonea',
  ALTRO_PROC: 'Altro procedimento',
  VALIDA: 'Istanza ammissibile: comunicazione avvio',
  VALIDA_AVVIO_PROC:
    'Istanza ammissibile: comunicazione avvio procedimento attraverso notifica preliminare',
  NON_AMMISSIBILE: 'Istanza non valida: Nota con motivazioni di chiusura',
  ALTRO_PROCEDIMENTO:
    'Istanza gestita come altro procedimento: nota con motivazioni di chiusura',
  ESITO_POSITIVA: 'Positivo',
  ESITO_NEGATIVA: 'Negativo',
  ESITO_POSITIVO: 'Approvata',
  ESITO_NEGATIVO: 'Bocciata',
  POSITIVA: 'Positivo',
  NEGATIVA: 'Negativo',
  POSITIVO: 'Positivo',
  NEGATIVO: 'Negativo',
  RICHIESTA_INTEGRAZIONE: 'Richiedere integrazione',
  INVIO_PREAVVISO: 'Invio di preavviso',
  ARCHIVIAZIONE: 'Da archiviare',
  ISTANZA_COMPLETA: 'Istanza completa',

  DESKTOP_AGENDA_TITLE: 'Agenda',
  DESKTOP_AGENDA_SUBTITLE: 'scadenze trovate',
  DESKTOP_AGENDA_FILTER_BUTTON: 'Filtra scadenze',
  DESKTOP_AGENDA_FILTER_FORM_TYPE_LABEL: 'Tipologia',
  DESKTOP_AGENDA_FILTER_FORM_PRIORITY_LABEL: 'Priorità',

  DESKTOP_AGENDA_FILTER_FORM_PROCEDURE_LABEL: 'N. Pratica',
  DESKTOP_AGENDA_FILTER_FORM_PROCEDURE_PLACEHOLDER: 'Cerca per numero pratica',

  DESKTOP_AGENDA_FILTER_FORM_PROCIDING_LABEL: 'Nome procedimento',
  DESKTOP_AGENDA_FILTER_FORM_PROCIDING_PLACEHOLDER:
    'Cerca per nome procedimento',

  DESKTOP_AGENDA_MONTH: 'Mese',
  DESKTOP_AGENDA_WEEK: 'Settimana',
  DESKTOP_AGENDA_TODAY: 'Oggi',

  AGENDA_MODAL_NOTIFICATIONS_DEADLINE_DATE: 'Data scadenza',
  AGENDA_MODAL_NOTIFICATIONS_FORM_TIPOLOGY_LABEL: 'Tipologia notifica',
  AGENDA_MODAL_NOTIFICATIONS_FORM_TIPOLOGY_PLACEHOLDER: 'Scegli tipo notifica',
  AGENDA_MODAL_NOTIFICATIONS_FORM_WHEN_LABEL: 'Avverti',
  AGENDA_MODAL_NOTIFICATIONS_FORM_WHEN_PLACEHOLDER:
    'Scegli quando ricevere la notifica',
  AGENDA_MODAL_NOTIFICATIONS_FORM_TIME_LABEL: 'Giorni/Minuti/Ore prima',
  AGENDA_MODAL_NOTIFICATIONS_FORM_TIME_PLACEHOLDER:
    'Scegli se giorni/minuti/ore',
  AGENDA_MODAL_NOTIFICATIONS_ACTIVE_NOTIFICATIONS: 'Notifiche attive',
  AGENDA_MODAL_NOTIFICATIONS_BUTTONS_ADD: 'Aggiungi notifica',
  AGENDA_MODAL_NOTIFICATIONS_BUTTONS_SAVE: 'Salva',

  AGENDA_FILTRO_PRIORITA_BASSA: 'Bassa',
  AGENDA_FILTRO_PRIORITA_MEDIA: 'Media',
  AGENDA_FILTRO_PRIORITA_ALTA: 'Alta',

  AGENDA_FILTRO_TIPO_RICHIESTA: 'Richiesta',
  AGENDA_FILTRO_TIPO_INTEGRAZIONE: 'Integrazione',
  AGENDA_FILTRO_TIPO_VERIFICA: 'Verifica',

  AGENDA_FILTRO_UNITA_GIORNI: 'Giorni',
  AGENDA_FILTRO_UNITA_ORE: 'Ore',
  AGENDA_FILTRO_UNITA_MINUTI: 'Minuti',

  AGENDA_FILTRO_EMAIL: 'E-mail',

  AGENDA_GENERIC_ERROR_TITLE: 'ATTENZIONE',
  AGENDA_GENERIC_ERROR_DESC:
    'Si è verificato un errore. Si prega di riprovare più tardi',

  AGENDA_POPUP_SCADENZA: 'Scade tra',
  AGENDA_POPUP_TIPO: 'Tipo scadenza',
  AGENDA_POPUP_PRATICA: 'Pratica',
  AGENDA_POPUP_PROMEMORIA: 'Imposta promemoria',
  DESKTOP_NOTIFICHE_BREADCRUMB_HOME: 'Home',
  DESKTOP_NOTIFICHE_BREADCRUMB_DESKTOP: 'Scrivania',
  DESKTOP_NOTIFICHE_BREADCRUMB_NOTIFICHE: 'Notifiche',

  DESKTOP_NOTIFICHE_TITLE: 'Notifiche',
  DESKTOP_NOTIFICHE_SUBTITLE: 'risultati trovati',

  DESKTOP_NOTIFICHE_FILTER_BUTTON: 'Filtra notifiche',

  DESKTOP_NOTIFICHE_FILTER_FORM_STATE_LABEL: 'Stato',
  DESKTOP_NOTIFICHE_FILTER_FORM_TIPOLOGY_LABEL: 'Tipologia',

  DESKTOP_NOTIFICHE_FILTER_FORM_PROCEDURE_LABEL: 'N. Pratica',
  DESKTOP_NOTIFICHE_FILTER_FORM_PROCEDURE_PLACEHOLDER:
    'Cerca per numero pratica',

  DESKTOP_NOTIFICHE_FILTER_FORM_DATEFROM_LABEL: 'Data da',
  DESKTOP_NOTIFICHE_FILTER_FORM_DATETO_LABEL: 'Data a',

  DESKTOP_NOTIFICHE_LIST_COLUMNS_TITLE: 'Titolo Notifica',
  DESKTOP_NOTIFICHE_LIST_COLUMNS_DATE: 'Data',
  DESKTOP_NOTIFICHE_LIST_COLUMNS_TIPOLOGY: 'Tipologia',
  DESKTOP_NOTIFICHE_LIST_COLUMNS_STATE: 'Stato',

  DESKTOP_NOTIFICHE_LIST_DELETE_POP_CONFIRM_TITLE: 'Rimuovi notifica',
  DESKTOP_NOTIFICHE_LIST_DELETE_POP_CONFIRM_TEXT:
    'Confermi di voler rimuovere la notifica selezionata?',

  DESKTOP_NOTIFICHE_LIST_DELETE_ERROR_TITLE: 'Errore',
  DESKTOP_NOTIFICHE_LIST_DELETE_ERROR_TEXT:
    'Non è stato possibile eliminare la notifica selezionata, riprova più tardi',

  DESKTOP_NOTIFICHE_LIST_BUTTON_DELETE_TITLE: 'Elimina',
  DESKTOP_NOTIFICHE_LIST_BUTTON_ARCHIVE_TITLE: 'Archivia',

  DESKTOP_NOTIFICHE_LIST_DELETE_SUCCESS_TITLE: 'Notifica eliminata',
  DESKTOP_NOTIFICHE_LIST_DELETE_SUCCESS_TEXT:
    'La notifica selezionata è stata correttamente eliminata',

  DESKTOP_NOTIFICHE_LIST_ARCHIVE_SUCCESS_TITLE: 'Notifica archiviata',
  DESKTOP_NOTIFICHE_LIST_ARCHIVE_SUCCESS_TEXT:
    'La notifica selezionata è stata correttamente archiviata',

  DESKTOP_NOTIFICHE_LIST_COLUMNS_DETAIL_ATTACHMENTS: 'Nome Allegato',
  DESKTOP_NOTIFICHE_LIST_COLUMNS_DETAIL_PRATICAL_NUMBER: 'Numero Pratica',
  DESKTOP_NOTIFICHE_LIST_COLUMNS_DETAIL_TEXT: 'Dettaglio',

  DESKTOP_NOTIFICHE_LIST_DETAIL_BUTTON_DOWNLOAD: 'Scarica Allegati',
  DESKTOP_NOTIFICHE_LIST_DETAIL_BUTTON_PRACTICE: 'Vai alla pratica',

  DESKTOP_NOTIFICHE_ALL: 'Tutte',
  DESKTOP_NOTIFICHE_ARCHIVED: 'Archiviate',
  DESKTOP_NOTIFICHE_TOREAD: 'Da Leggere',

  DESKTOP_NOTIFICHE_STATE_TO_READ: 'Da Leggere',
  DESKTOP_NOTIFICHE_STATE_READED: 'Letta',
  DESKTOP_NOTIFICHE_STATE_ARCHIVIED: 'Archiviate',
  DESKTOP_NOTIFICHE_STATE_DELETED: 'Cancellata',

  DESKTOP_NOTIFICHE_TIPOLOGY_ALERT: 'Avvisi',
  DESKTOP_NOTIFICHE_TIPOLOGY_INFO: 'Informazioni',
  DESKTOP_NOTIFICHE_TIPOLOGY_PAYMENT: 'Pagamenti',

  NOTIFICHE_FILTRO_STATO_DALEGGERE: 'Da leggere',
  NOTIFICHE_FILTRO_STATO_LETTA: 'Letta',
  NOTIFICHE_FILTRO_STATO_CANCELLATA: 'Cancellata',
  NOTIFICHE_FILTRO_STATO_ARCHIVIATA: 'Archiviata',

  NOTIFICHE_FILTRO_TIPOLOGIA_AVVISI: 'Avvisi',
  NOTIFICHE_FILTRO_TIPOLOGIA_INFORMAZIONI: 'Informazioni',
  NOTIFICHE_FILTRO_TIPOLOGIA_PAGAMENTI: 'Pagamenti',

  DESKTOP_DELEGHE_BREADCRUMB_HOME: 'Home',
  DESKTOP_DELEGHE_BREADCRUMB_DESKTOP: 'Scrivania',
  DESKTOP_DELEGHE_BREADCRUMB_DELEGHE: 'Le mie deleghe',
  DESKTOP_DELEGHE_BREADCRUMB_NEW: 'Nuova delega',
  DESKTOP_DELEGHE_BREADCRUMB_EDIT: 'Modifica delega',
  DESKTOP_DELEGHE_BREADCRUMB_ASSOCIA_PROCEDIMENTI: 'Associa procedimenti',

  DESKTOP_DELEGHE_LIST_TITLE: 'Le mie deleghe',
  DESKTOP_DELEGHE_LIST_BUTTON_FILTER: 'Filtra deleghe',

  DESKTOP_DELEGHE_PERSONA_FISICA: 'Persona fisica',
  DESKTOP_DELEGHE_PERSONA_GIURIDICA: 'Persona giuridica',

  DESKTOP_DELEGHE_NEW_TITLE: 'Nuova delega',
  DESKTOP_DELEGHE_NEW_LEGAL_PERSON: 'Persona giuridica',
  DESKTOP_DELEGHE_NEW_NORMAL_PERSON: 'Persona fisica',

  DESKTOP_DELEGHE_STEP_DATA_TITLE: 'Dati delega',
  DESKTOP_DELEGHE_STEP_DOCUMENTS_TITLE: 'Allegati',
  DESKTOP_DELEGHE_STEP_PROCEDIMENTI_TITLE: 'Procedimenti',

  DESKTOP_DELEGHE_STEP_DATA_ACTIONS_BUTTONS_CANCEL: 'Annulla',
  DESKTOP_DELEGHE_STEP_DATA_ACTIONS_BUTTONS_SAVE_CONTINUE: 'Salva e continua',
  DESKTOP_DELEGHE_STEP_DATA_TABS_APPLICANT_DATA: 'Dati richiedente',
  DESKTOP_DELEGHE_STEP_DATA_TABS_DELEGATE_DATA: 'Dati delegato',
  DESKTOP_DELEGHE_STEP_DATA_TABS_INSERITORE_DATA: 'Dati inseritore',
  DESKTOP_DELEGHE_STEP_DATA_TABS_VALIDITY: 'Validità',

  DESKTOP_DELEGHE_STEP_DATA_TABS_PERSON_DATA_FORM_DENOMINAZIONE_LABEL:
    'Denominazione azienda',
  DESKTOP_DELEGHE_STEP_DATA_TABS_PERSON_DATA_FORM_DENOMINAZIONE_PLACEHOLDER:
    'Inserisci Ragione Sociale azienda',
  DESKTOP_DELEGHE_STEP_DATA_TABS_PERSON_DATA_FORM_ID_CODE_LABEL:
    'Codice identificativo',
  DESKTOP_DELEGHE_STEP_DATA_TABS_PERSON_DATA_FORM_ID_CODE_PLACEHOLDER:
    'Inserisci Codice azienda',
  DESKTOP_DELEGHE_STEP_DATA_TABS_PERSON_DATA_FORM_P_IVA_LABEL: 'Partita Iva',
  DESKTOP_DELEGHE_STEP_DATA_TABS_PERSON_DATA_FORM_P_IVA_PLACEHOLDER:
    'Inserisci Partita Iva',
  DESKTOP_DELEGHE_STEP_DATA_TABS_PERSON_DATA_FORM_I_CC_LABEL:
    'Iscrizione alla camera di commercio',
  DESKTOP_DELEGHE_STEP_DATA_TABS_PERSON_DATA_FORM_I_CC_PLACEHOLDER:
    'Inserisci iscrizione alla camera di commercio',
  DESKTOP_BPM_DIPENDENTI_FORM_LABEL_DATA_ISCRIZIONE_CCIA:
    'Data iscrizione alla camera di commercio',
  DESKTOP_DELEGHE_STEP_DATA_TABS_PERSON_DATA_FORM_PROVINCIA_REA_LABEL:
    'Provincia REA',
  DESKTOP_DELEGHE_STEP_DATA_TABS_PERSON_DATA_FORM_PROVINCIA_REA_PLACEHOLDER:
    'Inserisci provincia REA',
  DESKTOP_BPM_DIPENDENTI_FORM_LABEL_DATA_ISCRIZIONE_REA: 'Data iscrizione REA',
  DESKTOP_DELEGHE_STEP_DATA_TABS_PERSON_DATA_FORM_TIPO_SOCIETA_LABEL:
    'Tipo società',
  DESKTOP_DELEGHE_STEP_DATA_TABS_PERSON_DATA_FORM_TIPO_SOCIETA_PLACEHOLDER:
    'Seleziona tipo società',
  DESKTOP_DELEGHE_STEP_DATA_TABS_PERSON_DATA_FORM_TIPO_AZIENDA_LABEL:
    'Tipo azienda',
  DESKTOP_DELEGHE_STEP_DATA_TABS_PERSON_DATA_FORM_TIPO_AZIENDA_PLACEHOLDER:
    'Seleziona tipo azienda',
  DESKTOP_DELEGHE_STEP_DATA_TABS_PERSON_DATA_FORM_REA_LABEL: 'Numero REA',
  DESKTOP_DELEGHE_STEP_DATA_TABS_PERSON_DATA_FORM_REA_PLACEHOLDER:
    'Inserisci numero REA',
  DESKTOP_DELEGHE_STEP_DATA_TABS_PERSON_DATA_FORM_FISCAL_CODE_LABEL:
    'Codice Fiscale',
  DESKTOP_DELEGHE_STEP_DATA_TABS_PERSON_DATA_FORM_FISCAL_CODE_PLACEHOLDER:
    'Inserisci CF',
  DESKTOP_DELEGHE_STEP_DATA_TABS_PERSON_DATA_FORM_NAME_LABEL: 'Nome',
  DESKTOP_DELEGHE_STEP_DATA_TABS_PERSON_DATA_FORM_NAME_PLACEHOLDER:
    'Inserisci nome',
  DESKTOP_DELEGHE_STEP_DATA_TABS_PERSON_DATA_FORM_SURNAME_LABEL: 'Cognome',
  DESKTOP_DELEGHE_STEP_DATA_TABS_PERSON_DATA_FORM_SURNAME_PLACEHOLDER:
    'Inserisci cognome',
  DESKTOP_DELEGHE_STEP_DATA_TABS_PERSON_DATA_FORM_DATE_OF_BIRTH_LABEL:
    'Data di nascita',
  DESKTOP_DELEGHE_STEP_DATA_TABS_PERSON_DATA_FORM_DATE_OF_BIRTH_PLACEHOLDER:
    'Scegli data di nascita',
  DESKTOP_DELEGHE_STEP_DATA_TABS_PERSON_DATA_FORM_SEX_LABEL: 'Sesso',
  DESKTOP_DELEGHE_STEP_DATA_TABS_PERSON_DATA_FORM_SEX_PLACEHOLDER:
    'Scegli sesso',
  DESKTOP_DELEGHE_STEP_DATA_TABS_PERSON_DATA_FORM_FUNCTION_LABEL: 'Funzione',
  DESKTOP_DELEGHE_STEP_DATA_TABS_PERSON_DATA_FORM_FUNCTION_PLACEHOLDER:
    'Scegli funzione',
  DESKTOP_DELEGHE_STEP_DATA_TABS_PERSON_DATA_FORM_CF_LABEL: 'Codice Fiscale',
  DESKTOP_DELEGHE_STEP_DATA_TABS_PERSON_DATA_FORM_CF_PLACEHOLDER:
    'Cerca per CF',
  DESKTOP_DELEGHE_STEP_DATA_TABS_PERSON_DATA_FORM_CF_TITLE_P_FISICA:
    'Se il richiedente è già presente in anagrafica cercalo tramite codice fiscale per compilare automaticamente i campi',
  DESKTOP_DELEGHE_STEP_DATA_TABS_PERSON_DATA_FORM_CF_TITLE_P_FISICA_RAPPRESENTANTE:
    'Se il rappresentante è già presente in anagrafica cercalo tramite codice fiscale per compilare automaticamente i campi',
  DESKTOP_DELEGHE_STEP_DATA_TABS_PERSON_DATA_FORM_PIVA_LABEL: 'Partita Iva',
  DESKTOP_DELEGHE_STEP_DATA_TABS_PERSON_DATA_FORM_PIVA_PLACEHOLDER:
    'Cerca per Partita Iva',
  DESKTOP_DELEGHE_STEP_DATA_TABS_PERSON_DATA_FORM_CF_TITLE_P_GIURIDICA:
    'Se la società è già presente in anagrafica cercalo tramite Codice fiscale o Partita Iva per compilare automaticamente i campi',

  DESKTOP_DELEGHE_STEP_DATA_TABS_PERSON_DATA_FORM_LR: 'Legale Rappresentante',
  DESKTOP_DELEGHE_STEP_DATA_TABS_PERSON_DATA_FORM_LR_TITLE_P_GIURIDICA:
    'Se il rappresentante legale è già presente in anagrafica cercalo tramite Codice fiscale per compilare automaticamente i campi',
  DESKTOP_DELEGHE_STEP_DATA_TABS_PERSON_DATA_FORM_FISCAL_CODE_LEG_RAP_LABEL:
    'Codice Fiscale Legale Rappresentante',
  DESKTOP_DELEGHE_STEP_DATA_TABS_PERSON_DATA_FORM_FISCAL_CODE_LEG_RAP_PLACEHOLDER:
    'Cerca per CF',
  DESKTOP_DELEGHE_STEP_DATA_TABS_PERSON_DATA_FORM_CF_ERROR:
    'Codice Fiscale non valido',
  DESKTOP_DELEGHE_STEP_DATA_TABS_PERSON_DATA_FORM_PIVA_ERROR:
    'Partita Iva non valida',
  DESKTOP_NOTIFICHE_TEMPO_FORM: 'Tempo non valido',
  DESKTOP_DELEGHE_STEP_DATA_TABS_PERSON_DATA_FORM_ALFABETO_ERROR:
    'Inserire solo lettere alfabetiche',
  DESKTOP_DELEGHE_STEP_DATA_TABS_PERSON_DATA_FORM_OR: 'o',
  DESKTOP_DELEGHE_STEP_DATA_TABS_PERSON_DATA_FORM_FISCAL_CODE_RAP_LABEL:
    'CF rappresentante',
  DESKTOP_DELEGHE_STEP_DATA_TABS_PERSON_DATA_FORM_FISCAL_CODE_RAP_PLACEHOLDER:
    'Inserisci codice fiscale rappresentante',
  DESKTOP_DELEGHE_STEP_DATA_TABS_PERSON_DATA_FORM_NAME_LR_LABEL:
    'Nome rappresentante',
  DESKTOP_DELEGHE_STEP_DATA_TABS_PERSON_DATA_FORM_NAME_LR_PLACEHOLDER:
    'Inserisci nome rappresentante',
  DESKTOP_DELEGHE_STEP_DATA_TABS_PERSON_DATA_FORM_SURNAME_LR_LABEL:
    'Cognome rappresentante',
  DESKTOP_DELEGHE_STEP_DATA_TABS_PERSON_DATA_FORM_SURNAME_LR_PLACEHOLDER:
    'Inserisci cognome rappresentante',
  DESKTOP_DELEGHE_STEP_DATA_TABS_PERSON_DATA_FORM_NATION_LABEL:
    'Nazione di nascita',
  DESKTOP_DELEGHE_STEP_DATA_TABS_PERSON_DATA_FORM_NATION_PLACEHOLDER:
    'Scegli nazione',
  DESKTOP_DELEGHE_STEP_DATA_TABS_PERSON_DATA_FORM_SEDE_CCIA_LABEL:
    'Sede camera di commercio',
  DESKTOP_DELEGHE_STEP_DATA_TABS_PERSON_DATA_FORM_SEDE_CCIA_PLACEHOLDER:
    'Scegli sede camera di commercio',
  DESKTOP_DELEGHE_STEP_DATA_TABS_PERSON_DATA_FORM_PROVINCE_LABEL:
    'Provincia di nascita',
  DESKTOP_DELEGHE_STEP_DATA_TABS_PERSON_DATA_FORM_PROVINCE_PLACEHOLDER:
    'Scegli provincia',
  DESKTOP_DELEGHE_STEP_DATA_TABS_PERSON_DATA_FORM_CITY_LABEL:
    'Comune di nascita',
  DESKTOP_DELEGHE_STEP_DATA_TABS_PERSON_DATA_FORM_CITY_PLACEHOLDER:
    'Scegli comune',
  DESKTOP_DELEGHE_FORM_INPUT_CITY_LABEL: 'Comune',
  DESKTOP_DELEGHE_FORM_INPUT_CITY_PLACEHOLDER: 'Inserisci comune',
  DESKTOP_DELEGHE_STEP_DATA_TABS_PERSON_DATA_FORM_RESIDENCE: 'Residenza',
  DESKTOP_DELEGHE_STEP_DATA_TABS_PERSON_DATA_FORM_DOMICILIO:
    'Domicilio fiscale',
  DESKTOP_DELEGHE_STEP_DATA_TABS_PERSON_DATA_FORM_DOMICILIO_ALSO:
    ' (solo se diverso dalla Sede Legale)',
  DESKTOP_DELEGHE_STEP_DATA_TABS_PERSON_DATA_FORM_SEDE_LEGALE: 'Sede Legale',
  DESKTOP_DELEGHE_STEP_DATA_TABS_PERSON_DATA_FORM_ADDRESS_LABEL: 'Indirizzo',
  DESKTOP_DELEGHE_STEP_DATA_TABS_PERSON_DATA_FORM_ADDRESS_PLACEHOLDER:
    'Inserisci via/piazza/... e n.Civico',
  DESKTOP_DELEGHE_STEP_DATA_TABS_PERSON_DATA_FORM_CAP_LABEL: 'CAP',
  DESKTOP_DELEGHE_STEP_DATA_TABS_PERSON_DATA_FORM_CAP_PLACEHOLDER:
    'Inserisci CAP',
  DESKTOP_DELEGHE_STEP_DATA_TABS_PERSON_DATA_FORM_CAP_ERROR: 'CAP non valido',
  DESKTOP_DELEGHE_STEP_DATA_TABS_PERSON_DATA_FORM_NATION_RESIDENCE_LABEL:
    'Nazione',
  DESKTOP_DELEGHE_STEP_DATA_TABS_PERSON_DATA_FORM_NATION_RESIDENCE_PLACEHOLDER:
    'Scegli nazione',
  DESKTOP_DELEGHE_FORM_INPUT_CITY_RESIDENZA_LABEL: 'Comune',
  DESKTOP_DELEGHE_FORM_INPUT_CITY_NASCITA_LABEL: 'Comune',
  DESKTOP_DELEGHE_FORM_INPUT_CITY_RESIDENZA_PLACEHOLDER:
    'Scegli comune di residenza',
  DESKTOP_DELEGHE_FORM_INPUT_CITY_NASCITA_PLACEHOLDER:
    'Scegli comune di nascita',
  DESKTOP_DELEGHE_STEP_DATA_TABS_PERSON_DATA_FORM_SEDE_FISCALE: 'Sede fiscale',
  DESKTOP_DELEGHE_STEP_DATA_TABS_PERSON_DATA_FORM_SEDE_FISCALE_HINT:
    '(solo se diverso da sede legale)',
  DESKTOP_DELEGHE_STEP_DATA_TABS_PERSON_DATA_FORM_PROVINCE_RESIDENCE_LABEL:
    'Provincia',
  DESKTOP_DELEGHE_STEP_DATA_TABS_PERSON_DATA_FORM_PROVINCE_RESIDENCE_PLACEHOLDER:
    'Scegli provincia',
  DESKTOP_DELEGHE_STEP_DATA_TABS_PERSON_DATA_FORM_CITY_RESIDENCE_LABEL:
    'Comune',
  DESKTOP_DELEGHE_STEP_DATA_TABS_PERSON_DATA_FORM_CITY_RESIDENCE_PLACEHOLDER:
    'Scegli comune',
  DESKTOP_DELEGHE_STEP_DATA_TABS_PERSON_DATA_FORM_CONTACTS: 'Contatti',
  DESKTOP_DELEGHE_STEP_DATA_TABS_PERSON_DATA_FORM_EMAIL_LABEL: 'Email',
  DESKTOP_DELEGHE_STEP_DATA_TABS_PERSON_DATA_FORM_EMAIL_PLACEHOLDER:
    'Inserisci indirizzo email',

  DESKTOP_DELEGHE_STEP_DATA_TABS_PERSON_DATA_FORM_PEC_LABEL: 'PEC',
  DESKTOP_DELEGHE_STEP_DATA_TABS_PERSON_DATA_FORM_PEC_PLACEHOLDER:
    'Inserisci indirizzo posta certificata',
  DESKTOP_DELEGHE_STEP_DATA_TABS_PERSON_DATA_FORM_TELEFONO_LABEL: 'Telefono',
  DESKTOP_DELEGHE_STEP_DATA_TABS_PERSON_DATA_FORM_TELEFONO_PLACEHOLDER:
    'Inserisci n. Telefono',
  DESKTOP_DELEGHE_STEP_DATA_TABS_PERSON_DATA_FORM_TELEFONO_CELLULARE_LABEL:
    'Cellulare',
  DESKTOP_DELEGHE_STEP_DATA_TABS_PERSON_DATA_FORM_TELEFONO_CELLULARE_PLACEHOLDER:
    'Inserisci n. Cellulare',

  DESKTOP_DELEGHE_STEP_DATA_TABS_VALIDITY_BEGIN_LABEL:
    'Data inizio validità delega',
  DESKTOP_DELEGHE_STEP_DATA_TABS_VALIDITY_BEGIN_PLACEHOLDER: 'Inserisci data',
  DESKTOP_DELEGHE_STEP_DATA_TABS_VALIDITY_END_LABEL:
    'Data fine validità delega',
  DESKTOP_DELEGHE_STEP_DATA_TABS_VALIDITY_END_PLACEHOLDER: 'Inserisci data',

  DESKTOP_DELEGHE_STEP_DOCUMENTS_BUTTONS_ADD: 'Nuovo allegato',
  DESKTOP_DELEGHE_STEP_DOCUMENTS_LIST_COLUMNS_DOCUMENT_NAME: 'Nome allegato',
  DESKTOP_DELEGHE_STEP_DOCUMENTS_LIST_COLUMNS_APPLICANT_NAME:
    'Nome richiedente',
  DESKTOP_DELEGHE_STEP_DOCUMENTS_LIST_COLUMNS_DELEGATE_NAME: 'Nome delegato',
  DESKTOP_DELEGHE_STEP_DOCUMENTS_LIST_COLUMNS_DATE: 'Data caricamento',
  DESKTOP_DELEGHE_STEP_DOCUMENTS_ACTIONS_BUTTONS_BACK: 'Indietro',
  DESKTOP_DELEGHE_STEP_DOCUMENTS_ACTIONS_BUTTONS_CONTINUE: 'Prosegui',

  DESKTOP_DELEGHE_STEP_DOCUMENTS_MODAL_NEW_DOCUMENT_TITLE: 'Nuovo allegato',
  DESKTOP_DELEGHE_STEP_DOCUMENTS_MODAL_NEW_DOCUMENT_BUTTONS_UPLOAD: 'Carica',
  DESKTOP_DELEGHE_STEP_DOCUMENTS_MODAL_NEW_DOCUMENT_TEXT:
    'Seleziona il file da caricare e premi Carica',
  DESKTOP_DELEGHE_STEP_DOCUMENTS_MODAL_NEW_DOCUMENT_FILE_LABEL:
    'Puoi allegare solo file .pdf, .jpg, .png, .tiff, .p7m (dimensione massima 10mb)',
  DESKTOP_DELEGHE_STEP_DOCUMENTS_MODAL_NEW_DOCUMENT_FILE_BUTTON_PLACEHOLDER:
    'Scegli',
  DESKTOP_DELEGHE_STEP_DOCUMENTS_MODAL_NEW_DOCUMENT_FORM_APPLICANT_NAME_LABEL:
    'Denominazione/Nome richiedente',
  DESKTOP_DELEGHE_STEP_DOCUMENTS_MODAL_NEW_DOCUMENT_FORM_APPLICANT_NAME_PLACEHOLDER:
    'Denominazione o nome/cognome',
  DESKTOP_DELEGHE_STEP_DOCUMENTS_MODAL_NEW_DOCUMENT_FORM_CF_APPLICANT_LABEL:
    'CF/P. Iva richiedente',
  DESKTOP_DELEGHE_STEP_DOCUMENTS_MODAL_NEW_DOCUMENT_FORM_CF_APPLICANT_PLACEHOLDER:
    'CF o Partita Iva richiedente',
  DESKTOP_DELEGHE_STEP_DOCUMENTS_MODAL_NEW_DOCUMENT_FORM_DELEGATE_NAME_LABEL:
    'Denominazione/Nome delegato',
  DESKTOP_DELEGHE_STEP_DOCUMENTS_MODAL_NEW_DOCUMENT_FORM_DELEGATE_NAME_PLACEHOLDER:
    'Denominazione o nome/cognome',
  DESKTOP_DELEGHE_STEP_DOCUMENTS_MODAL_NEW_DOCUMENT_FORM_CF_DELEGATE_LABEL:
    'CF/P. Iva delegato',
  DESKTOP_DELEGHE_STEP_DOCUMENTS_MODAL_NEW_DOCUMENT_FORM_CF_DELEGATE_PLACEHOLDER:
    'CF o Partita Iva delegato',
  DESKTOP_DELEGHE_STEP_DOCUMENTS_MODAL_NEW_DOCUMENT_NAME_LABEL: 'Nome file',
  DESKTOP_DELEGHE_STEP_DOCUMENTS_MODAL_NEW_DOCUMENT_NAME_PLACEHOLDER:
    'Inserisci nome documento',
  DESKTOP_DELEGHE_STEP_DOCUMENTS_MODAL_DELETE_TITLE: 'Elimina allegato',
  DESKTOP_DELEGHE_STEP_DOCUMENTS_MODAL_DELETE_TEXT:
    'Confermi di eliminare l\'allegato selezionato?',
  DESKTOP_DELEGHE_STEP_DOCUMENTS_TOASTR_DELETE_SUCCESS_TITLE:
    'Allegato eliminato',
  DESKTOP_DELEGHE_STEP_DOCUMENTS_TOASTR_DELETE_SUCCESS_MESSAGE:
    'L\'allegato selezionato è stato correttamente eliminato',
  DESKTOP_DELEGHE_STEP_DOCUMENTS_TOASTR_DELETE_ERROR_TITLE: 'Errore',
  DESKTOP_DELEGHE_STEP_DOCUMENTS_TOASTR_DELETE_ERROR_MESSAGE:
    'Non è stato possibile eliminare l\'allegato selezionato, riprova più tardi',
  DESKTOP_DELEGHE_EDIT_TITLE: 'Modifica delega',
  DESKTOP_DELEGHE_STEP_DOCUMENTS_TOASTR_NEW_SUCCESS_TITLE: 'Allegato caricato',
  DESKTOP_DELEGHE_STEP_DOCUMENTS_TOASTR_NEW_SUCCESS_TEXT:
    'L\'allegato è stato correttamente caricato',
  DESKTOP_DELEGHE_STEP_DOCUMENTS_TOASTR_NEW_ERROR_TITLE: 'Errore',
  DESKTOP_DELEGHE_STEP_DOCUMENTS_TOASTR_NEW_ERROR_TEXT:
    'Non è stato possibile caricare l\'allegato, riprova più tardi',
  DESKTOP_DELEGHE_STEP_DOCUMENTS_TOASTR_NEW_ERROR_TYPE_TEXT:
    'Estensione del file non valida',
  DESKTOP_DELEGHE_STEP_DOCUMENTS_TOASTR_NEW_ERROR_SIZE_TEXT:
    'Superata dimensione massima, non superare i 10mb',
  DESKTOP_DELEGHE_STEP_PROCEDIMENTI_BUTTONS_ADD: 'Aggiungi procedimenti',

  DESKTOP_DELEGHE_STEP_PROCEDIMENTI_ACTIONS_BUTTONS_SAVE: 'Salva e chiudi',
  DESKTOP_DELEGHE_STEP_PROCEDIMENTI_LIST_COLUMNS_NAME: 'Nome procedimento',
  DESKTOP_DELEGHE_STEP_PROCEDIMENTI_LIST_COLUMNS_DATE_START:
    'Data inizio validità',
  DESKTOP_DELEGHE_STEP_PROCEDIMENTI_LIST_COLUMNS_DATE_END: 'Data fine validità',
  DESKTOP_DELEGHE_STEP_PROCEDIMENTI_LIST_COLUMNS_TIPO_DELEGA:
    'Tipologia delega',
  DESKTOP_DELEGHE_STEP_PROCEDIMENTI_LIST_COLUMNS_STATE: 'Stato',
  SCRIVANIA_FORM_FILTER_DELEGHE_TIPO_RICHIEDENTE_LABEL: 'Tipo Richiedente',
  SCRIVANIA_FORM_FILTER_DELEGHE_TIPO_RICHIEDENTE_PLACEHOLDER:
    'Cerca per tipo richiedente',
  SCRIVANIA_FORM_FILTER_DELEGHE_NOME_RICHIEDENTE_LABEL: 'Nome Richiedente',
  SCRIVANIA_FORM_FILTER_DELEGHE_NOME_RICHIEDENTE_PLACEHOLDER:
    'Cerca per nome richiedente',
  SCRIVANIA_FORM_FILTER_DELEGHE_NOME_GIU_RICHIEDENTE_LABEL:
    'Denominazione Richiedente',
  SCRIVANIA_FORM_FILTER_DELEGHE_NOME_GIU_RICHIEDENTE_PLACEHOLDER:
    'Cerca per denominazione richiedente',
  SCRIVANIA_FORM_FILTER_DELEGHE_COGNOME_RICHIEDENTE_LABEL:
    'Cognome Richiedente',
  SCRIVANIA_FORM_FILTER_DELEGHE_COGNOME_RICHIEDENTE_PLACEHOLDER:
    'Cerca per cognome richiedente',
  SCRIVANIA_FORM_FILTER_DELEGHE_CF_RICHIEDENTE_LABEL:
    'Codice Fiscale Richiedente',
  SCRIVANIA_FORM_FILTER_DELEGHE_P_IVA_RICHIEDENTE_LABEL:
    'Partita Iva Richiedente',
  SCRIVANIA_FORM_FILTER_DELEGHE_P_IVA_RICHIEDENTEPLACEHOLDER:
    'Cerca per P. Iva Richiedente',
  SCRIVANIA_FORM_FILTER_DELEGHE_CF_RICHIEDENTE_PLACEHOLDER:
    'Cerca per codice fiscale richiedente',
  MGU_FORM_FILTER_DELEGHE_NOME_DELEGATO_LABEL: 'Nome Delegato',
  MGU_FORM_FILTER_DELEGHE_NOME_DELEGATO_PLACEHOLDER: 'Cerca per nome delegato',
  MGU_FORM_FILTER_DELEGHE_COGNOME_DELEGATO_LABEL: 'Cognome Delegato',
  MGU_FORM_FILTER_DELEGHE_COGNOME_DELEGATO_PLACEHOLDER:
    'Cerca per cognome delegato',
  MGU_FORM_FILTER_DELEGHE_CF_DELEGATO_LABEL: 'Codice Fiscale Delegato',
  MGU_FORM_FILTER_DELEGHE_CF_DELEGATO_PLACEHOLDER:
    'Cerca per codice fiscale delegato',
  SCRIVANIA_FORM_FILTER_DELEGHE_STATO_LABEL: 'Stato',
  SCRIVANIA_FORM_FILTER_DELEGHE_STATO_PLACEHOLDER: 'Cerca per stato',
  SCRIVANIA_TABLE_COLUMN_NOME_RICHIEDENTE: 'Nome Richiedente',
  SCRIVANIA_TABLE_COLUMN_ID_DELEGA: 'ID delega',
  SCRIVANIA_TABLE_COLUMN_FUNZIONE: 'Funzione',
  SCRIVANIA_TABLE_COLUMN_STATO: 'Stato',
  SCRIVANIA_TABLE_COLUMN_FINE_VALIDITA: 'Fine validità',

  SCRIVANIA_NEW_DELEGA: 'Nuova delega',
  SCRIVANIA_DELEGHE_LIST_FETCH_ERROR_TEXT:
    'Non è stato possibile recuperare le deleghe, riprova più tardi',
  TOASTR_ERROR_EXPORT_MESSAGE:
    'Impossibile esportare il file richiesto, riprova più tardi',
  TOASTR_SUCCESS_EXPORT_TITLE: 'Operazione completata',
  TOASTR_SUCCESS_EXPORT_MESSAGE: 'File esportato con successo',
  EXPORT_LIST_BUTTON: 'Esporta elenco',

  SCRIVANIA_MODAL_NEW_DELEGA_PER_CONTO:
    'Stai richiedendo questa delega per tuo conto?',
  SCRIVANIA_MODAL_NEW_DELEGA_SUBTITLE:
    'Seleziona la tipologia di richiedente per il quale inserisci la delega',
  SCRIVANIA_MODAL_NEW_DELEGA_TITLE_FISICI: 'Soggetti fisici',
  SCRIVANIA_MODAL_NEW_DELEGA_TEXT_FISICI:
    'Necessari i dati del richiedente, del delegato e i documenti di delega',
  SCRIVANIA_MODAL_NEW_DELEGA_BUTTON_FISICI: 'Persona fisica',
  SCRIVANIA_MODAL_NEW_DELEGA_TITLE_GIURIDICI: 'Soggetti giuridici',
  SCRIVANIA_MODAL_NEW_DELEGA_TEXT_GIURIDICI:
    'Necessari i dati del richiedente (rappresentante legale), dell’azienda, del delegato e i documenti di delega',
  SCRIVANIA_MODAL_NEW_DELEGA_BUTTON_GIURIDICI: 'Persona giuridica',
  SCRIVANIA_DELEGHE_NEW_STEP_1_BUTTON_NEW_LR:
    'Inserisci Rappresentante non in anagrafica',
  SCRIVANIA_DELEGHE_NEW_STEP_1_BUTTON_SEARCH_WARNING:
    'Non è presente nell\' anagrafica',
  SCRIVANIA_DELEGHE_NEW_STEP_1_BUTTON_SEARCH_LR_SUCCESS_NOME: 'Nome',
  SCRIVANIA_DELEGHE_NEW_STEP_1_BUTTON_SEARCH_LR_SUCCESS_SURNOME: 'Cognome',
  SCRIVANIA_DELEGHE_NEW_STEP_1_BUTTON_SEARCH_LR_SUCCESS_DATANASCITA:
    'Data di nascita',
  SCRIVANIA_DELEGHE_NEW_STEP_1_BUTTON_SEARCH_LR_SUCCESS_PROVINCIANASCITA:
    'Provincia di nascita',
  SCRIVANIA_DELEGHE_NEW_STEP_1_BUTTON_SEARCH_LR_SUCCESS_COMUNENASCITA:
    'Comune di nascita',
  SCRIVANIA_DELEGHE_STEP_ASSOCIA_PROCEDIMENTI_FILTER_BUTTON:
    'Filtra procedimenti',
  SCRIVANIA_DELEGHE_STEP_ASSOCIA_PROCEDIMENTI_LIST_COLUMNS_NAME:
    'Nome procedimento',
  SCRIVANIA_DELEGHE_STEP_ASSOCIA_PROCEDIMENTI_LIST_COLUMNS_CODICE: 'Codice',
  SCRIVANIA_DELEGHE_STEP_ASSOCIA_PROCEDIMENTI_LIST_COLUMNS_CATEGORIA:
    'Categoria',
  SCRIVANIA_DELEGHE_STEP_ASSOCIA_PROCEDIMENTI_LIST_COLUMNS_STATE: 'Stato',
  SCRIVANIA_DELEGHE_STEP_ASSOCIA_PROCEDIMENTI_SUB_TITLE_ASS_PROC_NEW:
    'Nuova delega',
  SCRIVANIA_DELEGHE_STEP_ASSOCIA_PROCEDIMENTI_SUB_TITLE_ASS_PROC_UPDATE:
    'Modifica delega',
  SCRIVANIA_DELEGHE_STEP_ASSOCIA_PROCEDIMENTI_TITLE_ASS_PROC:
    'Associa procedimenti alla delega',
  SCRIVANIA_DELEGHE_STEP_ASSOCIA_PROCEDIMENTI_BREADCRUMB_ASS_PROC:
    'Associa procedimenti',
  SCRIVANIA_DELEGHE_STEP_ASSOCIA_PROCEDIMENTI_FILTER_FORM_TITOLO_LABEL:
    'Nome procedimento',
  SCRIVANIA_DELEGHE_STEP_ASSOCIA_PROCEDIMENTI_FILTER_FORM_CODICE_LABEL:
    'Codice procedimento',
  SCRIVANIA_DELEGHE_STEP_ASSOCIA_PROCEDIMENTI_FILTER_FORM_TITOLO_PLACEHOLDER:
    'Cerca per nome',
  SCRIVANIA_DELEGHE_STEP_ASSOCIA_PROCEDIMENTI_FILTER_FORM_CODICE_PLACEHOLDER:
    'Cerca per codice',
  SCRIVANIA_DELEGHE_STEP_ASSOCIA_PROCEDIMENTI_FILTER_FORM_CATEGORY_LABEL:
    'Categoria procedimento',
  SCRIVANIA_DELEGHE_STEP_ASSOCIA_PROCEDIMENTI_FILTER_FORM_CATEGORY_PLACEHOLDER:
    'Scegli categoria procedimento',
  SCRIVANIA_DELEGHE_STEP_ASSOCIA_PROCEDIMENTI_MODAL_FORM_START_DATE:
    'Inizio validità',
  SCRIVANIA_DELEGHE_STEP_ASSOCIA_PROCEDIMENTI_MODAL_FORM_TIPO_DELEGA_LABEL:
    'Tipo delega',
  SCRIVANIA_DELEGHE_STEP_ASSOCIA_PROCEDIMENTI_MODAL_FORM_TIPO_DELEGA_PLACEHOLDER:
    'Scegli tipo delega',
  SCRIVANIA_DELEGHE_STEP_ASSOCIA_PROCEDIMENTI_TIPO_DELEGA_FIRMA: 'Firma',
  SCRIVANIA_DELEGHE_STEP_ASSOCIA_PROCEDIMENTI_TIPO_DELEGA_OPERATIVA:
    'Operativa',
  SCRIVANIA_DELEGHE_STEP_ASSOCIA_PROCEDIMENTI_MODAL_FORM_END_DATE:
    'Fine validità',
  SCRIVANIA_DELEGHE_STEP_ASSOCIA_PROCEDIMENTI_MODAL_TITLE:
    'Imposta validità deleghe',
  SCRIVANIA_DELEGHE_STEP_ASSOCIA_PROCEDIMENTI_MODAL_ERROR_EMPTY_LIST:
    'Lista procedimenti vuota non valida',
  SCRIVANIA_DELEGHE_STEP_ASSOCIA_PROCEDIMENTI_MODAL_ERROR:
    'Impossibile aggiungere questi procedimenti alla delega, riprova più tardi',
  SCRIVANIA_DELEGHE_STEP_ASSOCIA_PROCEDIMENTI_MODAL_SUCCESS_TITLE:
    'Operazione completata',
  SCRIVANIA_DELEGHE_STEP_ASSOCIA_PROCEDIMENTI_MODAL_SUCCESS_MESSAGE:
    'Procedimenti aggiunti alla delega',
  SCRIVANIA_DELEGHE_STEP_ASSOCIA_PROCEDIMENTI_MODAL_MODIFY_VALIDITY:
    'Modifica validità delega',
  SCRIVANIA_DELEGHE_STEP_ASSOCIA_PROCEDIMENTI_TOASTR_ERROR_MODIFY_VALIDITY_TITLE:
    'Errore',
  SCRIVANIA_DELEGHE_STEP_ASSOCIA_PROCEDIMENTI_TOASTR_ERROR_MODIFY_VALIDITY_MESSAGE:
    'Non è stato possibile modificare le date di fine e inizio validità del procedimento scelto, riprova più tardi',
  SCRIVANIA_DELEGHE_STEP_ASSOCIA_PROCEDIMENTI_TOASTR_SUCCESS_MODIFY_VALIDITY_TITLE:
    'Validità procedimento modificato',
  SCRIVANIA_DELEGHE_STEP_ASSOCIA_PROCEDIMENTI_TOASTR_SUCCESS_MODIFY_VALIDITY_MESSAGE:
    'Le date d\'inizio e fine validità del procedimento selezionato sono state correttamente modificate',

  DESKTOP_DELEGHE_DETAIL_HEAD_ID: 'Id delega',
  DESKTOP_DELEGHE_DETAIL_HEAD_FUNCTION: 'Funzione',
  DESKTOP_DELEGHE_DETAIL_HEAD_KIND_APPLICANT: 'Tipo richiedente',
  DESKTOP_DELEGHE_DETAIL_HEAD_BEGIN_VALIDITY: 'Data inizio validità',
  DESKTOP_DELEGHE_DETAIL_HEAD_END_VALIDITY: 'Data fine validità',
  DESKTOP_DELEGHE_DETAIL_HEAD_STATE: 'Stato',
  DESKTOP_DELEGHE_DETAIL_HEAD_BUTTONS_TO_LIST: 'Torna alla lista',
  DESKTOP_DELEGHE_DETAIL_CREATION_DATE: 'Creato il',

  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_COMPANY_NAME: 'Denominazione azienda',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_I_CC:
    'Iscrizione camera di commercio',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_SEDE_CC: 'Sede camera di commercio',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_DATE_I_CC:
    'Data iscrizione camera di commercio',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_TIPO_SOCIETA_CC: 'Tipo società',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_TIPO_AZIENDA_CC: 'Tipo azienda',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_PROVINCE_REA: 'Provincia REA',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_CAPITALE_SOCIALE: 'Capitale sociale',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_SCADENZA_SOCIETA:
    'Data scadenza società',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_DURATA_SOCIETA: 'Durata società',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_NUMERO_ISCRIZIONE_AA:
    'Numero iscrizione Albo Autotrasporti',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_NUMERO_ISCRIZIONE_REN:
    'Numero iscrizione REN',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_LEGAL_REPRESENTED_FUNCTION:
    'Funzione rappresentante',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_SEDE_LEGALE_ADDRESS:
    'Indirizzo sede legale',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_SEDE_LEGALE_CAP: 'CAP sede legale',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_SEDE_LEGALE_NATION:
    'Nazione sede legale',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_SEDE_LEGALE_PROVINCE:
    'Provincia sede legale',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_SEDE_LEGALE_CITY:
    'Comune sede legale',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_SEDE_FISCALE_ADDRESS:
    'Indirizzo sede fiscale',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_SEDE_FISCALE_CAP: 'CAP sede fiscale',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_SEDE_FISCALE_NATION:
    'Nazione sede fiscale',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_SEDE_FISCALE_PROVINCE:
    'Provincia sede fiscale',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_SEDE_FISCALE_CITY:
    'Comune sede fiscale',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_CODE: 'Codice identificativo',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_PIVA: 'Partita Iva',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_LEGAL_ADDRESS: 'Indirizzo',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_LEGAL_CAP: 'CAP',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_LEGAL_NATION: 'Nazione',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_FUNZIONE_DELEGATO: 'Funzione',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_LEGAL_PROVINCE: 'Provincia',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_LEGAL_CITY: 'Comune',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_LEGAL_PEC: 'PEC',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_LEGAL_REPRESENTED_FISCAL_CODE:
    'CF rappresentante',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_LEGAL_REPRESENTED_NAME:
    'Nome rappresentante',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_LEGAL_REPRESENTED_SURNNAME:
    'Cognome rappresentante',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_FISCAL_CODE: 'Codice Fiscale',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_NAME: 'Nome',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_SURNAME: 'Cognome',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_DATE_OF_BIRTH: 'Data di nascita',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_SEX: 'Sesso',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_FUNCTION: 'Funzione',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_NATION: 'Nazione di nascita',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_PROVINCE: 'Provincia di nascita',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_CITY: 'Comune di nascita',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_EST_RESIDENZA:
    'Città estera di residenza',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_RESIDENCE: 'Residenza',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_ADDRESS: 'Indirizzo di residenza',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_CAP: 'CAP di residenza',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_NATION_RESIDENCE:
    'Nazione di residenza',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_PROVINCE_RESIDENCE:
    'Provincia di residenza',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_CITY_RESIDENCE: 'Comune di residenza',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_PHONE: 'Telefono',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_CELLULARE: 'Cellulare',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_EMAIL: 'Email',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_PROV_I_CC:
    'Provincia camera di commercio',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_REA: 'Numero REA',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_PEC: 'Pec',
  DESKTOP_DELEGHE_DETAIL_TABS_DOCUMENTS_LIST_COLUMNS_NAME: 'Nome allegato',
  DESKTOP_DELEGHE_DETAIL_TABS_DOCUMENTS_LIST_COLUMNS_APPLICANT_NAME:
    'Nome richiedente',
  DESKTOP_DELEGHE_DETAIL_TABS_DOCUMENTS_LIST_COLUMNS_DELEGATE_NAME:
    'Nome delegato',
  DESKTOP_DELEGHE_DETAIL_TABS_DOCUMENTS_LIST_COLUMNS_UPLOAD_DATE:
    'Data caricamento',
  DESKTOP_DELEGHE_DETAIL_TABS_DOCUMENTS_TOASTR_ERROR_FETCH_TITLE: 'Errore',
  DESKTOP_DELEGHE_DETAIL_TABS_DOCUMENTS_TOASTR_ERROR_FETCH_MESSAGR:
    'Non è stato possibile recuperare gli allegati della delega, riprova più tardi',
  DESKTOP_DELEGHE_DETAIL_TABS_DOCUMENTS_TOASTR_ERROR_DOWNLOAD_TITLE: 'Errore',
  DESKTOP_DELEGHE_DETAIL_TABS_DOCUMENTS_TOASTR_ERROR_DOWNLOAD_MESSAGE:
    'Non è stato possibile scaricare il documento selezionato, riprova più tardi',

  DESKTOP_DELEGHE_DETAIL_TABS_PROCESS_LIST_COLUMNS_NAME: 'Nome procedimento',
  DESKTOP_DELEGHE_DETAIL_TABS_PROCESS_LIST_COLUMNS_BEGIN_VALIDITY:
    'Data inizio validità',
  DESKTOP_DELEGHE_DETAIL_TABS_PROCESS_LIST_COLUMNS_END_VALIDITY:
    'Data fine validità',
  DESKTOP_DELEGHE_DETAIL_TABS_PROCESS_LIST_COLUMNS_TIPO_DELEGA:
    'Tipologia delega',
  DESKTOP_DELEGHE_DETAIL_TABS_PROCESS_LIST_COLUMNS_STATE: 'Stato',
  DESKTOP_DELEGHE_DETAIL_TABS_PROCESS_TOASTR_ERROR_FETCH_TITLE: 'Errore',
  DESKTOP_DELEGHE_DETAIL_TABS_PROCESS_TOASTR_ERROR_FETCH_MESSAGE:
    'Non è stato possibile recuperare i procedimenti della delega, riprova più tardi',
  SCRIVANIA_DELEGA_DETAIL_FETCH_ERROR_TEXT:
    'Non è stato possibile recuperare la delega, riprova più tardi',
  SCRIVANIA_DELEGA_PROCEDIMENTI_LIST_FETCH_ERROR_TEXT:
    'Non è stato possibile recuperare i procedimenti, riprova più tardi',

  DESKTOP_DELEGHE_TOASTR_FETCH_PERSON_REGISTRY_ERROR_TITLE: 'Errore',
  DESKTOP_DELEGHE_TOASTR_FETCH_PERSON_REGISTRY_ERROR_MESSAGE:
    'Non è stato possibile cercare il soggetto in anagrafica, riprova più tardi',
  DESKTOP_DELEGHE_TOASTR_FETCH_PERSON_REGISTRY_ERROR_NO_PERSON_TITLE:
    'Attenzione!',
  DESKTOP_DELEGHE_TOASTR_FETCH_PERSON_REGISTRY_ERROR_NO_PERSON_MESSAGE:
    'Non è stato trovato alcun soggetto corrispondente ai campi inseriti',
  DESKTOP_DELEGHE_TOASTR_FETCH_PERSON_ME_ERROR_NO_PERSON_MESSAGE:
    'Non puoi inserire te stesso in questa sezione',
  DESKTOP_DELEGHE_TOASTR_NEW_ERROR_TITLE: 'Errore',
  DESKTOP_DELEGHE_TOASTR_NEW_ERROR_MESSAGE:
    'Non è stato possibile creare la delega, riprova più tardi',
  DESKTOP_DELEGHE_TOASTR_EDIT_ERROR_TITLE: 'Errore',
  DESKTOP_DELEGHE_TOASTR_EDIT_ERROR_MESSAGE:
    'Non è stato possibile aggiornare i dati della delega, riprova più tardi',
  DESKTOP_DELEGHE_TOASTR_NEW_SUCCESS_TITLE: 'Delega creata',
  DESKTOP_DELEGHE_TOASTR_NEW_SUCCESS_MESSAGE:
    'La nuova delega è stata inserita nell\'archivio delle deleghe',
  DESKTOP_DELEGHE_TOASTR_EDIT_SUCCESS_TITLE: 'Dati delega aggiornati',
  DESKTOP_DELEGHE_TOASTR_EDIT_SUCCESS_MESSAGE:
    'I dati della delega sono stati correttamente aggiornati',

  DESKTOP_PRATICHE_BREADCRUMB_HOME: 'Home',
  DESKTOP_PRATICHE_BREADCRUMB_DESKTOP: 'Scrivania',
  DESKTOP_PRATICHE_BREADCRUMB_PRATICHE: 'Le mie pratiche',
  DESKTOP_PRATICHE_BREADCRUMB_DETAIL: 'Dettaglio pratica',
  DESKTOP_PRATICHE_BREADCRUMB_STATE: 'Stato pratica',
  DESKTOP_PRATICHE_BREADCRUMB_ASSOCIATE_USERS_ROLES: 'Associa utenti/ruoli',
  DESKTOP_PRATICHE_BREADCRUMB_ADD_USER_RICHIEDENTE: 'Aggiungi operatori',
  DESKTOP_PRATICHE_BREADCRUMB_ADD_USER_IMPIEGATO: 'Aggiungi impiegati',
  DESKTOP_PRATICHE_BREADCRUMB_ADD_USER_RESPONSABILE_PROCEDIMENTO_EXTRAUO:
    'Aggiungi responsabili ExtraUO',
  DESKTOP_PRATICHE_BREADCRUMB_ADD_USER_IMPIEGATO_UORD:
    'Aggiungi impiegati UORD',
  DESKTOP_PRATICHE_BREADCRUMB_ADD_USER_ISTRUTTORE:
    'Aggiungi responsabili pratica',
  DESKTOP_PRATICHE_BREADCRUMB_ADD_USER_RESPONSABILE_PROVVEDIMENTO:
    'Aggiungi responsabili firma',
  DESKTOP_PRATICHE_BREADCRUMB_ADD_USER_RESPONSABILE_PARERE:
    'Aggiungi responsabili di un parere',
  DESKTOP_PRATICHE_BREADCRUMB_ADD_USER_RESPONSABILE_PROCEDIMENTO:
    'Aggiungi responsabili procedimento',
  DESKTOP_PRATICHE_BREADCRUMB_ADD_USER_RESPONSABILE_ASSEGNAZIONE:
    'Aggiungi responsabile assegnazione',
  DESKTOP_PRATICHE_BREADCRUMB_ADD_USER_RESPONSABILE_UORD:
    'Aggiungi responsabile UORD',
  DESKTOP_PRATICHE_BREADCRUMB_ADD_USER_RESPONSABILE_SUA:
    'Aggiungi responsabile SUA',
  DESKTOP_PRATICHE_BREADCRUMB_ADD_USER_EMISSIONE_PARERE_ESTERNO:
    'Aggiungi responsabili di un parere esterno',

  DESKTOP_PRATICHE_LIST_TITLE: 'Le mie pratiche',
  DESKTOP_PRATICHE_LIST_FETCH_ERROR_TITLE: 'Errore',
  DESKTOP_PRATICHE_LIST_FETCH_ERROR_MESSAGE:
    'Non è stato possibile recuperare le pratiche, riprova più tardi',
  DESKTOP_PRATICHE_LIST_FILTER_BUTTON_SEARCH: 'Filtra pratiche',
  DESKTOP_PRATICHE_LIST_FILTER_BUTTON_ALL: 'Tutte',
  DESKTOP_PRATICHE_LIST_FILTER_BUTTON_SENT: 'Inviate',
  DESKTOP_PRATICHE_LIST_FILTER_BUTTON_DRAFT: 'Bozze',
  DESKTOP_PRATICHE_LIST_FILTER_FORM_TIPO_RICHIEDENTE_LABEL: 'Tipo richiedente',
  DESKTOP_PRATICHE_LIST_FILTER_FORM_TIPO_RICHIEDENTE_PLACEHOLDER:
    'Scegli tipo richiedente',
  DESKTOP_PRATICHE_LIST_FILTER_FORM_NAME_LABEL: 'Nome richiedente',
  DESKTOP_PRATICHE_LIST_FILTER_FORM_NAME_PLACEHOLDER:
    'Cerca per nome richiedente',
  DESKTOP_PRATICHE_LIST_FILTER_FORM_SURNAME_LABEL: 'Cognome richiedente',
  DESKTOP_PRATICHE_LIST_FILTER_FORM_SURNAME_PLACEHOLDER:
    'Cerca per cognome richiedente',
  DESKTOP_PRATICHE_LIST_FILTER_FORM_LEGAL_PERSON_NAME_LABEL:
    'Denominazione sociale',
  DESKTOP_PRATICHE_LIST_FILTER_FORM_LEGAL_PERSON_NAME_PLACEHOLDER:
    'Cerca per denominazione sociale',
  DESKTOP_PRATICHE_LIST_FILTER_FORM_PIVA_LABEL: 'Partita IVA',
  DESKTOP_PRATICHE_LIST_FILTER_FORM_PIVA_VALIDATION_ERROR:
    'Partita IVA non valida',
  DESKTOP_PRATICHE_LIST_FILTER_FORM_PIVA_PLACEHOLDER: 'Cerca per partita iva',
  DESKTOP_PRATICHE_LIST_FILTER_FORM_PROCEDIMENTO_LABEL: 'Procedimento',
  DESKTOP_PRATICHE_LIST_FILTER_FORM_PROCEDIMENTO_PLACEHOLDER:
    'Cerca per nome/numero procedimento',
  DESKTOP_PRATICHE_LIST_FILTER_FORM_PRATICA_NUMBER_LABEL: 'Numero pratica',
  DESKTOP_PRATICHE_LIST_FILTER_FORM_PRATICA_NUMBER_PLACEHOLDER:
    'Cerca per n. Pratica (8 cifre)',
  DESKTOP_PRATICHE_LIST_FILTER_FORM_PRATICA_ERROR_VALIDATION:
    'Deve essere un numero di 8 cifre',
  DESKTOP_PRATICHE_LIST_FILTER_FORM_DATE_PROTOCOL_FROM_LABEL:
    'Data protocollo da',
  DESKTOP_PRATICHE_LIST_FILTER_FORM_DATE_PROTOCOL_FROM_PLACEHOLDER:
    'gg/mm/aaaa',
  DESKTOP_PRATICHE_LIST_FILTER_FORM_DATE_PROTOCOL_TO_LABEL: 'Data protocollo a',
  DESKTOP_PRATICHE_LIST_FILTER_FORM_DATE_PROTOCOL_TO_PLACEHOLDER: 'gg/mm/aaaa',
  DESKTOP_PRATICHE_LIST_FILTER_FORM_DATE_PROTOCOL_PHASE_LABEL: 'Fase pratica',
  DESKTOP_PRATICHE_LIST_FILTER_FORM_DATE_PROTOCOL_PHASE_PLACEHOLDER:
    'Cerca per fase pratica',
  DESKTOP_PRATICHE_LIST_COLUMNS_PROCEDIMENTO: 'Procedimento',
  DESKTOP_PRATICHE_LIST_COLUMNS_PRATICA: 'N. Pratica',
  DESKTOP_PRATICHE_LIST_COLUMNS_PROTOCOL_DATE: 'Data protocollo',
  DESKTOP_PRATICHE_LIST_COLUMNS_CREATION_DATE: 'Data creazione',
  DESKTOP_PRATICHE_LIST_COLUMNS_STATE: 'Stato',
  DESKTOP_PRATICHE_LIST_COLUMNS_PHASE: 'Fase',
  DESKTOP_PRATICHE_DETAIL_HEAD_BUTTON_TO_LIST: 'Torna alle pratiche',
  DESKTOP_PRATICHE_DETAIL_HEAD_BUTTON_TO_PANEL: 'Pannello soggetti',
  DESKTOP_PRATICHE_ASSOCIATE_USERS_ROLES_BUTTON_TO_PRATICA: 'Vai alla pratica',
  DESKTOP_PRATICHE_DETAIL_HEAD_PRATICA_NUMBER: 'N. Pratica',
  DESKTOP_PRATICHE_DETAIL_HEAD_PROTOCOL_NUMBER: 'N. Protocollo',
  DESKTOP_PRATICHE_DETAIL_HEAD_PROTOCOL_DATE: 'Data protocollo',
  DESKTOP_PRATICHE_DETAIL_HEAD_APPLICANT: 'Richiedente',
  DESKTOP_PRATICHE_DETAIL_HEAD_NOTIFICATION_TO_READ: 'Notifiche da leggere',
  DESKTOP_PRATICHE_DETAIL_BUTTON_GO_TO_DETAIL: 'Apri dettaglio',
  DESKTOP_PRATICHE_DETAIL_APPLICANT_REGISTRY: 'Anagrafica richiedente',
  DESKTOP_PRATICHE_DETAIL_DELEGATE_REGISTRY: 'Anagrafica operatori',
  DESKTOP_PRATICHE_DETAIL_FIRMATARI_REGISTRY: 'Anagrafica firmatari',
  DESKTOP_PRATICHE_DETAIL_REGISTRY_FISCAL_CODE: 'Codice Fiscale',
  DESKTOP_PRATICHE_DETAIL_REGISTRY_PARITA_IVA: 'Partita Iva',
  DESKTOP_PRATICHE_DETAIL_REGISTRY_COD_ID: 'Codice Identificativo',
  DESKTOP_PRATICHE_DETAIL_REGISTRY_DEN: 'Denominazione',
  DESKTOP_PRATICHE_DETAIL_REGISTRY_NAME: 'Nome',
  DESKTOP_PRATICHE_DETAIL_REGISTRY_SURNAME: 'Cognome',
  DESKTOP_PRATICHE_DETAIL_REGISTRY_BIRTH_DATE: 'Data di nascita',
  DESKTOP_PRATICHE_DETAIL_REGISTRY_CITY_BIRTH: 'Comune di nascita',
  DESKTOP_PRATICHE_DETAIL_REGISTRY_PROVINCE: 'Provincia di nascita',
  DESKTOP_PRATICHE_DETAIL_REGISTRY_NATION: 'Nazione di nascita',
  DESKTOP_PRATICHE_DETAIL_REGISTRY_NATION_RESIDENCE: 'Nazione di residenza',
  DESKTOP_PRATICHE_DETAIL_REGISTRY_PROVINCE_RESIDENCE: 'Provincia di residenza',
  DESKTOP_PRATICHE_DETAIL_REGISTRY_CITY_RESIDENCE: 'Comune di residenza',
  DESKTOP_PRATICHE_DETAIL_REGISTRY_CITY_SEDE: 'Comune Sede Legale',
  DESKTOP_PRATICHE_DETAIL_REGISTRY_CITY_ESTERA_SEDE: 'Città estera Sede Legale',
  DESKTOP_PRATICHE_DETAIL_REGISTRY_CAP_SEDE: 'CAP Sede Legale',
  DESKTOP_PRATICHE_DETAIL_REGISTRY_NAZIONE_SEDE: 'Nazione Sede Legale',
  DESKTOP_PRATICHE_DETAIL_REGISTRY_PROVINCE_SEDE: 'Provincia Sede Legale',
  DESKTOP_PRATICHE_DETAIL_REGISTRY_CITY_SEDE_FISCALE: 'Comune Sede Fiscale',
  DESKTOP_PRATICHE_DETAIL_REGISTRY_CAP_SEDE_FISCALE: 'CAP Sede Fiscale',
  DESKTOP_PRATICHE_DETAIL_REGISTRY_NAZIONE_SEDE_FISCALE: 'Nazione Sede Fiscale',
  DESKTOP_PRATICHE_DETAIL_REGISTRY_PROVINCE_SEDE_FISCALE:
    'Provincia Sede Fiscale',
  DESKTOP_PRATICHE_DETAIL_REGISTRY_PROVINCIA_CC: 'Sede camera di commercio',
  DESKTOP_PRATICHE_DETAIL_REGISTRY_DATA_ISCRIZIONE_CC:
    'Data iscrizione camera di commercio',
  DESKTOP_PRATICHE_DETAIL_REGISTRY_INDIRIZZO_SEDE: 'Indirizzo Sede Legale',

  DESKTOP_PRATICHE_DETAIL_DOM_CITY_SEDE: 'Comune Domicilio Fiscale',
  DESKTOP_PRATICHE_DETAIL_DOM_CITY_ESTERA_SEDE:
    'Città estera Domicilio Fiscale',
  DESKTOP_PRATICHE_DETAIL_DOM_CAP_SEDE: 'CAP Domicilio Fiscale',
  DESKTOP_PRATICHE_DETAIL_DOM_NAZIONE_SEDE: 'Nazione Domicilio Fiscale',
  DESKTOP_PRATICHE_DETAIL_DOM_PROVINCE_SEDE: 'Provincia Domicilio Fiscale',
  DESKTOP_PRATICHE_DETAIL_DOM_INDIRIZZO_SEDE: 'Indirizzo Domicilio Fiscale',

  DESKTOP_PRATICHE_DETAIL_REGISTRY_REA: 'Numero REA',
  DESKTOP_PRATICHE_DETAIL_REGISTRY_REA_PROVINCIA: 'Provincia REA',
  DESKTOP_PRATICHE_DETAIL_REGISTRY_REA_DATA: 'Data iscrizione REA',
  DESKTOP_PRATICHE_DETAIL_REGISTRY_INDIRIZZO_SEDE_FISCALE:
    'Indirizzo Sede fiscale',
  DESKTOP_PRATICHE_DETAIL_REGISTRY_NUMERO_AUTOTRASPORTI:
    'Numero iscrizione albo autotrasporti',
  DESKTOP_PRATICHE_DETAIL_REGISTRY_REN: 'Numero iscrizione REN',
  DESKTOP_PRATICHE_DETAIL_REGISTRY_TIPO_AZIENDA: 'Tipo azienda',
  DESKTOP_PRATICHE_DETAIL_REGISTRY_TIPO_SOCIETA: 'Tipo società',
  DESKTOP_PRATICHE_DETAIL_REGISTRY_CAPITALE_SOCIETA: 'Capitale società',
  DESKTOP_PRATICHE_DETAIL_REGISTRY_SCADENZA_SOCIETA: 'Data scadenza società',
  DESKTOP_PRATICHE_DETAIL_REGISTRY_DURATA_SOCIETA: 'Durata società',
  DESKTOP_PRATICHE_DETAIL_REGISTRY_ISCRIZIONE_CC:
    'Iscrizione alla Camera di Commercio',
  DESKTOP_PRATICHE_DETAIL_NEXT_DEADLINE: 'Prossima scadenza',
  DESKTOP_PRATICHE_DETAIL_NEXT_DEADLINE_PRATICA: 'Pratica',
  DESKTOP_PRATICHE_DETAIL_FETCH_ERROR_TITLE: 'Errore',
  DESKTOP_PRATICHE_DETAIL_FETCH_ERROR_MESSAGE:
    'Non è stato possibile recuperare i dati della pratica, riprova più tardi',
  DESKTOP_PRATICHE_DETAIL_DELEGATI_FETCH_ERROR_TITLE: 'Errore',
  DESKTOP_PRATICHE_DETAIL_DELEGATI_FETCH_ERROR_MESSAGE:
    'Non è stato possibile recuperare i dati dei delegati, riprova più tardi',
  DESKTOP_PRATICHE_POPUP_NOTIFICHE_VEDI_TUTTE: 'Vedi tutte',
  DESKTOP_DELEGA_DETAIL_BUTTON_DOWNLOAD_DOCUMENT: 'Scarica documento',
  DESKTOP_DELEGA_DETAIL_BUTTON_EDIT_DELEGA: 'Modifica delega',
  DESKTOP_DELEGA_ALLEGATI_BUTTON_REMOVE_DOCUMENT: 'Cancella documento',
  DESKTOP_DELEGA_PROCEDIMENTI_BUTTON_EDIT_VALIDITY: 'Modifica validità',
  DESKTOP_PRATICHE_BUTTON_BELL: 'Notifiche',

  SCRIVANIA_ESITO_PAGAMENTO_TITLE: 'Esito del pagamento',
  SCRIVANIA_ESITO_PAGAMENTO_SUCCESS_TITLE: 'Pagamento effettuato con successo',
  SCRIVANIA_ESITO_PAGAMENTO_ERROR_TITLE: 'Pagamento non effettuato',
  SCRIVANIA_ESITO_PAGAMENTO_SUCCESS_MESSAGE:
    'Non ci sono stati problemi durante il processo di pagamento.',
  SCRIVANIA_ESITO_PAGAMENTO_ERROR_MESSAGE:
    'Qualcosa è andato storto durante il processo di pagamento',
  SCRIVANIA_ESITO_PAGAMENTO_TEXT_LIST:
    'In ogni momento puoi seguire lo stato dei pagamenti a te assegnati accedendo alla sezione ',
  SCRIVANIA_ESITO_PAGAMENTO_BUTTON_LIST: 'Lista Pagamenti',
  SCRIVANIA_ESITO_PAGAMENTO_BUTTON_TO_PRATICA: 'Torna alla pratica',
  DESKTOP_PRATICHE_BUTTON_USERS: 'Pannello soggetti',
  SCRIVANIA_STATO_PRATICA: 'Stato pratica',
  SCRIVANIA_STATO_PRATICA_FROM_PRACTICE: 'Torna alla pratica',
  SCRIVANIA_STATO_PRATICA_FROM_LIST: 'Torna alle pratiche',
  SCRIVANIA_STATO_PRATICA_PHASE: 'Fase',
  SCRIVANIA_STATO_PRATICA_HEAD_PHASE: 'Fase',
  SCRIVANIA_STATO_PRATICA_HEAD_NUMBER_PRACTICE: 'Numero pratica',
  SCRIVANIA_STATO_PRATICA_HEAD_STATE: 'Stato',
  SCRIVANIA_STATO_PRATICA_HEAD_RICHIEDENTE: 'Richiedente',
  SCRIVANIA_STATO_PRATICA_HEAD_ISTRUTTTORE: 'Istruttore',
  SCRIVANIA_STATO_PRATICA_HEAD_RESP_PROC: 'Responsabile procedimento',
  SCRIVANIA_STATO_PRATICA_HEAD_RESP_SUA: 'Responsabile SUA',
  SCRIVANIA_STATO_PRATICA_HEAD_RESP_PROVV: 'Resp. firma',

  DESKTOP_PRATICHE_ASSOCIATE_USERS_ROLES_BUTTON_TO_LIST: 'Torna alle pratiche',
  DESKTOP_PRATICHE_ASSOCIATE_USERS_ROLES_TITLE: 'Associa utenti/ruoli',
  DESKTOP_PRATICHE_ASSOCIATE_USERS_ROLES_SUB_TITLE: 'Pratica n.',
  DESKTOP_PRATICHE_ASSOCIATE_USERS_ROLES_LIST_COLUMNS_NAME: 'Nome utente',
  DESKTOP_PRATICHE_ASSOCIATE_USERS_ROLES_LIST_COLUMNS_FISCAL_CODE:
    'Codice Fiscale',
  DESKTOP_PRATICHE_ASSOCIATE_USERS_ROLES_LIST_COLUMNS_BEGIN_VALIDITY:
    'Inizio validità',
  DESKTOP_PRATICHE_ASSOCIATE_USERS_ROLES_LIST_COLUMNS_END_VALIDITY:
    'Fine validità',
  DESKTOP_PRATICHE_ASSOCIATE_USERS_ROLES_LIST_COLUMNS_MANAGE_VALIDITY:
    'Gestisci validità',
  DESKTOP_PRATICHE_ASSOCIATE_USERS_APPLICANT_NAME: 'Per conto di',
  DESKTOP_PRATICHE_ASSOCIATE_USERS_APPLICANT_FISCAL_CODE: 'Codice fiscale',
  DESKTOP_PRATICHE_ASSOCIATE_USERS_APPLICANT_PIVA: 'Partita Iva',
  DESKTOP_PRATICHE_ASSOCIATE_USERS_ROLES_RICHIEDENTE: 'Operatori',
  DESKTOP_PRATICHE_ASSOCIATE_USERS_ROLES_ADD_BUTTON_RICHIEDENTE:
    'Aggiungi operatori',

  DESKTOP_PRATICHE_ASSOCIATE_USERS_ROLES_FIRMATARIO: 'Firmatari',
  DESKTOP_PRATICHE_ASSOCIATE_USERS_ROLES_ADD_BUTTON_FIRMATARIO:
    'Aggiungi firmatari',
  DESKTOP_PRATICHE_BREADCRUMB_ADD_USER_FIRMATARIO: 'Aggiungi firmatari',

  DESKTOP_PRATICHE_ASSOCIATE_USERS_ROLES_IMPIEGATO: 'Responsabili istruttoria',
  DESKTOP_PRATICHE_ASSOCIATE_USERS_ROLES_ADD_BUTTON_IMPIEGATO:
    'Aggiungi responsabili',

  DESKTOP_PRATICHE_ASSOCIATE_USERS_ROLES_ISTRUTTORE: 'Responsabili pratica',
  DESKTOP_PRATICHE_ASSOCIATE_USERS_ROLES_ADD_BUTTON_ISTRUTTORE:
    'Aggiungi responsabili',

  DESKTOP_PRATICHE_ASSOCIATE_USERS_ROLES_RESPONSABILE_ASSEGNAZIONE:
    'Responsabile assegnazione',
  DESKTOP_PRATICHE_ASSOCIATE_USERS_ROLES_ADD_BUTTON_RESPONSABILE_ASSEGNAZIONE:
    'Aggiungi Resp. assegnazione',

  DESKTOP_PRATICHE_ASSOCIATE_USERS_ROLES_RESPONSABILE_UORD: 'Responsabile UORD',
  DESKTOP_PRATICHE_ASSOCIATE_USERS_ROLES_ADD_BUTTON_RESPONSABILE_UORD:
    'Aggiungi responsabile UORD',
  DESKTOP_PRATICHE_ASSOCIATE_USERS_ROLES_RESPONSABILE_PROVVEDIMENTO:
    'Responsabili firma',
  DESKTOP_PRATICHE_ASSOCIATE_USERS_ROLES_ADD_BUTTON_RESPONSABILE_PROVVEDIMENTO:
    'Aggiungi responsabili',

  DESKTOP_PRATICHE_ASSOCIATE_USERS_ROLES_RESPONSABILE_PARERE:
    'Responsabili di un parere',
  DESKTOP_PRATICHE_ASSOCIATE_USERS_ROLES_ADD_BUTTON_RESPONSABILE_PARERE:
    'Aggiungi responsabili',

  DESKTOP_PRATICHE_ASSOCIATE_USERS_ROLES_RESPONSABILE_PROCEDIMENTO:
    'Responsabili procedimento',
  DESKTOP_PRATICHE_ASSOCIATE_USERS_ROLES_ADD_BUTTON_RESPONSABILE_PROCEDIMENTO:
    'Aggiungi responsabili',
  DESKTOP_PRATICHE_ASSOCIATE_USERS_ROLES_IMPIEGATO_UORD: 'Impiegato UORD',
  DESKTOP_PRATICHE_ASSOCIATE_USERS_ROLES_RESPONSABILE_PROCEDIMENTO_EXTRAUO:
    'Responsabile procedimento extra UO',
  DESKTOP_PRATICHE_ASSOCIATE_USERS_ROLES_ADD_BUTTON_RESPONSABILE_PROCEDIMENTO_EXTRAUO:
    'Aggiungi resp. extra uo',
  DESKTOP_PRATICHE_ADD_USERS_LIST_COLUMNS_NAME: 'Nome utente',
  DESKTOP_PRATICHE_ADD_USERS_LIST_COLUMNS_FISCAL_CODE: 'Codice fiscale',
  DESKTOP_PRATICHE_ASSOCIATE_USERS_ROLES_ADD_BUTTON_IMPIEGATO_UORD:
    'Aggiungi impiegato UORD',
  DESKTOP_PRATICHE_ASSOCIATE_USERS_ROLES_RESPONSABILE_SUA: 'Responsabile SUA',
  DESKTOP_PRATICHE_ASSOCIATE_USERS_ROLES_ADD_BUTTON_RESPONSABILE_SUA:
    'Aggiungi Resp. SUA',
  DESKTOP_PRATICHE_ASSOCIATE_USERS_ROLES_EMISSIONE_PARERE_ESTERNO:
    'Resp. parere esterno',
  DESKTOP_PRATICHE_ASSOCIATE_USERS_ROLES_ADD_BUTTON_EMISSIONE_PARERE_ESTERNO:
    'Aggiungi Resp. parere esterno',

  DESKTOP_PRATICHE_ADD_USERS_MODAL_TITLE: 'Imposta validità',
  DESKTOP_PRATICHE_ADD_USERS_MODAL_TEXT:
    'Imposta il periodo di validità per il ruolo',
  DESKTOP_PRATICHE_ADD_USERS_MODAL_FORM_BEGIN_VALIDITY_DATE_LABEL:
    'Inizio validità',
  DESKTOP_PRATICHE_ADD_USERS_MODAL_FORM_BEGIN_VALIDITY_DATE_PLACEHOLDER:
    'Inizio validità',
  DESKTOP_PRATICHE_ADD_USERS_MODAL_FORM_END_VALIDITY_DATE_LABEL:
    'Fine validità',
  DESKTOP_PRATICHE_ADD_USERS_MODAL_FORM_END_VALIDITY_DATE_PLACEHOLDER:
    'Fine validità',

  DESKTOP_PRATICHE_ADD_USERS_MODAL_ERROR_SAVE_ASSOCIATIONS_TITLE: 'Errore',
  DESKTOP_PRATICHE_ADD_USERS_MODAL_ERROR_SAVE_ASSOCIATIONS_MESSAGE:
    'Non è stato possibile eseguire le associazioni degli utenti, riprova più tardi',
  DESKTOP_PRATICHE_ADD_USERS_MODAL_SUCCESS_SAVE_ASSOCIATIONS_TITLE:
    'Associazioni salvate',
  DESKTOP_PRATICHE_ADD_USERS_MODAL_SUCCESS_SAVE_ASSOCIATIONS_MESSAGE:
    'Le associazioni sono state correttamente salvate',

  DESKTOP_PRATICHE_ASSOCIATE_USERS_FETCH_ERROR_TITLE: 'Errore',
  DESKTOP_PRATICHE_ASSOCIATE_USERS_FETCH_ERROR_MESSAGE:
    'Non è stato possibile recuperare gli utenti della pratica selezionata, riprova più tardi',

  DESKTOP_PRATICHE_ADD_USERS_LIST_FETCH_ERROR_TITLE: 'Errore',
  DESKTOP_PRATICHE_ADD_USERS_LIST_FETCH_ERROR_MESSAGE:
    'Non è stato possibile recuperare la lista degli utenti associabili per questa funzione, riprova più tardi',
  DESKTOP_PRATICHE_DETAIL_DELEGATE_COLUMNS_NAME: 'Nome Operatore',
  DESKTOP_PRATICHE_DETAIL_DELEGATE_COLUMNS_FISCAL_CODE: 'Codice Fiscale',
  DESKTOP_PRATICHE_DETAIL_DELEGATE_COLUMNS_STATO: 'Stato',
  DESKTOP_PRATICHE_DETAIL_DOCUMENTI: 'Documenti',
  DESKTOP_PRATICHE_DETAIL_PAGAMENTI: 'Pagamenti',
  DESKTOP_PRATICHE_DETAIL_TABS_DOCUMENTS_TOASTR_ERROR_DOWNLOAD_TITLE: 'Errore',
  DESKTOP_PRATICHE_DETAIL_TABS_DOCUMENTS_TOASTR_ERROR_DOWNLOAD_MESSAGE:
    'Non è stato possibile scaricare il documento selezionato, riprova più tardi',
  DESKTOP_PRATICHE_DETAIL_BUTTON_DOWNLOAD_DOCUMENT: 'Scarica documento',
  QUADRO_PRATICHE_COM_MODALE_DOC_SCARICA: 'Scarica documento',
  QUADRO_PRATICHE_COM_MODALE_DOC: 'File comunicazione',
  DESKTOP_PRATICHE_DETAIL_TABS_DOCUMENTS_LIST_COLUMNS_NAME: 'Nome Documento',
  DESKTOP_PRATICHE_DETAIL_TABS_DOCUMENTS_LIST_COLUMNS_DOCUMENT_NAME:
    'Nome Documento',
  DESKTOP_PRATICHE_DETAIL_TABS_DOCUMENTS_LIST_COLUMNS_FILE_NAME: 'Nome File',
  DESKTOP_PRATICHE_DETAIL_TABS_DOCUMENTS_LIST_COLUMNS_UPLOAD_DATE:
    'Data di caricamento',
  DESKTOP_PRATICHE_DETAIL_TABS_DOCUMENTS_TOASTR_ERROR_FETCH_TITLE: 'Errore',
  DESKTOP_PRATICHE_DETAIL_TABS_DOCUMENTS_TOASTR_ERROR_FETCH_MESSAGR:
    'Non è stato possibile recuperare i documenti della pratica, riprova più tardi',
  DESKTOP_PRATICHE_DETAIL_LIST_FETCH_ERROR_TEXT:
    'Non è stato possibile recuperare le deleghe, riprova più tardi',

  DESKTOP_PRATICHE_CREA_COLLABORATORI_FILTER_BUTTON: 'Filtra operatori',
  DESKTOP_PRATICHE_CREA_COLLABORATORI_COLUMNS_NAME: 'Nome utente',
  DESKTOP_PRATICHE_CREA_COLLABORATORI_COLUMNS_FISCAL_CODE: 'Codice Fiscale',
  DESKTOP_PRATICHE_CREA_COLLABORATORI_FETCH_ERROR_TITLE: 'Errore',
  DESKTOP_PRATICHE_CREA_COLLABORATORI_FETCH_ERROR_TEXT:
    'Non è stato possibile recuperare gli utenti, riprova più tardi',
  DESKTOP_PRATICHE_CREA_COLLABORATORI_FILTER_FORM_NAME_LABEL: 'Nome',
  DESKTOP_PRATICHE_CREA_COLLABORATORI_FILTER_FORM_NAME_PLACEHOLDER:
    'Cerca per nome utente',
  DESKTOP_PRATICHE_CREA_COLLABORATORI_FILTER_FORM_SURNAME_LABEL: 'Cognome',
  DESKTOP_PRATICHE_CREA_COLLABORATORI_FILTER_FORM_SURNAME_PLACEHOLDER:
    'Cerca per cognome utente',
  DESKTOP_PRATICHE_CREA_COLLABORATORI_FILTER_FORM_FISCAL_CODE_LABEL:
    'Codice fiscale',
  DESKTOP_PRATICHE_CREA_COLLABORATORI_FILTER_FORM_FISCAL_CODE_PLACEHOLDER:
    'Cerca per CF utente',
  DESKTOP_PRATICHE_CREA_COLLABORATORI_TITLE: 'Aggiungi operatori',
  DESKTOP_PRATICHE_CREA_COLLABORATORI_TEXT:
    'Seleziona gli altri utenti, tra quelli abilitati, che possono lavorare in qualità di delegato alla pratica.',

  DESKTOP_USER_LIST_FILTER_BUTTON: 'Filtra operatori',
  DESKTOP_USER_LIST_COLUMNS_USERNAME: 'Nome',
  DESKTOP_USER_LIST_COLUMNS_FISCAL_CODE: 'Codice Fiscale',
  DESKTOP_USER_LIST_FETCH_ERROR_TITLE: 'Errore',
  DESKTOP_USER_LIST_FETCH_ERROR_TEXT:
    'Non è stato possibile recuperare gli utenti, riprova più tardi',
  DESKTOP_USER_LIST_FILTER_FORM_NAME_LABEL: 'Nome',
  DESKTOP_USER_LIST_FILTER_FORM_NAME_PLACEHOLDER: 'Cerca per nome utente',
  DESKTOP_USER_LIST_FILTER_FORM_SURNAME_LABEL: 'Cognome',
  DESKTOP_USER_LIST_FILTER_FORM_SURNAME_PLACEHOLDER: 'Cerca per cognome utente',
  DESKTOP_USER_LIST_FILTER_FORM_FISCAL_CODE_LABEL: 'Codice fiscale',
  DESKTOP_USER_LIST_FILTER_FORM_FISCAL_CODE_PLACEHOLDER: 'Cerca per CF utente',
  DESKTOP_COLLABORATORI_STEP1_PROCEDIMENTI_CORR_WARNING_MESSAGE:
    'Non puoi scegliere il operatore, è stato rimosso dalla selezione.',
  DESKTOP_COLLABORATORI_TITLE: 'Aggiungi operatori',
  DESKTOP_COLLABORATORI_SUBTITLE:
    'Seleziona gli altri utenti, tra quelli abilitati, che possono lavorare in qualità di delegato alla pratica.',

  DESKTOP_DOCUMENTI_TITLE: 'Allegati',
  DESKTOP_DOCUMENTI_SUBTITLE:
    'Allegare fotocopia di un documento di identità in corso di validità. Il documento non va trasmesso se la richiesta è sottoscritta con firma digitale o con altro tipo di firma elettronica qualificata. Il documento di identità deve essere allegato anche in caso di trasmissione dell’istanza a mezzo posta elettronica e/o posta elettronica certificata',
  DESKTOP_DOCUMENTI_ADD_BUTTON_NEW_DOCUMENTO: 'Nuovo documento',
  DESKTOP_DOCUMENTI_ADD_NEW_DOCUMENT_LABEL_INPUT_FILE:
    'Puoi allegare solo file .pdf, .jpg, .png, .tiff, .p7m (dimensione massima 10mb)',
  DESKTOP_DOCUMENTI_ADD_NEW_DOCUMENT_BUTTON_INPUT_FILE: 'Scegli',
  DESKTOP_DOCUMENTI_ADD_NEW_DOCUMENT_LABEL_INPUT_TEXT: 'Nome file',
  DESKTOP_DOCUMENTI_ADD_NEW_DOCUMENT_PLACEHOLDER_INPUT_TEXT:
    'Inserisci nome del documento',
  DESKTOP_DOCUMENTI_ADD_TABLE_DOCUMENTO_NOME: 'Nome documento',
  DESKTOP_DOCUMENTI_ADD_TABLE_DOCUMENTO_DATA: 'Data caricamento',
  DESKTOP_DOCUMENTI_LIST_FETCH_ERROR_TITLE: 'Errore',
  DESKTOP_DOCUMENTI_LIST_FETCH_ERROR_TEXT:
    'Non è stato possibile recuperare i procedimenti, riprova più tardi',
  DESKTOP_DOCUMENTI_ADD_NEW_DOCUMENT_ERROR_SIZE_MESSAGE:
    'Superata dimensione massima, non superare i 10mb',
  DESKTOP_DOCUMENTI_ADD_NEW_DOCUMENT_ERROR_EXTENCION_MESSAGE:
    'Estensione del file non valida',
  DESKTOP_DOCUMENTI_ADD_NEW_DOCUMENT_ERROR_MESSAGE:
    'È stato riscontrato un errore durante il caricamento del file, riprova più tardi',
  DESKTOP_DOCUMENTI_ADD_NEW_DOCUMENT_SUCCESS_TITLE: 'Documento salvato',
  DESKTOP_DOCUMENTI_ADD_NEW_DOCUMENT_SUCCESS_MESSAGE:
    'Il documento è stato correttamente salvato',
  DESKTOP_TAG_LIST_TOASTR_ERROR_DELETED_TITLE: 'Errore',
  DESKTOP_TAG_LIST_TOASTR_ERROR_DELETED_MESSAGE:
    'Non è stato possibile eliminare il tipo Tag selezionato, riprova più tardi',
  DESKTOP_DOCUMENTI_ADD_NEW_DOCUMENT_SUCCESS_DELETED_TITLE:
    'Documento eliminato',
  DESKTOP_DOCUMENTI_ADD_NEW_DOCUMENT_SUCCESS_DELETED_MESSAGE:
    'Il documento selezionato è stato correttamente eliminato',
  DESKTOP_DOCUMENTI_ADD_NEW_DOCUMENT_ERROR_SCARICARE_MESSAGE:
    'Non è stato possibile scaricare il documento selezionato, riprova più tardi',
  DESKTOP_DOCUMENTI_ADD_NEW_DOCUMENT_TITLE: 'Nuovo documento',
  DESKTOP_DOCUMENTI_ADD_NEW_DOCUMENT_TEXT:
    'Seleziona il file da caricare e premi Carica',
  DESKTOP_DOCUMENTI_ADD_NEW_DOCUMENT_BUTTON_CONFIRM: 'Carica',
  DESKTOP_DOCUMENTI_ADD_NEW_DOCUMENT_POP_CONFIRM_DELETE_TITLE:
    'Rimuovi documento',
  DESKTOP_DOCUMENTI_ADD_NEW_DOCUMENT_POP_CONFIRM_DELETE_TEXT:
    'Confermi di voler rimuovere il documento selezionato?',

  DESKTOP_PRIVACY_TITLE:
    'INFORMATIVA ai sensi del Regolamento 2016/679/UE – GDPR',
  DESKTOP_PRIVACY_CONSENSO: 'Presto il consenso',

  DESKTOP_PRATICHE_DETAIL_NOT_NEXT_DEADLINE:
    'Non è prevista nessuna scadenza per questa pratica',

  SCADENZA_TERMINE_MASSIMO_DESC: 'Scadenza termine massimo descrizione',
  SCADENZA_TERMINE_MASSIMO_TITOLO: 'Scadenza termine massimo',
  SCADENZA_INTEGRAZIONE_DESC: 'Scadenza per presentazione integrazione',
  SCADENZA_INTEGRAZIONE_TITOLO: 'Scadenza per integrazione',
  SCADENZA_PREAVV_DINIEGO_DESC: 'Scadenza a seguito del preavviso diniego',
  SCADENZA_PREAVV_DINIEGO_TITOLO: 'Scadenza preavviso diniego',
  TASK_NEXT_BUTTON_VERIFICA_PROCED_DESC:
    'Verifica la procedibilità dell’istanza inviata',
  TASK_NEXT_BUTTON_VERIFICA_PROCEDIBILITA: 'Verifica procedibilità',
  TASK_NEXT_BUTTON_VERIFICA_PREAVVISO_DESC: 'Verifica integrazione inserita',
  TASK_NEXT_BUTTON_VERIFICA_PREAVVISO: 'Verifica integrazione',
  TASK_NEXT_BUTTON_INOLTRO_DESC: 'Inoltra pratica',
  TASK_NEXT_BUTTON_INOLTRO: 'Inoltra',
  TASK_NEXT_BUTTON_DESC_APERTURA: 'Procedi con l\'apertura della pratica',
  TASK_NEXT_BUTTON_APERTURA: 'Inizializza pratica',
  TASK_NEXT_BUTTON_VERIFICA_DESC: 'Verifica la validità dell’istanza inviata',
  TASK_NEXT_BUTTON_VERIFICA: 'Verifica preliminare',
  TASK_NEXT_BUTTON_APERTURA_REG_ACC_DESC:
    'Apri il procedimento sul Registro Accessi',
  TASK_NEXT_BUTTON_APERTURA_REG_ACC: 'Apertura registro accessi',
  SCRIVANIA_STATO_PRATICA_TIMELINE: 'Timeline',

  TASK_NEXT_BUTTON_COMUNICA_PUBBL_DESC:
    'Comunica l’avvenuta pubblicazione del dato',
  TASK_NEXT_BUTTON_COMUNICA_PUBBL: 'Comunica pubblicazione',

  TASK_NEXT_BUTTON_CHIUSURA_REG_ACC_DESC:
    'Chiudi il procedimento sul Registro Accessi',
  TASK_NEXT_BUTTON_CHIUSURA_REG_ACC: 'Chiusura procedimento',

  TASK_NEXT_BUTTON_COMUNICA_LINK_DESC: 'Comunica il link del dato pubblicato',
  TASK_NEXT_BUTTON_COMUNICA_LINK: 'Comunica link',

  TASK_NEXT_BUTTON_COMUNICA_AUTORIZZAZIONE_DESC: 'Comunica autorizzazione',
  TASK_NEXT_BUTTON_COMUNICA_AUTORIZZAZIONE: 'Comunica autorizzazione',
  TASK_NEXT_BUTTON_COMUNICA_SG_DESC: 'Comunica la responsabilità del dato a SG',
  TASK_NEXT_BUTTON_COMUNICA_SG: 'Comunica a SG',
  TASK_NEXT_BUTTON_COMUNICA_PREAV_DINIEGO_DESC:
    'Invia comunicazione di preavviso diniego',
  TASK_NEXT_BUTTON_COMUNICA_PREAV_DINIEGO: 'Preav. diniego',
  TASK_NEXT_BUTTON_INSERISCI_INTEGRAZIONI_DESC:
    'Effettua l\'integrazione richiesta',
  TASK_NEXT_BUTTON_INSERISCI_INTEGRAZIONI: 'Inserisci integrazione',
  TASK_NEXT_BUTTON_VERIFICA_INTEGRAZIONI_DESC: 'Verifica integrazione inserita',
  TASK_NEXT_BUTTON_VERIFICA_INTEGRAZIONI: 'Verifica integrazione',
  TASK_NEXT_BUTTON_VERIFICA_PAGAMENTO_DESC: 'Verifica il pagamento effettuato',
  TASK_NEXT_BUTTON_VERIFICA_PAGAMENTO: 'Verifica pagamento',
  TIMELINE_TITLE_COM_AUTORIZZAZIONE: 'Approvato',
  TIMELINE_DESC_COM_AUTORIZZAZIONE: 'Comunicazione di autorizzazione',

  TIMELINE_TITLE_RICHIESTA_RINUNCIA: 'Richiesta di rinuncia',
  TIMELINE_DESC_RICHIESTA_RINUNCIA:
    'È stata eseguita una richiesta di rinuncia della pratica',
  TIMELINE_TITLE_COM_VERIFICA_RINUNCIA: 'Approvazione della rinuncia',
  TIMELINE_DESC_COM_VERIFICA_RINUNCIA:
    'È stato approvata la richiesta di rinuncia',
  TASK_NEXT_BUTTON_ASSEGNA_RESP_ISTR_DESC:
    'Desc. assegnazione responsabile dell\'istruttoria',
  TASK_NEXT_BUTTON_ASSEGNA_RESP_ISTR: 'Assegna Resp. Istr.',
  TASK_NEXT_BUTTON_ASSEGNA_RESP_PROC_DESC:
    'Desc. assegnazione responsabile del procedimento',
  TASK_NEXT_BUTTON_ASSEGNA_RESP_PROC: 'Assegna Resp. Proc.',

  TASK_NEXT_BUTTON_ACCETTAZIONE_RESP_DESC:
    'Accetta incarico da Responsabile',
  TASK_NEXT_BUTTON_ACCETTAZIONE_RESP: 'Accetta incarico',

  TASK_NEXT_BUTTON_COMUNICA_BO_DESC: 'Comunicazioni con il Responsabile',
  TASK_NEXT_BUTTON_COMUNICA_BO: 'Comunicazioni',
  TASK_NEXT_BUTTON_COMUNICA_FO_DESC: 'Comunicazioni con l\'Operatore',
  TASK_NEXT_BUTTON_COMUNICA_FO: 'Comunicazioni',
  TASK_NEXT_BUTTON_APPROVA_RINUNCIA: 'Approva rinuncia',
  TASK_NEXT_BUTTON_APPROVA_RINUNCIA_DESC:
    'Comunica approvazione della rinuncia',
  TASK_NEXT_BUTTON_RINUNCIA: 'Rinuncia',
  TASK_NEXT_BUTTON_RINUNCIA_DESC: 'Richiedi la rinuncia',
  TASK_NEXT_BUTTON_COMUNICA_RESP_PROC: 'Comunica Resp. Proc.',
  TASK_NEXT_BUTTON_COMUNICA_RESP_PROC_DESC:
    'Comunica il cambio del responsabile procedimento',

  SCRIVANIA_PAGAMENTI_BUTTON_DOWNLOAD_PAGAMENTO: 'Scarica pagamento',
  SCRIVANIA_PAGAMENTI_LIST_COLUMNS_OGGETTO: 'Oggetto',
  SCRIVANIA_PAGAMENTI_LIST_COLUMNS_DATA: 'Data',
  SCRIVANIA_PAGAMENTI_LIST_COLUMNS_NOME: 'Pratica',
  SCRIVANIA_PAGAMENTI_LIST_COLUMNS_NUMERO: 'N. Pratica',
  SCRIVANIA_PAGAMENTI_LIST_COLUMNS_STATE: 'Stato',
  SCRIVANIA_PAGAMENTI_LIST_COLUMNS_TIPO: 'Tipo',
  SCRIVANIA_PAGAMENTI_LIST_COLUMNS_IMPORTO: 'Importo',
  SCRIVANIA_PAGAMENTI_LIST_TOASTR_SUCCESS_DOWNNLOAD_TITLE:
    'Operazione completata',
  SCRIVANIA_PAGAMENTI_LIST_TOASTR_SUCCESS_DOWNNLOAD_MESSAGE:
    'Il pagamento è stato correttamente scaricato',
  SCRIVANIA_PAGAMENTI_LIST_TOASTR_ERROR_DOWNNLOAD_TITLE: 'Errore',
  SCRIVANIA_PAGAMENTI_LIST_TOASTR_ERROR_DOWNNLOAD_MESSAGE:
    'Non è stato possibile scaricare il pagamento',
  SCRIVANIA_PAGAMENTI_LIST_FETCH_ERROR_TITLE: 'Errore',
  SCRIVANIA_PAGAMENTI_LIST_FETCH_ERROR_TEXT:
    'Non è stato possibile recuperare la lista dei pagamenti',
  SCRIVANIA_PAGAMENTI_LIST_FILTER_FORM_OBJECT_LABEL: 'Oggetto del pagamento',
  SCRIVANIA_PAGAMENTI_LIST_FILTER_FORM_OBJECT_PLACEHOLDER:
    'Inserisci il nome dell\'oggetto',
  SCRIVANIA_PAGAMENTI_LIST_FILTER_FORM_STATE_LABEL: 'Stato del pagamento',
  SCRIVANIA_PAGAMENTI_LIST_FILTER_FORM_STATE_PLACEHOLDER:
    'Inserisci lo stato del pagamento',
  SCRIVANIA_PAGAMENTI_LIST_FILTER_FORM_TIPO_LABEL: 'Tipologia di pagamento',
  SCRIVANIA_PAGAMENTI_LIST_FILTER_FORM_TIPO_PLACEHOLDER:
    'Inserisci la tipologia di pagamento',
  SCRIVANIA_PAGAMENTI_LIST_FILTER_FORM_DATA_LABEL: 'Data del pagamento',
  SCRIVANIA_PAGAMENTI_LIST_FILTER_FORM_DATA_PLACEHOLDER:
    'Inserisci la data del pagamento',
  SCRIVANIA_PAGAMENTI_LIST_FILTER_FORM_PRATICA_LABEL: 'Nome della pratica',
  SCRIVANIA_PAGAMENTI_LIST_FILTER_FORM_PRATICA_PLACEHOLDER:
    'Inserisci il nome della pratica',
  SCRIVANIA_PAGAMENTI_LIST_FILTER_FORM_N_PRATICA_LABEL: 'Numero della pratica',
  SCRIVANIA_PAGAMENTI_LIST_FILTER_FORM_N_PRATICA_PLACEHOLDER:
    'Inserisci il numero della pratica',
  SCRIVANIA_PAGAMENTI_BREADCRUMB_HOME: 'Home',
  SCRIVANIA_PAGAMENTI_BREADCRUMB_SCRIVANIA: 'Scrivania',
  SCRIVANIA_PAGAMENTI_BREADCRUMB_PAGAMENTI: 'Pagamenti',
  SCRIVANIA_PAGAMENTI_LIST_TITLE: 'Pagamenti',
  SCRIVANIA_PAGAMENTI_LIST_SUBTITLE: 'pagamenti trovati',
  SCRIVANIA_PAGAMENTI_LIST_FILTER_BUTTON: 'Filtra pagamenti',

  SCRIVANIA_DOCUMENTI_LIST_COLUMNS_OGGETTO: 'Nome',
  SCRIVANIA_DOCUMENTI_LIST_COLUMNS_DATA: 'Data',
  SCRIVANIA_DOCUMENTI_LIST_COLUMNS_PESO: 'Peso',
  SCRIVANIA_DOCUMENTI_LIST_COLUMNS_NOME: 'Pratica',
  SCRIVANIA_DOCUMENTI_LIST_COLUMNS_NUMERO: 'N. Pratica',
  SCRIVANIA_DOCUMENTI_LIST_FILTER_FORM_NOME_LABEL: 'Nome del documento',
  SCRIVANIA_DOCUMENTI_LIST_FILTER_FORM_NOME_PLACEHOLDER:
    'Inserisci il nome del documento',
  SCRIVANIA_DOCUMENTI_LIST_FILTER_FORM_DATA_LABEL: 'Data del documento',
  SCRIVANIA_DOCUMENTI_LIST_FILTER_FORM_DATEFROM_LABEL: 'Data da',
  SCRIVANIA_DOCUMENTI_LIST_FILTER_FORM_DATETO_LABEL: 'Data a',
  SCRIVANIA_DOCUMENTI_LIST_FILTER_FORM_DATEFROM_PLACEHOLDER:
    'Inserisci la data di inizio ricerca',
  SCRIVANIA_DOCUMENTI_LIST_FILTER_FORM_DATETO_PLACEHOLDER:
    'Inserisci la data di fine ricerca',
  SCRIVANIA_DOCUMENTI_LIST_FILTER_FORM_PRATICA_LABEL: 'Nome della pratica',
  SCRIVANIA_DOCUMENTI_LIST_FILTER_FORM_PRATICA_PLACEHOLDER:
    'Inserisci il nome della pratica',
  SCRIVANIA_DOCUMENTI_LIST_FILTER_FORM_N_PRATICA_LABEL: 'Numero della pratica',
  SCRIVANIA_DOCUMENTI_LIST_FILTER_FORM_N_PRATICA_PLACEHOLDER:
    'Inserisci il numero della pratica',
  SCRIVANIA_DOCUMENTI_LIST_FETCH_ERROR_TITLE: 'Errore',
  SCRIVANIA_DOCUMENTI_LIST_FETCH_ERROR_TEXT:
    'Non è stato possibile recuperare la lista dei documenti',
  SCRIVANIA_DOCUMENTI_LIST_TOASTR_SUCCESS_DOWNNLOAD_TITLE:
    'Operazione completata',
  SCRIVANIA_DOCUMENTI_LIST_TOASTR_SUCCESS_DOWNNLOAD_MESSAGE:
    'Il documento è stato correttamente scaricato',
  SCRIVANIA_DOCUMENTI_LIST_TOASTR_ERROR_DOWNNLOAD_TITLE: 'Errore',
  SCRIVANIA_DOCUMENTI_LIST_TOASTR_ERROR_DOWNNLOAD_MESSAGE:
    'Non è stato possibile scaricare il documento',
  SCRIVANIA_DOCUMENTI_BREADCRUMB_HOME: 'Home',
  SCRIVANIA_DOCUMENTI_BREADCRUMB_SCRIVANIA: 'Scrivania',
  SCRIVANIA_DOCUMENTI_BREADCRUMB_PAGAMENTI: 'Documenti',
  SCRIVANIA_DOCUMENTI_LIST_TITLE: 'Documenti',
  SCRIVANIA_DOCUMENTI_LIST_SUBTITLE: 'documenti trovati',
  SCRIVANIA_DOCUMENTI_LIST_FILTER_BUTTON: 'Filtra documenti',

  SCRIVANIA_DOCUMENTI_BUTTON_DOWNLOAD_PAGAMENTO: 'Scarica documento',

  TASK_NEXT_BUTTON_DESC_COM_NON_VALIDA:
    'Comunica all’istante la non validità preliminare',
  TASK_NEXT_BUTTON_COMUNICAZIONE_NON_VALIDITA: 'Comunica non validità',
  TASK_NEXT_BUTTON_COMUNICAZIONE_FINALE_DESC: 'Invia comunicazione finale',
  TASK_NEXT_BUTTON_COMUNICAZIONE_FINALE: 'Comunicazione finale',
  TASK_NEXT_BUTTON_DESC_COM_NON_PROC:
    'Comunica all’istante la non procedibilità della richiesta',
  TASK_NEXT_BUTTON_COMUNICAZIONE_NON_PROC: 'Comunica non procedibilità',
  TASK_NEXT_BUTTON_AVVIO_PROCEDIMENTO_DESC:
    'Comunica l’avvio del procedimento al richiedente',
  TASK_NEXT_BUTTON_AVVIO_PROCEDIMENTO: 'Avvio procedimento',
  TASK_NEXT_BUTTON_COMUNICA_UORD_DESC:
    'Comunica l’istanza all’Ufficio Responsabile del Dato',
  TASK_NEXT_BUTTON_COMUNICA_UORD: 'Comunica a UORD',
  TASK_NEXT_BUTTON_COMUNICA_RPCT_DESC:
    'Comunica la responsabilità del dato a RPCT',
  TASK_NEXT_BUTTON_COMUNICA_RPCT: 'Comunica a RPCT',
  BPM_HEADER_X_CONTO: 'Per conto di',
  DETAIL_false: 'Vedi dettaglio',
  DETAIL_true: 'Chiudi dettaglio',
  DETAIL_RICHIESTA: 'Richiesta',
  OMESSA_PUBBL: 'Omessa pubblicazione',
  PUBBL: 'Parziale pubblicazione',
  DESKTOP_BPM_TASK_NAME_BUTTON_APERTURA: 'Apertura pratica',
  DESKTOP_PRATICHE_CREA_RIFERIMENTI_TITLE: 'Riferimenti',
  DESKTOP_PRATICHE_CREA_RIFERIMENTI_ADSP: 'ADSP',
  DESKTOP_PRATICHE_CREA_RIFERIMENTI_UFFICIO_UNICO: 'Ufficio unico',
  DESKTOP_PRATICHE_CREA_ACCESSO_TITLE_TABLE: 'Richieste di accesso',
  DESKTOP_PRATICHE_CREA_DOCUMENTI_TITLE: 'Allegati',
  DESKTOP_PRATICHE_CREA_RIFERIMENTI_UFFICIO_COMPETENTE: 'Ufficio competente',
  DESKTOP_PRATICHE_CREA_ACCESSO_TABLE_COLUMN_SET: 'Sez. Amm. trasparente',
  DESKTOP_PRATICHE_CREA_ACCESSO_TABLE_COLUMN_RIF: 'Riferimento',
  DESKTOP_PRATICHE_CREA_DOCUMENTI_ADD_TABLE_DOCUMENTO_NOME: 'Nome documento',
  DESKTOP_PRATICHE_CREA_DOCUMENTI_ADD_TABLE_DOCUMENTO_DATA: 'Data caricamento',
  DESKTOP_PRATICHE_DETAIL_HEAD_BUTTON_TO_PRATICA: 'Torna alla pratica',
  DESKTOP_PRATICA_DETAIL_QUADRI_VERIFICA_SOTTOSCRIZIONE_TITLE:
    'Validità istanza',
  DESKTOP_PRATICA_DETAIL_QUADRI_VERIFICA_SOTTOSCRIZIONE_SELECT_LABEL:
    'L’istanza presentata è',
  DESKTOP_PRATICA_DETAIL_QUADRI_VERIFICA_SOTTOSCRIZIONE_PLACEHOLDER:
    'Scegli validità',
  DESKTOP_PRATICA_DETAIL_QUADRI_VERIFICA_SOTTOSCRIZIONE_LONG_TEXT:
    'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata',
  DESKTOP_PRATICA_DETAIL_QUADRI_VERIFICA_PROCEDIBILITA_TITLE:
    'Procedibilità istanza',
  DESKTOP_PRATICA_DETAIL_QUADRI_VERIFICA_PROCEDIBILITA_SELECT_LABEL:
    'L’istanza presentata è',
  DESKTOP_PRATICA_DETAIL_QUADRI_VERIFICA_PROCEDIBILITA_PLACEHOLDER:
    'Scegli procedibilita',
  DESKTOP_PRATICA_DETAIL_QUADRI_VERIFICA_PROCEDIBILITA_LONG_TEXT:
    'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata',
  DESKTOP_PRATICA_DETAIL_QUADRI_VERIFICA_INTEGRAZIONI_PREAVVISO_TITLE:
    'Verifica dell\'integrazione fatta dopo il preavviso di diniego',
  DESKTOP_PRATICA_DETAIL_QUADRI_VERIFICA_INTEGRAZIONI_PREAVVISO_LONG_TEXT:
    'Verifica preavviso',
  DESKTOP_PRATICA_DETAIL_QUADRI_VERIFICA_INTEGRAZIONI_PREAVVISO_SELECT_LABEL:
    'La pratica risulta',
  DESKTOP_PRATICA_DETAIL_QUADRI_COMUNICAZIONE_GENERICA_DESTINATARI:
    'Destinatari comunicazione:',
  DESKTOP_PRATICA_DETAIL_QUADRI_COMUNICAZIONE_GENERICA_DOCUMENTO: 'Documento:',
  DESKTOP_PRATICA_DETAIL_QUADRI_RICHIESTA_PUBBLICAZIONE_OPTION_PUBB:
    'Parziale pubblicazione',
  DESKTOP_PRATICA_DETAIL_QUADRI_RICHIESTA_PUBBLICAZIONE_OPTION_OMESSA_PUBB:
    'Omessa pubblicazione',
  DESKTOP_PRATICA_DETAIL_QUADRI_RICHIESTA_TABLE_RICHIESTE_ACCESSO_COLUMNS_SEZIONE:
    'Sezione Amministrazione trasp.',
  DESKTOP_PRATICA_DETAIL_QUADRI_RICHIESTA_TABLE_RICHIESTE_ACCESSO_COLUMNS_RIFERIMENTI:
    'Riferimenti doc./norma',
  DESKTOP_PRATICA_DETAIL_QUADRI_DOCUMENTI_TITLE: 'Documenti',
  DESKTOP_PRATICA_DETAIL_QUADRI_REGISTRO_ACCESSI_ISTANZA_LABEL:
    'Numero istanza',
  DESKTOP_PRATICA_DETAIL_QUADRI_REGISTRO_ACCESSI_DATA_PROTOCOLLO_LABEL:
    'Data protocollo istanza',
  DESKTOP_PRATICA_DETAIL_QUADRI_REGISTRO_ACCESSI_AMBITO_LABEL: 'Ambito',
  DESKTOP_PRATICA_DETAIL_QUADRI_REGISTRO_ACCESSI_OGGETTO_LABEL: 'Oggetto',
  DESKTOP_PRATICA_DETAIL_QUADRI_REGISTRO_ACCESSI_UFFICIO_DETENTORE_LABEL:
    'Ufficio Detentore del Dato',
  DESKTOP_PRATICA_DETAIL_QUADRI_REGISTRO_ACCESSI_ESITO_LABEL: 'Esito',
  DESKTOP_PRATICA_DETAIL_QUADRI_REGISTRO_ACCESSI_DATA_ESITO_LABEL: 'Data esito',
  DESKTOP_PRATICA_DETAIL_QUADRI_REGISTRO_ACCESSI_RICORSO_LABEL:
    'Ricorso al Giudice Amministrativo',
  DESKTOP_PRATICA_DETAIL_QUADRI_COM_PUBBLICAZIONE_TITLE:
    'Comunicazione avvenuta pubblicazione',
  DESKTOP_PRATICA_DETAIL_QUADRI_COM_PUBBLICAZIONE_LONG_TEXT: 'Lorem ipsum...',
  DESKTOP_PRATICA_DETAIL_QUADRI_COM_PUBBLICAZIONE_DESTINATARIO:
    'Destinatario comunicazione',
  DESKTOP_PRATICA_DETAIL_TASK_APERTURA: 'Apertura pratica',
  DESKTOP_PRATICA_DETAIL_TASK_VERIFICA_SOTTOSCRIZIONE: 'Verifica requisiti',
  DESKTOP_PRATICA_DETAIL_TASK_VERIFICA_PROCEDIBILITA: 'Verifica procedibilità',
  DESKTOP_PRATICA_DETAIL_TASK_VERIFICA_PREAVVISO: 'Verifica preavviso',
  DESKTOP_PRATICA_DETAIL_TASK_AVVIO_PROCEDIMENTO: 'Avvio procedimento',
  DESKTOP_PRATICA_DETAIL_TASK_APERTURA_REGISTRO_ACCESSI:
    'Apertura registro accessi',
  DESKTOP_PRATICA_DETAIL_TASK_COMUNICAZIONE_PUBBLICAZIONE:
    'Comunicazione pubblicazione',
  DESKTOP_PRATICA_DETAIL_TASK_CHIUSURA_REGISTRO_ACCESSI:
    'Chiusura registro accessi',
  DESKTOP_PRATICA_DETAIL_TASK_COMUNICAZIONE_NON_PRC:
    'Comunicazione non procedibilità',
  DESKTOP_PRATICA_DETAIL_TASK_COMUNICAZIONE_NON_VALIDITA:
    'Comunicazione non validità',
  DESKTOP_PRATICA_DETAIL_QUADRI_UPLOAD_ALLEGATI_D7_TITLE: 'Documenti D7',
  DESKTOP_PRATICA_DETAIL_QUADRI_UPLOAD_ALLEGATI_DOC_PROGETTO_TITLE:
    'Documenti di progetto',
  DESKTOP_PRATICA_DETAIL_QUADRI_UPLOAD_ALLEGATI_ALTRI_DOC_TITLE:
    'Altri documenti',
  DESKTOP_PRATICA_DETAIL_QUADRI_UPLOAD_ALLEGATI_TABLE_COLUMNS_NOME_DOCUMENTO:
    'Nome documento',
  DESKTOP_PRATICA_DETAIL_QUADRI_UPLOAD_ALLEGATI_TABLE_COLUMNS_DATA_CARICAMENTO:
    'Data caricamento',
  DESKTOP_PRATICA_DETAIL_QUADRI_UPLOAD_ALLEGATI_TABLE_COLUMNS_NOTE: 'Note',
  DESKTOP_PRATICA_DETAIL_QUADRI_UPLOAD_ALLEGATI_TABLE_COLUMNS_TIPO_DOCUMENTO:
    'Tipo documento',
  DESKTOP_PRATICA_DETAIL_QUADRI_UPLOAD_ALLEGATI_TITLE: 'Documenti caricati',

  DESKTOP_PRATICA_DETAIL_QUADRI_ELENCO_PORTI_TITLE: 'Porto di riferimento',
  DESKTOP_PRATICA_DETAIL_QUADRI_ELENCO_PORTI_LONG_TEXT:
    'Selezione dai porti disponibili',
  DESKTOP_PRATICA_DETAIL_QUADRI_ELENCO_PORTI_SELECT_LABEL: 'Porto Selezionato',

  DESKTOP_PRATICA_DETAIL_TASK_COMUNICAZIONE_PREAV_DINIEGO: 'Preavviso diniego',
  DESKTOP_PRATICA_DETAIL_QUADRI_COM_PREAV_DINIEGO_TITLE:
    'Comunicazione preavviso diniego',
  DESKTOP_PRATICA_DETAIL_QUADRI_COM_PREAV_DINIEGO_LONG_TEXT:
    'Lorem ipsum dolor sit amet...',
  TASK_NEXT_BUTTON_ACQUISIZIONE_PARERE_ENTI_ESTERNI_DESC:
    'Acquisizione parere Enti Esterni',
  TASK_NEXT_BUTTON_ACQUISIZIONE_PARERE_ENTI_ESTERNI: 'Acquisizione parere',
  TASK_NEXT_BUTTON_ISCRIZIONE_REGISTRO_DESC:
    'Procedere con l\'iscrizione nel registo',
  TASK_NEXT_BUTTON_ISCRIZIONE_REGISTRO: 'Iscrizione registo',
  DESKTOP_PRATICA_DETAIL_TASK_ACQUISIZIONE_PARERE_ENTI_ESTERNI:
    'Parere Enti Esterni',
  DESKTOP_PRATICA_DETAIL_QUADRI_ACQUISIZIONE_PARERE_ENTI_ESTERNI_TITLE:
    'Parere Enti Esterni',
  DESKTOP_PRATICA_DETAIL_QUADRI_ACQUISIZIONE_PARERE_ENTI_ESTERNI_LONG_TEXT:
    'Descrzione parere Enti Esterni',
  DESKTOP_PRATICA_DETAIL_QUADRI_ACQUISIZIONE_PARERE_ENTI_ESTERNI_SELECT_LABEL:
    'La richiesta è stata',
  DESKTOP_PRATICA_DETAIL_QUADRI_COM_NON_VALIDITA_TITLE:
    'Comunicazione non validità istanza',
  DESKTOP_PRATICA_DETAIL_QUADRI_COM_NON_PROCEDIBILITA_TITLE:
    'Comunicazione non procedibilità istanza',
  DESKTOP_PRATICA_DETAIL_QUADRI_COM_NON_PROCEDIBILITA_LONG_TEXT:
    'Lorem ipsum dolor sit amet...',
  DESKTOP_PRATICA_DETAIL_QUADRI_COM_NON_PROC_DOC: 'Documento',
  DESKTOP_PRATICA_DETAIL_QUADRI_COM_NON_PROC_DESTINATARIO:
    'Destinatario comunicazione',
  DESKTOP_PRATICA_DETAIL_QUADRI_COM_AVVIO_PROCEDIMENTO_TITLE:
    'Comunicazione avvio procedimento',
  DESKTOP_PRATICA_DETAIL_QUADRI_COM_AVVIO_PROCEDIMENTO_LONG_TEXT:
    'Lorem ipsum dolor sit amet...',
  DESKTOP_PRATICA_DETAIL_QUADRI_COM_NON_VALIDITA:
    'Comunicazione non validità istanza',
  DESKTOP_PRATICA_DETAIL_QUADRI_COM_NON_VALIDITA_LONG_TEXT:
    'Lorem ipsum dolor sit amet...',
  DESKTOP_PRATICA_DETAIL_QUADRI_COM_NON_VALIDITA_DESTINATARIO:
    'Destinatario comunicazione',
  DESKTOP_PRATICA_DETAIL_TASK_VERIFICA_INTEGRAZIONI: 'Verifica integrazione',
  DESKTOP_PRATICA_DETAIL_TASK_INOLTRO: 'Inoltra pratica',
  DESKTOP_PRATICA_DETAIL_QUADRI_VERIFICA_INTEGRAZIONI_TITLE:
    'Verifica dell\'integrazione effettuata',
  DESKTOP_PRATICA_DETAIL_QUADRI_VERIFICA_INTEGRAZIONI_LONG_TEXT:
    'Esito finale dell\'integrazione in seguito alla visione dei documenti ricevuti',
  DESKTOP_PRATICA_DETAIL_QUADRI_VERIFICA_INTEGRAZIONI_SELECT_LABEL:
    'L\'istanza risulta essere',
  DESKTOP_PRATICA_DETAIL_TASK_COMUNICAZIONE_AUTORIZZAZIONE:
    'Comunicazione di Autorizzazione',
  DESKTOP_PRATICA_DETAIL_QUADRI_CHIUSURA_PRATICA_TITLE: 'Fine della pratica',
  DESKTOP_PRATICA_DETAIL_QUADRI_CHIUSURA_PRATICA_LONG_TEXT:
    'Chiusura della pratica con iscrizione al registro imprese, con eventuale nota aggiuntiva:',
  DESKTOP_PRATICA_DETAIL_TASK_INSERISCI_INTEGRAZIONI: 'Integrazione effettuata',
  DESKTOP_PRATICA_DETAIL_QUADRI_CONTROLLO_REQUISITI_TITLE:
    'Controllo dei requisiti',
  DESKTOP_PRATICA_DETAIL_QUADRI_CONTROLLO_REQUISITI_LONG_TEXT:
    'Verifica dei controllo requisiti per il continuo della pratica',
  DESKTOP_PRATICA_DETAIL_QUADRI_CONTROLLO_REQUISITI_SELECT_LABEL: 'L\'istanza',

  DESKTOP_PRATICA_DETAIL_TASK_COMUNICAZIONE_RICHIESTA_INTEGRAZIONE:
    'Richiesta di integrazione',
  DESKTOP_PRATICA_DETAIL_QUADRI_COM_RICHIESTA_INTEGRAZIONE_TITLE:
    'Integrazioni richieste',
  DESKTOP_PRATICA_DETAIL_QUADRI_COM_RICHIESTA_INTEGRAZIONE_LONG_TEXT:
    'Per il proseguimento della pratica si necessita l\'integrazione dei documenti richiesti nella comunicazione',

  DESKTOP_PRATICHE_CREA_ACCESSO_LABEL_RADIO_TITLE: 'Considerata',
  SEZ_AMM_DISPOSIZIONI_GENERALI: 'Disposizioni Generali',
  SEZ_AMM_ORGANIZZAZIONE: 'Organizzazione',
  SEZ_AMM_CONSULENTI_COLLABORATORI: 'Consulenti e operatori',
  SEZ_AMM_PERSONALE: 'Personale',
  SEZ_AMM_BANDI_CONCORSO: 'Bandi di Concorso',
  SEZ_AMM_PERFORMANCE: 'Performance',
  SEZ_AMM_ENTI_CONTROLLATI: 'Enti Controllati',
  SEZ_AMM_ATTIVITA_PROCEDIMENTI: 'Attività e procedimenti',
  SEZ_AMM_PROVVEDIMENTI: 'Provvedimenti',
  SEZ_AMM_BANDI_GARA: 'Bandi di Gara e Contratti',
  SEZ_AMM_SOVVENZIONI: 'Sovvenzioni, contributi, sussidi, vantaggi economici',
  SEZ_AMM_BILANCI: 'Bilanci',
  SEZ_AMM_BENI_PATRIMONIO: 'Beni Immobili e Gestione Patrimonio',
  SEZ_AMM_CONTROLLO_RILIEVI: 'Controllo e rilievi sull\'amministrazione',
  SEZ_AMM_PAGAMENTI: 'Pagamenti dell\'amministrazione',
  SEZ_AMM_OPERE_PUBBLICHE: 'Opere Pubbliche',
  SEZ_AMM_PIANIFICAZIONE_GOVERNO: 'Pianificazione e governo del territorio',
  SEZ_AMM_INFO_AMBIENTALI: 'Informazioni Ambientali',
  SEZ_AMM_INTERVENTI: 'Interventi straordinari e di emergenza',
  SEZ_AMM_ALTRO: 'Altri Contenuti',
  SEZ_AMM_null: '---',
  DESKTOP_PRATICHE_ADD_NEW_DOCUMENT_ERROR_SCARICARE_MESSAGE:
    'Non è stato possibile scaricare il documento selezionato, riprova più tardi',

  TYPE_SCADENZA_RICHIESTA: 'Richiesta',
  TYPE_SCADENZA_INTEGRAZIONE: 'Integrazione',
  TYPE_SCADENZA_VERIFICA: 'Verifica',
  TYPE_SCADENZA_TERMINE_MASSIMO: 'Termine massimo',

  TIMELINE_STATE: 'Stato istanza: ',
  TIMELINE_TITLE_null: '---',
  TIMELINE_DESC_null: '---',
  TIMELINE_TITLE_APERTURA: 'Apertura',
  TIMELINE_DESC_APERTURA: 'Descrizione apertura',
  TIMELINE_TITLE_VERIFICA_SOTTOSCRIZIONE: 'Verifica preliminare',
  TIMELINE_DESC_VERIFICA_SOTTOSCRIZIONE: 'Descrizione verifica preliminare',
  TIMELINE_TITLE_VERIFICA_PROCEDIBILITA: 'Verifica procedibilità',
  TIMELINE_DESC_VERIFICA_PROCEDIBILITA: 'Descrizione verifica procedibilità',
  TIMELINE_TITLE_COM_NON_VALIDITA: 'Comunicazione di non validità',
  TIMELINE_DESC_COM_NON_VALIDITA: 'Descrizione comunicazione di non validità',
  TIMELINE_TITLE_COM_NON_PROCEDIBILITA: 'Comunicazione non procedibile',
  TIMELINE_DESC_COM_NON_PROCEDIBILITA:
    'Descrizione comunicazione non procedibile',
  TIMELINE_TITLE_COM_AVVIO_PROCEDIMENTO: 'Avvio procedimento',
  TIMELINE_DESC_COM_AVVIO_PROCEDIMENTO: 'Descrizione avvio procedimento',
  TIMELINE_TITLE_COMUNICAZIONE_UORD: 'Comunicazione a UORD',
  TIMELINE_DESC_COM_UORD: 'Descrizione comunicazione a UORD',
  TIMELINE_TITLE_APERTURA_REG_ACCESSI: 'Apertura registro accessi',
  TIMELINE_DESC_APERTURA_REG_ACCESSI: 'Descrizione apertura registro accessi',
  TIMELINE_TITLE_COMUNICAZIONE_PUBBL: 'Comunicazione di pubblicazione',
  TIMELINE_DESC_COMUNICAZIONE_PUBBL:
    'Descrizione comunicazione di pubblicazione',
  TIMELINE_TITLE_CHIUSURA_REG_ACCESSI: 'Chiusura registro accessi',
  TIMELINE_TITLE_ASSEGNAZIONE_RESP_ISTR: 'Assegnato responsabile istruttoria',
  TIMELINE_DESC_ASSEGNAZIONE_RESP_ISTR:
    'È stato assegnato il responsabile d\'istruttoria',
  TIMELINE_TITLE_VERIFICA_PAGAMENTO: 'Verifica pagamento',
  TIMELINE_DESC_VERIFICA_PAGAMENTO: 'Descrizione verifica pagamento',
  TIMELINE_TITLE_CONTROLLO_REQUISITI: 'Controllo requisiti',
  TIMELINE_DESC_CONTROLLO_REQUISITI: 'Descrizione controllo requisiti',
  TIMELINE_TITLE_ASSEGNAZIONE_RESP_PROC: 'Assegnato responsabile procedimento',
  TIMELINE_DESC_ASSEGNAZIONE_RESP_PROC:
    'È stato assegnato il responsabile del procedimento',
  TIMELINE_TITLE_ACCETTAZIONE_RESP_ISTR:
    'Accettato ruolo responsabile istruttoria',
  TIMELINE_DESC_ACCETTAZIONE_RESP_ISTR:
    'È stato accettati il ruolo di responsabile di istruttoria',
  TIMELINE_TITLE_ACCETTAZIONE_RESP_PROC: 'Accettato responsabile procedimento',
  TIMELINE_DESC_ACCETTAZIONE_RESP_PROC:
    'È stato accettati il ruolo di responsabile del procedimento',
  TIMELINE_DESC_CHIUSURA_REG_ACCESSI: 'Descrizione chiusura registro accessi',
  TIMELINE_TITLE_COM_INVIO_PREAVVISO: 'Invio preavviso diniego',
  TIMELINE_DESC_COMUNICAZIONE_INVIO_PREAVVISO:
    'Comunicazione di preavviso diniego',
  TIMELINE_TITLE_INSERIMENTO_INTEGRAZIONI_DINIEGO: 'Inserimento integrazioni',
  TIMELINE_DESC_INSERIMENTO_INTEGRAZIONI_DINIEGO:
    'Inserimento integrazioni post preavviso di diniego',
  TIMELINE_TITLE_VERIFICA_INTEGRAZIONI_DINIEGO: 'Verifica dell\'integrazione',
  TIMELINE_DESC_VERIFICA_INTEGRAZIONI_DINIEGO:
    'Verifica dell\'integrazione post diniego',
  TIMELINE_TITLE_VERIFICA_INTEGRAZIONI: 'Verifica dell\'integrazione',
  TIMELINE_DESC_VERIFICA_INTEGRAZIONI: 'Verifica dell\'integrazione richiesta',
  TIMELINE_TITLE_PARERE_ENTI_ESTERNI: 'Richiesto parere enti esterni',
  TIMELINE_DESC_PARERE_ENTI_ESTERNI:
    'È stato richiesto il parere degli enti esterni',

  TIMELINE_TITLE_COM_DINIEGO: 'Comunicazione di diniego',
  TIMELINE_TITLE_COM_INVIO_PREAVVISO_2: 'Comunicazione invio preavviso',
  TIMELINE_TITLE_COM_INVIO_PREAVVISO_3: 'Comunicazione invio preavviso',
  TIMELINE_TITLE_COM_PRATICA_NEGATIVA: 'Comunicazione pratica negativa',
  TIMELINE_TITLE_COM_PRATICA_POSITIVA: 'Comunicazione',
  TIMELINE_TITLE_COM_RICHIESTA_CONFORMAZIONE:
    'Comunicazione richiesta conformazione',
  TIMELINE_TITLE_COM_RICHIESTA_INTEGRAZIONI:
    'Comunicazione richiesta integrazioni',
  TIMELINE_DESC_COM_DINIEGO: 'Descrizione comunicazione di diniego',
  TIMELINE_DESC_COM_PRATICA_NEGATIVA:
    'Descrizione comunicazione pratica negativa',
  TIMELINE_DESC_COM_PRATICA_POSITIVA:
    'Descrizione comunicazione pratica positiva',
  TIMELINE_DESC_COMUNICAZIONE_INVIO_PREAVVISO_2:
    'Comunicazione invio preavviso',
  TIMELINE_DESC_COMUNICAZIONE_INVIO_PREAVVISO_3:
    'Comunicazione invio preavviso',
  TIMELINE_DESC_COMUNICAZIONE_RICHIESTA_CONFORMAZIONE:
    'Descrizione richiesta conformazione',
  TIMELINE_DESC_COMUNICAZIONE_RICHIESTA_INTEGRAZIONI:
    'Descrizione richiesta integrazione',
  TIMELINE_DESC_INSERIMENTO_CONFORMAZIONE:
    'Descrizione inserimento integrazione',
  TIMELINE_DESC_INSERIMENTO_INTEGRAZIONI:
    'Descrizione inserimento integrazione',
  TIMELINE_DESC_INSERIMENTO_INTEGRAZIONI_2:
    'Descrizione inserimento integrazione',
  TIMELINE_DESC_VERIFICA_CONFORMAZIONE: 'Descrizione verifica conformazione',
  TIMELINE_TITLE_INSERIMENTO_CONFORMAZIONE: 'Inserimento conformazione',
  TIMELINE_TITLE_INSERIMENTO_INTEGRAZIONI: 'Inserimento integrazioni',
  TIMELINE_TITLE_INSERIMENTO_INTEGRAZIONI_2: 'Inserimento integrazioni',
  TIMELINE_TITLE_VERIFICA_CONFORMAZIONE: 'Verifica conformazione',

 DESKTOP_BPM_DELEGATI_ASSOCIATI_COLUMNS_STATO_REVOCATA: 'Revocato',

  DESKTOP_PRATICA_DETAIL_QUADRI_COMUNICAZIONE_FINALE_TITLE:
    'Comunicazione finale',
  DESKTOP_PRATICA_DETAIL_QUADRI_COMUNICAZIONE_FINALE_LONG_TEXT:
    'Comunicazione di fine pratica',

  DESKTOP_PRATICA_DETAIL_QUADRI_COM_AVVIO_PROC_DESTINATARIO:
    'Destinatario comunicazione',
  DESKTOP_PRATICA_DETAIL_QUADRI_COM_AVVIO_PROC_DOC: 'Documento',

  DESKTOP_PRATICA_DETAIL_QUADRI_RICHIESTA_TABLE_RICHIESTE_ACCESSO_COLUMNS_AMBITO:
    'Ambito/Sezione Amministrazione trasp.',
  DESKTOP_PRATICA_DETAIL_QUADRI_RICHIESTA_TABLE_RICHIESTE_ACCESSO_COLUMNS_AMBITO_ALTRO:
    'Altro ambito',

  DESKTOP_PRATICA_DETAIL_QUADRI_COM_RPCT_UORD_TITLE:
    'Comunicazioni da inviare agli UORD',
  DESKTOP_PRATICA_DETAIL_QUADRI_COM_RPCT_UORD_COLUMNS_DESTINATARIO:
    'Destinatario',
  DESKTOP_PRATICA_DETAIL_QUADRI_COM_RPCT_UORD_COLUMNS_DATA_INVIO: 'Data invio',
  DESKTOP_PRATICA_DETAIL_QUADRI_COM_RPCT_UORD_COLUMNS_RESP_DATO:
    'Responsabilità del dato',
  DESKTOP_PRATICA_DETAIL_QUADRI_COM_RPCT_UORD_STATO_PARZIALMENTE_ACCETTATA:
    'Parzialmente accettata',
  DESKTOP_PRATICA_DETAIL_QUADRI_COM_LINK_STATO_PARZIALMENTE_ACCETTATA:
    'Parzialmente accettata',
  DESKTOP_PRATICA_DETAIL_QUADRI_COM_RPCT_UORD_FETCH_COM_ERROR_MESSAGE:
    'Non è stato possibile recuperare la comunicazione ricevuta da UORD, riprova più tardi',
  DESKTOP_PRATICA_DETAIL_QUADRI_COM_RPCT_UORD_MODAL_TITLE: 'Comunicazione UORD',
  DESKTOP_PRATICA_DETAIL_QUADRI_COM_RPCT_UORD_MODAL_COM_A_UORD:
    'Comunicazione inviata',
  DESKTOP_PRATICA_DETAIL_QUADRI_COM_RPCT_UORD_MODAL_COM_A_RPCT:
    'Comunicazione ricevuta',
  DESKTOP_PRATICA_DETAIL_QUADRI_COM_RPCT_UORD_MODAL_OGGETTO:
    'Oggetto comunicazione',
  DESKTOP_PRATICA_DETAIL_QUADRI_COM_RPCT_UORD_MODAL_MITTENTE: 'Mittente',
  DESKTOP_PRATICA_DETAIL_QUADRI_COM_RPCT_UORD_MODAL_DESTINATARIO:
    'Destinatario',
  DESKTOP_PRATICA_DETAIL_QUADRI_COM_RPCT_UORD_MODAL_FILE: 'File comunicazione',
  DESKTOP_PRATICA_DETAIL_QUADRI_COM_RPCT_UORD_MODAL_N_PROTOCOLLO:
    'Numero protocollo',
  DESKTOP_PRATICA_DETAIL_QUADRI_COM_RPCT_UORD_MODAL_DATA_PROTOCOLLO:
    'Data protocollazione',
  DESKTOP_PRATICA_DETAIL_QUADRI_COM_RPCT_UORD_MODAL_CLASSIFICAZIONE:
    'Classificazione',
  DESKTOP_PRATICA_DETAIL_QUADRI_COM_RPCT_UORD_MODAL_DOC_DOWNLOAD: 'Scarica doc',
  DESKTOP_PRATICA_DETAIL_QUADRI_COM_RPCT_UORD_MODAL_UFFICI_CONOSCENZA:
    'Uffici per conoscenza',
  DESKTOP_PRATICA_DETAIL_QUADRI_COM_RPCT_UORD_MODAL_CLASSIFICAZIONE_TABLE_COLUMNS_NOME:
    'Denominazione',
  DESKTOP_PRATICA_DETAIL_QUADRI_COM_RPCT_UORD_MODAL_CLASSIFICAZIONE_TABLE_COLUMNS_COD_UFFICIO:
    'Cod. ufficio',

  DESKTOP_PRATICA_DETAIL_QUADRI_COM_RPCT_UORD_STATO_ACCETTATA: 'Accettata',
  DESKTOP_PRATICA_DETAIL_QUADRI_COM_RPCT_UORD_STATO_NON_ACCETTATA:
    'Non accettata',
  DESKTOP_PRATICA_DETAIL_QUADRI_COM_RPCT_UORD_STATO_INVIATA:
    'In attesa di risposta',
  DESKTOP_PRATICA_DETAIL_QUADRI_COM_RPCT_UORD_STATO_NON_INVIATA: 'Non inviata',
  DESKTOP_PRATICA_DETAIL_QUADRI_COM_RPCT_UORD_RISPOSTA_UORD:
    'Vedi comunicazione UORD',

  DESKTOP_PRATICA_DETAIL_QUADRI_COM_LINK_TITLE: 'Comunicazione ricevuta',
  DESKTOP_PRATICA_DETAIL_QUADRI_COM_LINK_COM_TABLE_COLUMNS_MITTENTE: 'Mittente',
  DESKTOP_PRATICA_DETAIL_QUADRI_COM_LINK_COM_TABLE_COLUMNS_DATA_RICEZIONE:
    'Data ricezione',
  DESKTOP_PRATICA_DETAIL_QUADRI_COM_LINK_COM_TABLE_COLUMNS_DATA_INVIO:
    'Data invio',
  DESKTOP_PRATICA_DETAIL_QUADRI_COM_LINK_COM_TABLE_COLUMNS_RESP_DATO:
    'Responsabilità del dato',

  DESKTOP_PRATICA_DETAIL_QUADRI_COM_LINK_STATO_ACCETTATA: 'Accettata',
  DESKTOP_PRATICA_DETAIL_QUADRI_COM_LINK_STATO_NON_ACCETTATA: 'Non accettata',
  DESKTOP_PRATICA_DETAIL_QUADRI_COM_LINK_STATO_INVIATA: 'In attesa di risposta',
  DESKTOP_PRATICA_DETAIL_QUADRI_COM_LINK_STATO_NON_INVIATA: 'Non inviata',

  DESKTOP_PRATICA_DETAIL_QUADRI_COM_LINK_COM_TABLE_COLUMNS_VIEW_COM_RPCT:
    'Vedi comunicazione RPCT',
  DESKTOP_PRATICA_DETAIL_QUADRI_COM_LINK_COM_TABLE_ROW_DETAIL_OGGETTO:
    'Oggetto comunicazione',
  DESKTOP_PRATICA_DETAIL_QUADRI_COM_LINK_COM_TABLE_ROW_DETAIL_DATA_INVIO:
    'Data invio',
  DESKTOP_PRATICA_DETAIL_QUADRI_COM_LINK_COM_TABLE_ROW_DETAIL_NOME_FILE:
    'Nome file comunicazione',
  DESKTOP_PRATICA_DETAIL_QUADRI_COM_LINK_COM_TABLE_ROW_DETAIL_BUTTON_DOWNLOAD:
    'Scarica doc',
  DESKTOP_PRATICA_DETAIL_QUADRI_COM_LINK_COM_TABLE_ROW_DETAIL_DOC_LABEL:
    'Visualizza link per i seguenti documenti:',
  DESKTOP_PRATICA_DETAIL_QUADRI_COM_LINK_COM_TABLE_ROW_DETAIL_TABLE_COLUMNS_AMM:
    'Sezione Amministrazione trasp.',
  DESKTOP_PRATICA_DETAIL_QUADRI_COM_LINK_COM_TABLE_ROW_DETAIL_TABLE_COLUMNS_RIFERIMENTI:
    'Riferimenti doc./norma',
  DESKTOP_PRATICA_DETAIL_QUADRI_COM_LINK_COM_TABLE_ROW_DETAIL_TABLE_COLUMNS_VIEW_LINK:
    'Vedi comunicazione link',
  DESKTOP_PRATICA_DETAIL_QUADRI_COM_LINK_FETCH_DOCUMENT_ERROR_TITLE: 'Errore',
  DESKTOP_PRATICA_DETAIL_QUADRI_COM_LINK_FETCH_DOCUMENT_ERROR_MESSAGE:
    'Non è stato possibile scaricare il file richiesto, riprova più tardi',
  DESKTOP_PRATICA_DETAIL_QUADRI_COM_LINK_FETCH_COM_DETAIL_ERROR_TITLE: 'Errore',
  DESKTOP_PRATICA_DETAIL_QUADRI_COM_LINK_FETCH_COM_DETAIL_ERROR_MESSAGE:
    'Non è stato caricare la comunicazione selezionata, riprova più tardi',

  DESKTOP_PRATICA_DETAIL_QUADRI_COM_LINK_MODAL_DETAIL_LINK_TITLE:
    'Comunicazione link da UORD',
  DESKTOP_PRATICA_DETAIL_QUADRI_COM_LINK_MODAL_DETAIL_LINK:
    'Link dato richiesto',
  DESKTOP_PRATICA_DETAIL_QUADRI_COM_LINK_MODAL_DETAIL_OGGETTO:
    'Oggetto comunicazione',
  DESKTOP_PRATICA_DETAIL_QUADRI_COM_LINK_MODAL_DETAIL_MITTENTE: 'Mittente',
  DESKTOP_PRATICA_DETAIL_QUADRI_COM_LINK_MODAL_DETAIL_DESTINATARIO:
    'Destinatario',
  DESKTOP_PRATICA_DETAIL_QUADRI_COM_LINK_MODAL_DETAIL_FILE_NAME:
    'File comunicazione',
  DESKTOP_PRATICA_DETAIL_QUADRI_COM_LINK_MODAL_DETAIL_N_PROTOCOLLO:
    'Numero protocollo',
  DESKTOP_PRATICA_DETAIL_QUADRI_COM_LINK_MODAL_DETAIL_DATA_PROTOCOLLO:
    'Data protocollo',
  DESKTOP_PRATICA_DETAIL_QUADRI_COM_LINK_MODAL_DETAIL_CLASS: 'Classificazione',
  DESKTOP_PRATICA_DETAIL_QUADRI_COM_LINK_MODAL_DETAIL_UFFICI:
    'Uffici per conoscenza',
  DESKTOP_PRATICA_DETAIL_QUADRI_COM_LINK_MODAL_DETAIL_UFFICI_TABLE_COLUMN_DENOMINAZIONE:
    'Denominazione',
  DESKTOP_PRATICA_DETAIL_QUADRI_COM_LINK_MODAL_DETAIL_UFFICI_TABLE_COLUMN_COD_UFFICIO:
    'Cod. ufficio',

  DESKTOP_PRATICA_DETAIL_QUADRI_COM_PUBB_DESTINATARIO:
    'Destinatario comunicazione',

  DESKTOP_PRATICA_DETAIL_QUADRI_INOLTRO_PRATICA_TITLE: 'Inoltra',
  DESKTOP_PRATICA_DETAIL_QUADRI_INOLTRO_PRATICA_LONG_TEXT: 'Long text',

  DESKTOP_PRATICA_DETAIL_TASK_COMUNICAZIONI_UORD: 'Assegnazione istanza UORD',
  DESKTOP_PRATICA_DETAIL_TASK_COMUNICAZIONE_LINK: 'Comunicazione link del dato',

  DESKTOP_PRATICA_DETAIL_TASK_COMUNICAZIONI_FO:
    'Comunicazioni tra Responsabili e Operatori',
  DESKTOP_PRATICA_DETAIL_QUADRI_RICHIESTA_RINUNCIA_TITLE:
    'Richiesta di rinuncia',
  DESKTOP_PRATICA_DETAIL_QUADRI_RICHIESTA_RINUNCIA_LONG_TEXT:
    'Testo richiesta di rinuncia',
  DESKTOP_PRATICA_DETAIL_QUADRI_COMUNICAZIONE_RINUNCIA_TITLE:
    'Comunicazione di approvazione rinuncia',
  DESKTOP_PRATICA_DETAIL_QUADRI_COMUNICAZIONE_RINUNCIA_LONG_TEXT:
    'Testo Comunicazione di approvazione rinuncia',
  DESKTOP_PRATICA_DETAIL_QUADRI_ACCETTAZIONE_RINUNCIA_TITLE:
    'Approvazione della rinuncia',
  DESKTOP_PRATICA_DETAIL_QUADRI_ACCETTAZIONE_RINUNCIA_LONG_TEXT:
    'Testo approvazione della rinuncia',
  DESKTOP_PRATICA_DETAIL_QUADRI_ACCETTAZIONE_RINUNCIA_SELECT_LABEL:
    'Selezionare se si vuole approvare o no la rinuncia',
  DESKTOP_PRATICA_DETAIL_TASK_RINUNCIA: 'Comunicazione di rinuncia',
  DESKTOP_PRATICA_DETAIL_TASK_APPROVA_RINUNCIA: 'Approvazione della rinuncia',
  DESKTOP_PRATICA_DETAIL_TASK_COMUNICAZIONE_FINALE: 'Comunicazione finale',
  RICORSO_NO: 'No',
  RICORSO_RICORSO_ACCOLTO: 'Ricorso accolto',
  RICORSO_RICORSO_RESPINTO: 'Ricorso respinto',
  RICORSO_IN_CORSO: 'In corso',
  QUADRO_PRATICHE_TABELLA_RPCT_MODALE_TITLE: 'Comunicazione da UORD',
  QUADRO_PRATICHE_TABELLA_MODALE_TITLE: 'Dettaglio Comunicazione',
  QUADRO_PRATICHE_COM_MODALE_TABLE_DOC_TITLE: 'Documenti caricati',
  QUADRO_PRATICHE_COM_MODALE_OGGETTO: 'Oggetto comunicazione',
  QUADRO_PRATICHE_COM_TABELLA_COLUMNS_DESTINATARIO: 'Destinatario',
  QUADRO_PRATICHE_COM_TABELLA_COLUMNS_MITTENTE: 'Mittente',
  QUADRO_PRATICHE_COM_TABELLA_COLUMNS_DATA_INVIO: 'Data invio',
  QUADRO_PRATICHE_COM_TABELLA_COLUMNS_STATO: 'Stato',
  QUADRO_PRATICHE_COM_TABELLA_COLUMNS_GENERATO: 'Creata da',
  QUADRO_PRATICHE_COM_TABELLA_ORIGINE_BO: 'Responsabile',
  QUADRO_PRATICHE_COM_TABELLA_ORIGINE_FO: 'Operatore',

  TABLE_COM_ICON_TITLE_VIEW_DETAIL: 'Vedi dettaglio comunicazione',
  QUADRO_PRATICHE_COMUNICAZIONE_INVIATA_MODALE_TITLE: 'Comunicazione',
  QUADRO_PRATICHE_COMUNICAZIONE_RISPOSTA_MODALE_TITLE:
    'Comunicazione di risposta',
  QUADRO_PRATICHE_COMUNICAZIONE_NOT_VIEW: 'Da leggere',
  QUADRO_PRATICHE_COMUNICAZIONE_VIEW: 'Letto',
  QUADRO_PRATICHE_COMUNICAZIONE_RESPONSE: 'Rispondi',

  QUADRO_DETAIL_BENI_TITLE: 'Mezzi',
  QUADRO_DETAIL_BENI_SUBTITLE:
    'Sezione contente la lista dei mezzi che verranno impiegati nei lavori',
  QUADRO_BENI_DIALOG_TITLE: 'Dati del mezzo selezionato',
  QUADRO_BENI_TABLE_TIPO_VEICOLO: 'Tipo veicolo',
  QUADRO_BENI_TABLE_TARGA: 'Targa',
  QUADRO_BENI_TABLE_MODELLO: 'Modello',
  QUADRO_BENI_TABLE_MARCA: 'Marca',
  QUADRO_BENI_DETAIL_TIPO_VEICOLO: 'Tipo veicolo',
  QUADRO_BENI_DETAIL_SEARCH_TARGA_TELAIO: 'Targa o telaio',
  QUADRO_BENI_DETAIL_SEARCH_TARGA_TELAIO_PLACEHOLDER:
    'Inserisci targa o telaio',
  QUADRO_BENI_DETAIL_TARGA: 'Targa',
  QUADRO_BENI_DETAIL_DATA_IMMATRICOLAZIONE: 'Data immatricolazione',
  QUADRO_BENI_DETAIL_MODELLO: 'Modello',
  QUADRO_BENI_DETAIL_MARCA: 'Marca',
  QUADRO_BENI_DETAIL_TELAIO: 'Telaio',
  QUADRO_BENI_DETAIL_ATTIVITA_CORRELATE: 'Attività correlate',
  QUADRO_BENI_DETAIL_TITLE: 'Dettaglio del mezzo selezionato',
  QUADRO_BENI_DETAIL_AUTONOLEGGIO: 'Autonoleggio',
  QUADRO_BENI_DETAIL_NAZIONE: 'Nazionalità',
  QUADRO_BENI_DETAIL_NOME_AUTONOLEGGIO: 'Nome autonoleggio',
  QUADRO_BENI_DETAIL_DATA_INIZIO_AUTONOLEGGIO: 'Data inizio noleggio',
  QUADRO_BENI_DETAIL_DATA_FINE_AUTONOLEGGIO: 'Data fine noleggio',

  COMUNICAZIONE_STATO_null: '---',
  COMUNICAZIONE_STATO_undefined: '---',
  COMUNICAZIONE_STATO_NON_VISUALIZZATA: 'Non visualizzato',
  COMUNICAZIONE_STATO_VISUALIZZATA: 'Visualizzato',
  DESKTOP_BPM_UPLOAD_ALLEGATI_TABLE_COLUMNS_NOTE: 'Note',
  DESKTOP_BPM_UPLOAD_ALLEGATI_TABLE_COLUMNS_NOME_DOC: 'Nome documento',
  DESKTOP_BPM_UPLOAD_ALLEGATI_TABLE_COLUMNS_DATA_CARICAMENTO:
    'Data caricamento',
  QUADRO_PRATICA_TABELLA_COM_TRASV_TITLE: 'Comunicazioni trasversali',
  QUADRO_PRATICHE_COM_TABELLA_COLUMNS_STATO_LETT: 'Stato lettura com.',
  QUADRO_PRATICHE_COM_TABELLA_COLUMNS_STATO_LETT_RISP: 'Stato lettura risposta',
  DESKTOP_QUADRO_DATI_PRATICA_TITLE: 'Pratica di riferimento',
  BPM_HEADER_N_PROTOCOLLO: 'N. Protocollo',
  BPM_HEADER_RICHIEDENTE: 'Richiedente',
  BPM_HEADER_DATE_PROTOCOLLO: 'Data protocollo',
  QUADRO_SELEZIONA_DOCUMENTI_ISTANZA_ESITO_PRATICA:
    'La pratica selezionata risulta essere',

  ESITO_undefined: '---',
  ESITO_ACCOLTA: 'Accolto',
  ESITO_ISTANZA_NON_CONFORME: 'Istanza non conforme',
  ESITO_NON_ACCOLTA: 'Non accolta',
  ESITO_DIVIETO_PROSECUZIONE_ATTIVITA: 'Divieto prosecuzione attività',
  ESITO_SCADUTA: 'Scaduta',
  DESKTOP_QUADRO_DATI_PRATICA_BUTTON: 'Vedi pratica',
  TITOLO_SELECT_CLASS_RICHIESTA_ACCESSO_DATI: 'Tenuto conto che ad oggi',
  SINGLE_RADIO_LABEL_NO_INTERAMENTE_PUBLL:
    'quanto richiesto non è stato ancora interamente pubblicato nella sezione “Amministrazione trasparente” sul sito istituzionale',
  SINGLE_RADIO_LABEL_PARZ_PUBBL:
    'è stato solo parzialmente pubblicato nella sezione "Amministrazione trasparente" sul sito istituzionale',
  SINGLE_RADIO_LABEL_NO_RISP:
    'non è stata data nessuna risposta dall’Amministrazione',
  DESKTOP_BPM_COMUNICAZIONE_TITLE_SELECT_DOCUMENTI_COLUMN_ESITO: 'Esito',
  DESKTOP_BPM_SELECT_FORM_INPUTS_MOTIVAZIONE_LABEL: 'Motivazione',
  DESKTOP_BPM_PUBBLICAZIONE_SELECT_IST_LABEL: 'L\'istanza presentata è',
  DESKTOP_BPM_PUBBLICAZIONE_SELECT_IST_PLACEHOLDER: 'Selezione tipologia',
  DESKTOP_PRATICA_DETAIL_QUADRI_COM_SG_UORD_TITLE: 'Comunicazioni SG-UORD',
  QUADRO_PROCEDIBILITA_ISTANZA_LABEL: 'Verifica procedibilità istanza',
  DESKTOP_PRATICHE_DETAIL_ACCESSO_OGGETTO: 'Oggetto',

  ISTANZA_ACCOLTA: 'Accolta',
  ISTANZA_NON_ACCOLTA: 'Non accolta',
  ISTANZA_ALTRO_PROC: 'Altro procedimento',
  ISTANZA_RIGETTATA: 'Rigettata',
  ISTANZA_PARZIALMENTE_ACCOLTA: 'Parzialmente accolta',
  ISTANZA_ISTANZA_IN_DIFFERIMENTO: 'In differimento',
  ISTANZA_ISTANZA_N_CORSO: 'In corso',
  ISTANZA_ALTRO_PROCEDIMENTO: 'Non accolta: Gestita come altro proc.',

  SCRIVANIA_STATO_PRATICA_HEAD_NUMERO_PROTOCOLLO: 'Numero protocollo',
  SCRIVANIA_STATO_PRATICA_HEAD_PUNTO_CONTATTO_MAIL: 'Mail del responsabile',
  SCRIVANIA_STATO_PRATICA_HEAD_PUNTO_CONTATTO_PEC: 'Pec del responsabile',
  SCRIVANIA_STATO_PRATICA_HEAD_PUNTO_CONTATTO_TEL: 'Telefono del responsabile',
  SCRIVANIA_STATO_PRATICA_HEAD_PUNTO_CONTATTO_UFFICIO:
    'Ufficio del responsabile ',
  SCRIVANIA_STATO_PRATICA_HEAD_DATA_ODIERNA: 'Oggi è il',
  SCRIVANIA_STATO_PRATICA_HEAD_DATA_APERTURA_PRATICA:
    'Data di apertura pratica',
  SCRIVANIA_STATO_PRATICA_HEAD_TEMPO_TRASCORSO_TITLE:
    'Tempo trascorso dall\'inizio del procedimento escluse le sospensioni',
  SCRIVANIA_STATO_PRATICA_HEAD_TEMPO_TRASCORSO: 'Tempo trascorso',
  SCRIVANIA_STATO_PRATICA_HEAD_TEMPO_DISPONIBILE_TITLE:
    'Tempo rimanente dalla data odierna per la conclusione',
  SCRIVANIA_STATO_PRATICA_HEAD_TEMPO_DISPONIBILE: 'Tempo rimanente',
  SCRIVANIA_STATO_PRATICA_HEAD_UNITA_GIORNI: 'Giorni',
  SCRIVANIA_STATO_PRATICA_HEAD_UNITA_GIORNO: 'Giorno',
  TIMELINE_TITLE_TERMINE_MASSIMO:
    'Termine massimo per la conclusione del procedimento',
  TASK_NEXT_BUTTON_COMUNICA_RICHIESTA_INTEGRAZIONE_DESC:
    'Invio comunicazione con richiesta di integrazione',
  TASK_NEXT_BUTTON_COMUNICA_RICHIESTA_INTEGRAZIONE: 'Richiedi integrazioni',
  TASK_NEXT_BUTTON_COMUNICA_RICHIESTA_INTEGRAZIONI_DESC:
    'Invio comunicazione con richiesta di integrazione',
  TASK_NEXT_BUTTON_COMUNICA_RICHIESTA_INTEGRAZIONI: 'Richiedi integrazioni',
  TASK_NEXT_BUTTON_VALIDAZIONE_ENTI_ESTERNI_DESC:
    'Puoi inserire il parere ricevuto extrasistema e inserire l\'esito finale della SCIA',
  TASK_NEXT_BUTTON_VALIDAZIONE_ENTI_ESTERNI: 'Validazione enti esterni',
  TASK_NEXT_BUTTON_PARERE_ENTE_ESTERNO_DESC:
    'Inserisci il parere dell\'ente per questa pratica',
  TASK_NEXT_BUTTON_PARERE_ENTE_ESTERNO: 'Inserisci parere',

  DESKTOP_BPM_UPLOAD_INTEGRAZIONI_TITLE: 'Inserisci i documenti richiesti',
  DESKTOP_BPM_UPLOAD_INTEGRAZIONI_LONG_TEXT:
    'Si necessita di integrare la documentazione richiesta se si desidera continuare con il procedimento',

  DESKTOP_BPM_DIPENDENTI_DETAIL_DIPENDENTE_TITLE:
    'Dettaglio del dipendente selezionato',
  DESKTOP_BPM_DIPENDENTI_FORM_LABEL_NAME: 'Nome',
  DESKTOP_BPM_DIPENDENTI_FORM_LABEL_SURNAME: 'Cognome',
  DESKTOP_BPM_DIPENDENTI_FORM_LABEL_CF: 'Codice fiscale',
  DESKTOP_BPM_DIPENDENTI_FORM_LABEL_DATA_NASC: 'Data nascita',
  DESKTOP_BPM_DIPENDENTI_FORM_LABEL_SESSO: 'Sesso',
  DESKTOP_BPM_DIPENDENTI_FORM_LABEL_NAZIONE_NASC: 'Nazione nascita',
  DESKTOP_BPM_DIPENDENTI_FORM_LABEL_PROVINCIA_NASC: 'Provincia nascita',
  DESKTOP_BPM_DIPENDENTI_FORM_LABEL_COMUNE_NASC: 'Comune di nascita',
  DESKTOP_BPM_DIPENDENTI_FORM_LABEL_EMAIL: 'Email',
  DESKTOP_BPM_DIPENDENTI_FORM_LABEL_TIPO_DOC: 'Tipo documento',
  DESKTOP_BPM_DIPENDENTI_FORM_LABEL_NUM_DOC: 'Numero documento',
  DESKTOP_BPM_DIPENDENTI_FORM_LABEL_TIPO_CONTRATTI: 'Tipo contratto',
  DESKTOP_BPM_DIPENDENTI_FORM_LABEL_LIVELLO: 'Livello',
  DESKTOP_BPM_DIPENDENTI_FORM_LABEL_MANSIONE: 'Mansione',
  DESKTOP_BPM_DIPENDENTI_FORM_LABEL_SCADENZA_CONTRATTO: 'Scadenza contratto',
  DESKTOP_BPM_DIPENDENTI_FORM_LABEL_DATA_ASSUNZIONE: 'Data assunzione',
  DESKTOP_BPM_DIPENDENTI_FORM_LABEL_DATA_DISTACCO_DAL: 'Data distacco dal',
  DESKTOP_BPM_DIPENDENTI_FORM_LABEL_DATA_DISTACCO_AL: 'Data distacco al',
  DESKTOP_BPM_DIPENDENTI_DETAIL_TITLE: 'Dipendenti',
  DESKTOP_BPM_DIPENDENTI_DETAIL_TEXT:
    'Sezione contente la lista dei dipendenti che eseguiranno i lavori',

  DESKTOP_PRATICA_DETAIL_QUADRI_ASSICURAZIONE_TITLE: 'Contratto assicurativo',
  DESKTOP_PRATICA_DETAIL_READONLY_COMPAGNIA_ASSICURATIVA:
    'Società assicurativa',
  DESKTOP_PRATICA_DETAIL_READONLY_NUMERO_CONTRATTO_ASSICURAZIONE:
    'Numero polizza',
  DESKTOP_PRATICHE_DETAIL_SERVIZI_ATTIVITA_LONG_TEXT:
    'Elenco delle attività richieste nella SCIA',
  DESKTOP_BPM_SOC_ASSICURATIVA_LABEL: 'Società assicurativa',
  DESKTOP_BPM_NUM_POLIZZA_LABEL: 'Numero polizza',
  DESKTOP_BPM_DATI_ASSICURAZIONE_TITLE: 'Contratto assicurativo',
  DESKTOP_PRATICHE_FORM_INPUT_SOC_ASSICURATIVA_LABEL: 'Società assicurativa',
  DESKTOP_PRATICHE_FORM_INPUT_NUM_POLIZZA_LABEL: 'Numero polizza',
  DESKTOP_PRATICHE_DATI_ASSICURAZIONE_TITLE: 'Contratto assicurativo',
  DESKTOP_BPM_MULTI_CHECK_SERVIZI_ATTIVITA_LONG_TEXT:
    'Elenco delle attività richieste nella SCIA',
  DESKTOP_PRATICHE_DETAIL_SERVIZI_ATTIVITA_TITLE: 'Le attività svolte',
  DESKTOP_PRATICHE_DETAIL_AUTODICHIARAZIONI_LONG_TEXT:
    'Elenco delle autodichiarazioni sottoscritte nella SCIA',
  DESKTOP_PRATICHE_DETAIL_AUTODICHIARAZIONI_TITLE:
    'Autodichiarazioni sottoscritte',

  MEZZO_AUTOVEICOLO: 'Autoveicolo',
  MEZZO_MOTOVEICOLO: 'Motoveicolo',
  MEZZO_TRATTORE: 'Trattore',
  MEZZO_MOTRICE: 'Motrice',
  MEZZO_SEMIRIMORCHIO: 'Semirimorchio',
  MEZZO_SEMIRIMORCHIO_CISTERNA: 'Semirimorchio Cisterna',
  MEZZO_AUTOCARRO: 'Autocarro',
  MEZZO_GRU: 'Gru',
  MEZZO_FURGONE: 'Furgone',
  MEZZO_RIMORCHIO: 'Rimorchio',

  MANSIONE_ADDETTO_VIGILANZA: 'Addetto vigilanza',
  MANSIONE_ADSP: 'ADSP',
  MANSIONE_AUTISTA: 'Autista',
  MANSIONE_DIRETTORE: 'Direttore',
  MANSIONE_IMPIEGATO: 'Impiegato',
  MANSIONE_OPERAIO: 'Operaio',
  MANSIONE_PERSONALE_ESTERNO: 'Personale esterno',
  MANSIONE_RESPONSABILE_LEGALE: 'Responsabile legale',
  MANSIONE_RESPONSABILE_TECNICO: 'Responsabile tecnico',

  DESKTOP_PRATICA_DETAIL_TASK_VALIDAZIONE_ENTI_ESTERNI:
    'Validazione pareri esterni',
  DESKTOP_PRATICA_DETAIL_TASK_VERIFICA_PAGAMENTO: 'Verifica pagamento',
  QUADRO_PRATICHE_COMUNICAZIONE_PARERE_MODALE_TITLE:
    'Comunicazione di richiesta parere',
  QUADRO_PRATICHE_COM_MODALE_MOTIVAZIONE: 'Motivazione',
  QUADRO_PRATICHE_COM_MODALE_ESITO: 'Esito',
  INSERITO_DA_EXTRASISTEMA: 'Extrasistema',
  INSERITO_MANUALMENTE: 'Manualmente',
  QUADRO_PRATICHE_TABELLA_ENTI_TITLE: 'Comunicazioni inviate agli Enti esterni',
  TIPO_DI_INSERITO: 'Tipo di inserimento',
  QUADRO_PRATICHE_TABELLA_ENTI_ESTERNI_COLUMNS_DESTINAZIONE: 'Ente esterno',
  DESKTOP_BPM_COMUNICAZIONI_COLUMNS_STATO: 'Stato',
  QUADRO_PRATICHE_TABELLA_ENTI_ESTERNI_COLUMNS_DATA_INVIO: 'Data invio',
  QUADRO_PRATICHE_COM_MODALE_MITTENTE: 'Mittente',
  QUADRO_PRATICHE_COM_MODALE_DESTINATARIO: 'Destinatario',
  DESKTOP_PRATICA_DETAIL_QUADRI_ESITO_VALIDAZIONE_ENTI_ESTERNI_TITLE:
    'Validazione esito pareri esterni',
  DESKTOP_PRATICA_DETAIL_QUADRI_ESITO_VALIDAZIONE_ENTI_ESTERNI_LONG_TEXT:
    'Esito finale dei pareri esterni che sono stati ricevuti',
  DESKTOP_PRATICA_DETAIL_QUADRI_ESITO_VALIDAZIONE_ENTI_ESTERNI_FORM_LABEL:
    'Esito finale risulta essere:',
  DESKTOP_PRATICA_DETAIL_QUADRI_CONTROLLO_PAGAMENTO_TITLE:
    'Controllo del pagamento',
  DESKTOP_PRATICA_DETAIL_QUADRI_CONTROLLO_PAGAMENTO_LONG_TEXT:
    'É stato effettuato un controllo sull\'esito del pagamento',
  DESKTOP_PRATICA_DETAIL_QUADRI_CONTROLLO_PAGAMENTO_SELECT_LABEL:
    'Esito controllo pagamento:',
  DESKTOP_PRATICA_DETAIL_TASK_ISCRIZIONE_REGISTRO:
    'Iscrizione al registro imprese',
  QUADRO_PRATICHE_COM_MODALE_TABLE_UFFICI_TITLE: 'Uffici per conoscenza',
  DESKTOP_PRATICHE_COM_MODAL_DENOMINAZIONE: 'Denominazione',
  DESKTOP_PRATICHE_COM_MODAL_CODICE: 'Cod. uffici',

  DESKTOP_DELEGHE_STEP_DATA_TABS_PERSON_DATA_FORM_CAPITALE_SOCIALE_LABEL:
    'Capitale sociale',
  DESKTOP_DELEGHE_STEP_DATA_TABS_PERSON_DATA_FORM_CAPITALE_SOCIALE_PLACEHOLDER:
    'Inserisci capitale sociale',
  DESKTOP_DELEGHE_STEP_DATA_TABS_PERSON_DATA_FORM_DATA_SCADENZA_SPA_LABEL:
    'Data scadenza società',
  DESKTOP_DELEGHE_STEP_DATA_TABS_PERSON_DATA_FORM_DATA_SCADENZA_SPA_PLACEHOLDER:
    'Inserisci data scadenza',
  DESKTOP_DELEGHE_STEP_DATA_TABS_PERSON_DATA_FORM_DURATA_SPA_LABEL:
    'Durata società',
  DESKTOP_DELEGHE_STEP_DATA_TABS_PERSON_DATA_FORM_DURATA_SPA_PLACEHOLDER:
    'Inserisci durata società in mesi',
  DESKTOP_DELEGHE_STEP_DATA_TABS_PERSON_DATA_FORM_NUMERO_ALBO_AUTOTRASPORTI_LABEL:
    'Numero iscrizione Albo Autotrasporti',
  DESKTOP_DELEGHE_STEP_DATA_TABS_PERSON_DATA_FORM_NUMERO_ALBO_AUTOTRASPORTI_PLACEHOLDER:
    'Inserisci nr. iscrizione Albo Autotrasporti',
  DESKTOP_DELEGHE_STEP_DATA_TABS_PERSON_DATA_FORM_REN_LABEL:
    'Numero iscrizione REN',
  DESKTOP_DELEGHE_STEP_DATA_TABS_PERSON_DATA_FORM_REN_PLACEHOLDER:
    'Inserisci numero iscrizione REN',
  DESKTOP_DELEGHE_STEP_DATA_TABS_PERSON_DATA_FORM_FUNZIONE_RAP_LABEL:
    'Funzione rappresentante',
  DESKTOP_DELEGHE_STEP_DATA_TABS_PERSON_DATA_FORM_FUNZIONE_RAP_PLACEHOLDER:
    'Inserisci funzione rappresentante',
  DESKTOP_DELEGHE_FORM_INPUT_CITY_DOMICILIOFISCALE_LABEL: 'Comune',
  DESKTOP_DELEGHE_FORM_INPUT_CITY_DOMICILIOFISCALE_PLACEHOLDER:
    'Inserisci comune',
  DESKTOP_DELEGHE_FORM_INPUT_CITY_SEDELEGALE_LABEL: 'Comune',
  DESKTOP_DELEGHE_FORM_INPUT_CITY_SEDELEGALE_PLACEHOLDER: 'Inserisci comune',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_CELL: 'Cellulare',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_FISSO: 'Fisso',
  DESKTOP_DELEGHE_STEP_DATA_TABS_PERSON_DATA_FORM_CELLULARE_LABEL: 'Cellulare',
  DESKTOP_DELEGHE_STEP_DATA_TABS_PERSON_DATA_FORM_CELLULARE_PLACEHOLDER:
    'Inserisci n. Cellulare',
  DESKTOP_DELEGHE_STEP_DATA_TABS_PERSON_DATA_FORM_FUNZIONE: 'Funzione',
  DESKTOP_DELEGHE_STEP_DATA_TABS_PERSON_DATA_FORM_FUNZIONE_PLACEHOLDER:
    'Inserisci funzione',

  TIPO_SOCIETA_PER_AZIONI: 'Società per azioni',
  TIPO_SOCIETA_RESPONSABILITA_LIMITATA: 'Società a responsabilità limitata',

  TIPO_AZIENDA_AUTOTRASPORTI: 'Azienda autotrasporti',
  TIPO_AZIENDA_PORTUALE: 'Azienda portuale',
  SCRIVANIA_PAGAMENTI_LIST_COLUMNS_ESITO: 'Esito',
  SCRIVANIA_PAGAMENTI_RIMBORSI: 'Rimborsi',
  SCRIVANIA_PAGAMENTI_RICEVUTE: 'Ricevute',
  SCRIVANIA_PAGAMENTI_SCARICA_RICEVUTA: 'Scarica ricevuta',
  PAGAMENTO_LIST_DETAIL_NOT_RIC_RIM: 'Non ci sono ne ricevute ne rimborsi',

  SCRIVANIA_PAGAMENTI_LIST_FILTER_FORM_DATA_CREAZIONE_LABEL_DA:
    'Data creazione dal',
  SCRIVANIA_PAGAMENTI_LIST_FILTER_FORM_DATA_CREAZIONE_LABEL_A:
    'Data creazione al',
  SCRIVANIA_PAGAMENTI_LIST_FILTER_FORM_DATA_PAGAMENTO_LABEL_DA:
    'Data pagamento dal',
  SCRIVANIA_PAGAMENTI_LIST_FILTER_FORM_DATA_PAGAMENTO_LABEL_A:
    'Data pagamento al',
  DESKTOP_PRATICA_DETAIL_QUADRI_ESITO_VALIDAZIONE_ENTI_ESTERNI_SELECT_LABEL:
    'Esito finale dei pareri degli enti esterni',
  DESKTOP_PRATICA_DETAIL_READONLY_ANNO_RIFERIMENTO: 'Anno di riferimento',
  DESKTOP_PRATICA_DETAIL_READONLY_TIPOLOGIA_SCIA: 'Tipologia SCIA',
  ANNUALE:
    'Annuale, la SCIA sarà vaida per l\'anno di riferimento inserito, fino al 31 dicembre.',
  TEMPORANEA:
    'Temporanea, la SCIA scadrà dopo 30 giorni dalla data di inoltro.',
  DESKTOP_PRATICA_DETAIL_QUADRI_TIPOLOGIA_SCIA_TITLE: 'Scelta del tipo di SCIA',
  TASK_NEXT_BUTTON_ARCHIVIAZIONE_SCIA_DESC: 'Archivia la pratica',
  TASK_NEXT_BUTTON_ARCHIVIAZIONE_SCIA: 'Archivia',
  DESKTOP_PRATICA_DETAIL_TASK_ARCHIVIAZIONE_SCIA: 'Archiviazione SCIA',
  DESKTOP_DETAIL_INFO_ARCHIVIAZIONE_SCIA_TITLE:
    'Comunicazione di archiviazione pratica',
  DETAIL_INFO_ARCHIVIAZIONE_SCIA: 'La pratica è stata archiviata',
  DESKTOP_PRATICA_DETAIL_QUADRI_RICHIESTA_CONFORMAZIONE_SOSPENSIONE_TITLE:
    'Comunicazione di richiesta conformazione',
  DESKTOP_PRATICA_DETAIL_QUADRI_RICHIESTA_CONFORMAZIONE_SOSPENSIONE_LONG_TEXT:
    'Sospensione della pratica per permettere integrazione',
  DESKTOP_PRATICA_DETAIL_QUADRI_VERIFICA_CONFORMAZIONE_SOSPENSIONE_TITLE:
    'Verifica integrazione',
  DESKTOP_PRATICA_DETAIL_QUADRI_VERIFICA_CONFORMAZIONE_SOSPENSIONE_LONG_TEXT:
    'Verifica integrazione in seguito alla richiesta di documentazione per conformazione',
  DESKTOP_PRATICA_DETAIL_QUADRI_VERIFICA_CONFORMAZIONE_SOSPENSIONE_SELECT_LABEL:
    'Esito verifica integrazione',
  TASK_NEXT_BUTTON_REVOCA_SOSPENSIONE_DESC:
    'Revoca la sospensione alla pratica',
  DESKTOP_PRATICA_DETAIL_TASK_REVOCA_SOSPENSIONE: 'Revoca sospensione',
  DESKTOP_PRATICA_DETAIL_QUADRI_REVOCA_SOSPENSIONE_TITLE:
    'Comunicazione revoca sospensione',
  DESKTOP_PRATICA_DETAIL_QUADRI_REVOCA_SOSPENSIONE_LONG_TEXT:
    'Comunicazione revoca sospensione testo ',
  DESKTOP_PRATICA_DETAIL_TASK_RICHIESTA_RIMBORSO: 'Richiedi rimborso',

  TIPO_CONTRATTO_DIPENDENTE_INDETERMINATO: 'Dipendente indeterminato',
  TIPO_CONTRATTO_DIPENDENTE_DETERMINATO: 'Dipendente determinato',
  TIPO_CONTRATTO_SOCIO: 'Socio',
  TIPO_CONTRATTO_ALTRO: 'Altro',
  NOT_SINGLE_TEXTAREA_OGGETTO_CHIUSURA_PRATICA:
    'Non è stata aggiunta nessuna nota',

  DINIEGO: 'Diniego',
  DESKTOP_PRATICA_DETAIL_READONLY_ATTIVITA: 'Attività',
  DESKTOP_PRATICA_DETAIL_READONLY_NUMERO_MEZZI: 'Numero dei mezzi',
  DESKTOP_PRATICA_DETAIL_READONLY_NUMERO_PERSONALE: 'Numero del personale',
  DESKTOP_PRATICA_DETAIL_READONLY_OGGETTO: 'Oggetto',
  TASK_NEXT_BUTTON_REVOCA_SOSPENSIONE: 'Revoca la sospensione',
  TASK_NEXT_BUTTON_PRATICA_NEGATIVA_DESC:
    'Invia comunicazione di pratica chiusa con esito negativo',
  TASK_NEXT_BUTTON_PRATICA_NEGATIVA: 'Pratica respinta',

  NOT_SINGLE_TEXTAREA_OGGETTO_ISCRIZIONE_REGISTRO_TITLE:
    'Non è stata inserita nessuna nota',
  TASK_NEXT_BUTTON_VERIFICA_ATTIVITA_DESC:
    'Verifica della sussistenza di requisiti e casistiche particolari ',
  TASK_NEXT_BUTTON_VERIFICA_ATTIVITA: 'Verifica attività',
  DESKTOP_BPM_STEP_URL_PAGAMENTO: 'pagamenti',
  DESKTOP_PRATICA_DETAIL_QUADRI_VERIFICA_CASI_PARTICOLARI_TITLE:
    'Verifica casistiche particolari',
  DESKTOP_PRATICA_DETAIL_QUADRI_VERIFICA_CASI_PARTICOLARI_LONG_TEXT:
    'Selezione di eventuale appartenenza della società/ditta ad una delle casistiche particolari:',
  DESKTOP_PRATICA_DETAIL_QUADRI_VERIFICA_CASI_PARTICOLARI_SELECT_LABEL:
    'Verifica casistiche particolari',
  DESKTOP_PRATICA_DETAIL_TASK_VERIFICA_ATTIVITA: 'Verifica attività',
  TASK_NEXT_BUTTON_DESC_COMUNICAZIONE_RICH:
    'Invio comunicazione al Richiedente',
  TASK_NEXT_BUTTON_COMUNICAZIONE_RICH: 'Invio comunicazione',
  DESKTOP_PRATICA_DETAIL_TASK_COMUNICAZIONE_RICH:
    'Comunicazione al richiedente',
  DESKTOP_PRATICA_DETAIL_QUADRI_COMUNICAZIONE_RICHIEDENTE_TITLE:
    'Invia comunicazione al richiedente',
  DESKTOP_PRATICA_DETAIL_QUADRI_COMUNICAZIONE_RICHIEDENTE_LONG_TEXT:
    'Avendo accertato che si intende effettuare un attività rientrante nelle casistiche particolari si invia comunicazione al richiedente informandolo dei vincoli da rispettare',

  TASK_NEXT_BUTTON_PRATICA_POSITIVA_DESC:
    'Comunicazione finale per chiusura con esito positivo',
  TASK_NEXT_BUTTON_PRATICA_POSITIVA: 'Esito positivo',
  DESKTOP_PRATICA_DETAIL_QUADRI_ISCRIZIONE_REGISTRO_TITLE:
    'Iscrizione al registro delle imprese',
  DESKTOP_PRATICA_DETAIL_QUADRI_ISCRIZIONE_REGISTRO_LONG_TEXT:
    'Nota aggiuntiva ',
  DESKTOP_PRATICA_DETAIL_TASK_PRATICA_POSITIVA:
    'Pratica conclusa positivamente',
  DESKTOP_DETAIL_INFO_CHIUSURA_POSITIVA_SCIA_TITLE:
    'Pratica chiusa con esito positivo',
  DETAIL_INFO_CHIUSURA_POSITIVA_SCIA:
    'La pratica rispetta tutti gli standard ed è stato chiusa con esito positivo',
  TIPO_null: '---',
  DESKTOP_PRATICHE_REFERENTE_TITLE: 'Dati referente',
  DESKTOP_PRATICHE_FORM_INPUT_DELIVER_DATE: 'Data consegna',
  DESKTOP_PRATICHE_FORM_INPUT_DELIVER_DATE_PLACEHOLDER:
    'Inserisci data consegna',
  DESKTOP_PRATICA_DETAIL_QUADRI_CONSEGNA_BREVI_MANU_TITLE:
    'Consegna brevi manu',
  DESKTOP_PRATICA_DETAIL_READONLY_CF_REFERENTE: 'Codice fiscale',
  DESKTOP_PRATICA_DETAIL_READONLY_COGNOME_REFERENTE: 'Cognome referente',
  DESKTOP_PRATICA_DETAIL_READONLY_DATA_CONSEGNA: 'Data Consegna',
  DESKTOP_PRATICA_DETAIL_READONLY_NOME_REFERENTE: 'Nome referente',
  DESKTOP_PRATICA_DETAIL_QUADRI_CONSEGNA_BREVI_MANU_DOC_TITLE:
    'Documenti consegna brevi manu',
  DESKTOP_PRATICA_DETAIL_QUADRI_CONSEGNA_BREVI_MANU_DOC_LONG_TEXT:
    'Documenti consegna brevi manu',

  DESKTOP_PRATICA_DETAIL_QUADRI_UPLOAD_ALLEGATI_CAUZIONE_TITLE : 'Documenti Cauzione',
  DESKTOP_PRATICA_DETAIL_QUADRI_UPLOAD_ALLEGATI_P1_TITLE : 'Documenti P1',
  DESKTOP_PRATICA_DETAIL_QUADRI_UPLOAD_ALLEGATI_P2_TITLE : 'Documenti P2',
  DESKTOP_PRATICA_DETAIL_QUADRI_UPLOAD_ALLEGATI_PRIVACY_TITLE : 'Documenti Privacy',
  DESKTOP_PRATICA_DETAIL_QUADRI_UPLOAD_ALLEGATI_ANTIMAFIA_TITLE : 'Documenti Antimafia',
  SINGLE_SELECT_ELENCO_CASI_CAT06_FORM_LABEL : 'Seleziona il tipo di concessione',
  SINGLE_SELECT_ELENCO_CASI_CAT06_LONG_TEXT : 'Seleziona il tipo di concessione demaniale',
  SINGLE_SELECT_ELENCO_CASI_CAT06_TITLE : 'Seleziona il tipo di concessione',
  DESKTOP_PRATICA_DETAIL_QUADRI_UPLOAD_ALLEGATI_D1_TITLE : 'Documenti D1',
  DESKTOP_PRATICA_DETAIL_QUADRI_UPLOAD_ALLEGATI_D1_XML_TITLE : 'XML del documento D1',
  DESKTOP_PRATICA_DETAIL_QUADRI_UPLOAD_ALLEGATI_ATTO_NOTORIETA_TITLE : 'Documenti Atto di notorietà',
  DESKTOP_PRATICA_DETAIL_QUADRI_UPLOAD_ALLEGATI_BUSINESS_PLAN_TITLE : 'Documenti Business Plan',
  DESKTOP_PRATICA_DETAIL_QUADRI_UPLOAD_ALLEGATI_PIANO_OPERATIVO_TITLE : 'Documenti Piano Operativo',
  DESKTOP_PRATICA_DETAIL_QUADRI_UPLOAD_ALLEGATI_PIANO_ATTIVITA_TITLE : 'Documenti Piano Attività',
  DESKTOP_PRATICA_DETAIL_QUADRI_UPLOAD_ALLEGATI_POSSESSO_ATTREZZATURE_TITLE : 'Documenti attestanti il Possesso di Attrezzature',
  DESKTOP_PRATICA_DETAIL_QUADRI_UPLOAD_ALLEGATI_POSSESSO_DIPENDENZE_TITLE : 'Documenti attestanti il Possesso di Dipendenze',
  DESKTOP_PRATICA_DETAIL_QUADRI_UPLOAD_ALLEGATI_CAPACITA_FINANZIARIA_TITLE : 'Documenti Capacità Finanziaria',
  DESKTOP_PRATICA_DETAIL_QUADRI_UPLOAD_ALLEGATI_CONTRATTO_ASSICURATIVO_TITLE : 'Contratto Assicurativo',
  DESKTOP_PRATICA_DETAIL_QUADRI_UPLOAD_ALLEGATI_COMUNICAZIONE_ACCETT_RESP_TITLE : 'Comunicazione Accettazione Responsabile',
  DESKTOP_PRATICA_DETAIL_TASK_ACCETTAZIONE_RESP : 'Comunicazione Accettazione Responsabile',
  TASK_NEXT_BUTTON_VERIFICA_COMPLETEZZA_DOC_DESC : 'Verifica Completezza Documentazione',
  TASK_NEXT_BUTTON_VERIFICA_COMPLETEZZA_DOC : 'Verifica Documentazione',
  DESKTOP_PRATICA_DETAIL_QUADRI_UPLOAD_ALLEGATI_INTEGRAZIONE_2_TITLE : 'Integrazione',
  DESKTOP_PRATICA_DETAIL_QUADRI_UPLOAD_ALLEGATI_INTEGRAZIONE_TITLE : 'Integrazione',
  TASK_NEXT_BUTTON_LAVORI_COMIT_GESTIONE_DESC : 'Lavori Comitato di Gestione',
  TASK_NEXT_BUTTON_LAVORI_COMIT_GESTIONE : 'Lavori Comitato di Gestione',
  DESKTOP_PRATICA_DETAIL_QUADRI_UPLOAD_ALLEGATI_INTEGRAZIONE_3_TITLE : 'Integrazione',
  DESKTOP_PRATICA_DETAIL_QUADRI_UPLOAD_ALLEGATI_INTEGRAZIONE_4_TITLE : 'Integrazione',
  TASK_NEXT_BUTTON_RICHIESTA_RIMBORSO_DESC : 'Richiesta di Rimborso',
  TASK_NEXT_BUTTON_RICHIESTA_RIMBORSO : 'Richiesta di Rimborso',
  TASK_NEXT_BUTTON_RICHIESTA_PAGAMENTO_DESC : 'Richiesta di Pagamento',
  TASK_NEXT_BUTTON_RICHIESTA_PAGAMENTO : 'Richiesta di Pagamento',
  TASK_NEXT_BUTTON_COMUNICA_DINIEGO_DESC : 'Comuncazione Diniego',
  TASK_NEXT_BUTTON_COMUNICA_DINIEGO : 'Comuncazione Diniego'
};
