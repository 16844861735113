import { APP_BASE_HREF } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CodiceVerticaleResolver } from './core/services/codiceVerticale.resolver';
import { EmptyRouteComponent } from './shared/components';

const routes: Routes = [
  {
    path: 'sua',
    resolve: { codiceVerticale: CodiceVerticaleResolver },
    loadChildren: () =>
      import('./pages/pages.module').then((m) => m.PagesModule),
  },
  { path: '**', component: EmptyRouteComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes,  {scrollPositionRestoration: 'top'})],
  exports: [RouterModule],
  providers: [{ provide: APP_BASE_HREF, useValue: '' }],
})
export class AppRoutingModule {}
