import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiClient } from '@eng-ds/api-client';
import {Observable, of} from 'rxjs';
import { SelectOption } from 'src/app/shared/form';
import { Ambiti } from '../models/ambiti';

@Injectable()
export class UtilityService {
  constructor(private apiClient: ApiClient, private httpClient: HttpClient) {}

  getNazioni(): Observable<SelectOption<string, string>[]> {
    return this.apiClient.request<SelectOption<string, string>[]>('getNazioni');
  }
  getProvince(uuid: string): Observable<SelectOption<string, string>[]> {
    return uuid
      ? this.apiClient.request<SelectOption<string, string>[]>(
          'getProvince',
          null,
          { uuid },
          null
        )
      : of([]);
  }
  getComuni(uuid: string): Observable<SelectOption<string, string>[]> {
    return uuid
      ? this.apiClient.request<SelectOption<string, string>[]>(
          'getComuni',
          null,
          null,
          { uuid }
        )
      : of([]);
  }
  getAmbiti(uuid: string): Observable<Ambiti[]> {
    return this.apiClient.request('getAmbiti', null, null, { uuid });
  }
}
