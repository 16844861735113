import { ApiConfig, BackendConfig } from '@eng-ds/api-client';

declare const Adsp: any;

const apiScrivaniaPath = '/apiscrivania/1.0';
const apiGeneralPath = '/apigeneral/1.0';
const apiMguPath = '/apimgu/1.0';

const mockupUrl = 'http://localhost:3000';
const backendApi: ApiConfig[] = [
  {
    name: 'getScadenze',
    method: 'GET',
    url: apiScrivaniaPath + '/agenda/list',
  },
  {
    name: 'getNotifications',
    method: 'GET',
    url: apiScrivaniaPath + '/agenda/{uuid}/listPromemoria',
  },
  {
    name: 'addNotifications',
    method: 'POST',
    url: apiScrivaniaPath + '/agenda/{uuid}/addPromemoria',
  },
  {
    name: 'getNotifiche',
    method: 'GET',
    url: apiScrivaniaPath + '/notifica/list',
  },
  {
    name: 'archiviaNotifica',
    method: 'PATCH',
    url: apiScrivaniaPath + '/notifica/{uuid}/archivia',
  },
  {
    name: 'leggiNotifica',
    method: 'PATCH',
    url: apiScrivaniaPath + '/notifica/{uuid}/leggi',
  },
  {
    name: 'eliminaNotifica',
    method: 'PATCH',
    url: apiScrivaniaPath + '/notifica/{uuid}/elimina',
  },
  {
    name: 'getTranslate',
    method: 'GET',
    url:
      Adsp.configs.get('appHost') +
      '/general_lingua/{languageCode}/{module}.json',
  },
  {
    name: 'getNazioni',
    method: 'GET',
    url: apiGeneralPath + '/config/nazioni',
  },
  {
    name: 'getListaDotazioniTecnichePratica',
    method: 'GET',
    url: apiScrivaniaPath + '/dotazionetecnica/{uuidPratica}/list',
  },
  {
    name: 'detailDotazioneTecnicaPratica',
    method: 'GET',
    url:
      apiScrivaniaPath + '/dotazionetecnica/{uuidPratica}/associazione/{uuid}',
  },
  {
    name: 'getProvince',
    method: 'GET',
    url: apiGeneralPath + '/config/province',
  },
  {
    name: 'getComuni',
    method: 'GET',
    url: apiGeneralPath + '/config/comuni/{uuid}',
  },
  {
    name: 'creaDelega',
    method: 'POST',
    url: apiScrivaniaPath + '/deleghe',
  },
  {
    name: 'getListDeleghe',
    method: 'GET',
    url: apiScrivaniaPath + '/deleghe/list',
  },
  {
    name: 'exportListDeleghe',
    method: 'GET',
    url: apiScrivaniaPath + '/deleghe/export',
  },
  {
    name: 'getDetailDelega',
    method: 'GET',
    url: apiScrivaniaPath + '/deleghe/{uuid}',
  },
  {
    name: 'getStatiDelega',
    method: 'GET',
    url: apiMguPath + '/config/staticList/statoDelega',
  },
  {
    name: 'getProcedimentiByDelega',
    method: 'GET',
    url: apiScrivaniaPath + '/deleghe/{uuid}/procedimenti',
  },
  {
    name: 'getProcedimentiAssociabili',
    method: 'GET',
    url: apiScrivaniaPath + '/deleghe/{uuid}/procedimenti/associa/{idLingua}',
  },
  {
    name: 'associaProcedimenti',
    method: 'POST',
    url: apiScrivaniaPath + '/deleghe/{uuid}/procedimenti/associa',
  },
  {
    name: 'getAllegatiDelega',
    method: 'GET',
    url: apiScrivaniaPath + '/deleghe/{uuid}/documenti',
  },
  {
    name: 'deleteAllegatoDelega',
    method: 'DELETE',
    url: apiScrivaniaPath + '/deleghe/{uuidDelega}/documenti/{uuidDocumento}',
  },
  {
    name: 'uploadDocumentDelega',
    method: 'POST',
    url: apiScrivaniaPath + '/deleghe/{uuidDelega}/documenti',
  },
  {
    name: 'downloadDelegaDocument',
    method: 'GET',
    url: apiScrivaniaPath + '/deleghe/{uuidDelega}/documenti/{uuidDocumento}',
  },
  {
    name: 'createDelega',
    method: 'POST',
    url: apiScrivaniaPath + '/deleghe',
  },
  {
    name: 'updateDelega',
    method: 'PATCH',
    url: apiScrivaniaPath + '/deleghe/{uuid}',
  },
  {
    name: 'searchPhisicalApplicantByFiscalCode',
    method: 'GET',
    url: apiScrivaniaPath + '/soggetto/fisico/{codiceFiscale}',
  },
  {
    name: 'searchLegalApplicantByFiscalCode',
    method: 'GET',
    url: apiScrivaniaPath + '/soggetto/giuridico/cf/{codiceFiscale}',
  },
  {
    name: 'searchLegalApplicantByPIVA',
    method: 'GET',
    url: apiScrivaniaPath + '/soggetto/giuridico/piva/{partitaIva}',
  },
  {
    name: 'getCategorieProcedimenti',
    method: 'GET',
    url: apiScrivaniaPath + '/deleghe/categorieprocedimenti/{codiceLingua}',
  },
  {
    name: 'getStatoPratica',
    method: 'GET',
    url: apiScrivaniaPath + '/pratica/{uuid}/statopratica',
  },
  {
    name: 'getPratiche',
    method: 'GET',
    url: apiScrivaniaPath + '/pratica/list',
  },
  {
    name: 'getPraticaDetail',
    method: 'GET',
    url: apiScrivaniaPath + '/pratica/{uuid}',
  },
  {
    name: 'getDelegantePratica',
    method: 'GET',
    url: apiScrivaniaPath + '/pratica/{uuid}/delegante',
  },
  {
    name: 'getFasiPratica',
    method: 'GET',
    url: apiScrivaniaPath + '/config/staticList/fasePratica',
  },
  {
    name: 'getTipiRichiedente',
    method: 'GET',
    url: apiScrivaniaPath + '/config/staticList/tipoRichiedente',
  },
  {
    name: 'getNextScadenza',
    method: 'GET',
    url: apiScrivaniaPath + '/agenda/next',
  },
  {
    name: 'getStatiNotifica',
    method: 'GET',
    url: apiScrivaniaPath + '/config/staticList/statoNotifica',
  },
  {
    name: 'getListDipendenti',
    method: 'GET',
    url: apiScrivaniaPath + '/personale/{uuidPratica}/list',
  },
  {
    name: 'getDipendente',
    method: 'GET',
    url: apiScrivaniaPath + '/personale/{uuid}',
  },
  {
    name: 'getTipologieNotifica',
    method: 'GET',
    url: apiScrivaniaPath + '/config/staticList/tipoNotifica',
  },
  {
    name: 'exportPratiche',
    method: 'GET',
    url: apiScrivaniaPath + '/pratica/export',
  },
  {
    name: 'getNextTask',
    method: 'GET',
    url: apiScrivaniaPath + '/praticaBPM/nexttask/{uuid}',
  },
  {
    name: 'getDelegatiAssociati',
    method: 'GET',
    url: apiScrivaniaPath + '/pratica/{uuid}/utentiruoli',
  },
  {
    name: 'getRichiedentePratica',
    method: 'GET',
    url: apiScrivaniaPath + '/pratica/{uuid}/richiedente',
  },
  {
    name: 'getDocumentiPratica',
    method: 'GET',
    url: apiScrivaniaPath + '/documenti/{uuid}/allegatiByRuolo',
  },
  {
    name: 'getPagamenti',
    method: 'GET',
    url: apiScrivaniaPath + '/pagamento/list',
  },
  {
    name: 'downloadPraticaDocument',
    method: 'GET',
    url: apiScrivaniaPath + '/documenti/{uuid}/download/{uuidDownload}',
  },
  {
    name: 'getUtentiPratica',
    method: 'GET',
    url: apiScrivaniaPath + '/pratica/{uuid}/utentiruoli',
  },
  {
    name: 'associaUtentiPratica',
    method: 'POST',
    url: apiScrivaniaPath + '/pratica/{uuid}/associaUtenti',
  },
  {
    name: 'getUtentiPraticaByFunzione',
    method: 'GET',
    url: apiScrivaniaPath + '/pratica/{uuid}/associaUtenti',
  },
  {
    name: 'getProssimaScadenzaByPratica',
    method: 'GET',
    url: apiScrivaniaPath + '/pratica/{uuid}/nextScadenzeUtente',
  },
  {
    name: 'downloadPagamento',
    method: 'GET',
    url: apiScrivaniaPath + '/pagamenti/download',
  },
  {
    name: 'getDocumenti',
    method: 'GET',
    url: apiScrivaniaPath + '/documenti/utente',
  },
  {
    name: 'downloadDocumento',
    method: 'GET',
    url: apiScrivaniaPath + '/documenti/{uuidPratica}/download/{uuidDocumento}',
  },
  {
    name: 'getStatiPagamento',
    method: 'GET',
    url: apiScrivaniaPath + '/config/staticList/statoPagamento',
  },
  {
    name: 'getTipologiaPagamento',
    method: 'GET',
    url: apiScrivaniaPath + '/pagamenti/tipo',
  },

  /* api momentanee per dettaglio pratica accrdion */
  {
    name: 'getRichiestaAccesso',
    method: 'GET',
    url: apiScrivaniaPath + '/pratica/{uuidPratica}/richiestaAccesso',
  },
  {
    name: 'getDocumentByPratica',
    method: 'GET',
    url:
      // apiScrivaniaPath +
      apiScrivaniaPath + '/documenti/{uuidPratica}/allegati',
  },
  {
    name: 'scaricaDocumentoPratica',
    method: 'GET',
    url: apiScrivaniaPath + '/documenti/{uuidPratica}/download/{uuidDocumento}',
  },
  {
    name: 'getUfficiCompetenti',
    method: 'GET',
    url:
      // mockupUrl +
      apiScrivaniaPath + '/pratica/{uuidPratica}/ufficiCompetenti',
  },
  {
    name: 'getQuadriDataInfo',
    method: 'GET',
    url:
      // mockupUrl +
      apiScrivaniaPath +
      '/praticaBPM/stepdatainfo/{uuidPratica}/{uuidProcTask}/{uuidProcTaskStep}',
  },
  {
    name: 'updateAssociateUser',
    method: 'PATCH',
    url:
      apiScrivaniaPath +
      '/pratica/{uuidPratica}/updateUtente/{uuidAssociazione}',
  },
  {
    name: 'updateDelegaProcedimento',
    method: 'PATCH',
    url:
      apiScrivaniaPath +
      '/deleghe/{uuidDelega}/procedimenti/associa/{uuidProcedimentoDelega}',
  },
  {
    name: 'getTaskDetail',
    method: 'GET',
    url:
      // mockupUrl +
      apiScrivaniaPath + '/praticaBPM/getTaskDetail/{uuidPratica}',
  },
  {
    name: 'quadriDataValues',
    method: 'GET',
    url:
      // mockupUrl +
      apiScrivaniaPath +
      '/praticaBPM/getDataDetail/{uuidPratica}/{uuidProcTask}',
  },
  {
    name: 'getUfficiCompetenti',
    method: 'GET',
    url:
      // mockupUrl +
      apiScrivaniaPath + '/pratica/{uuidPratica}/ufficiCompetenti',
  },
  {
    name: 'getRichiedenteByPratica',
    method: 'GET',
    url:
      // mockupUrl +
      apiScrivaniaPath + '/pratica/{uuidPratica}/richiedente',
  },
  {
    name: 'getDeleganteByPratica',
    method: 'GET',
    url:
      // mockupUrl +
      apiScrivaniaPath + '/pratica/{uuidPratica}/delegante',
  },
  {
    name: 'getDocumentByPratica',
    method: 'GET',
    url: apiScrivaniaPath + '/documenti/{uuidPratica}/allegati',
  },
  {
    name: 'getComunicazioniAccessoUORD',
    method: 'GET',
    url: apiScrivaniaPath + '/pratica/{uuid}/comunicazioniAccessoAUORD',
  },
  {
    name: 'getRegistroAccessi',
    method: 'GET',
    url: apiScrivaniaPath + '/registroaccessi/pratica/{uuidPratica}',
  },
  {
    name: 'getComunicazioneAccessoDettaglio',
    method: 'GET',
    url:
      apiScrivaniaPath +
      '/pratica/{uuidPratica}/comunicazioneAccesso/{uuidComAccesso}',
  },
  {
    name: 'getComunicazioniAccessoDaUfficioRichiedente',
    method: 'GET',
    url: apiScrivaniaPath + '/pratica/{uuid}/comunicazioniAccessoDaUfficio',
  },
  {
    name: 'getTipologieDelega',
    method: 'GET',
    url: apiScrivaniaPath + '/config/staticList/tipoDelega',
  },
  {
    name: 'listComunicazioniTrasversali',
    method: 'GET',
    url: apiScrivaniaPath + '/comunicazionitrasversali/list',
  },
  {
    name: 'getComunicazioneTrasversaleDettaglio',
    method: 'GET',
    url: apiScrivaniaPath + '/comunicazionitrasversali/{uuid}',
  },
  {
    name: 'getInfoPraticaRiesame',
    method: 'GET',
    url: apiScrivaniaPath + '/pratica/{uuidPratica}/infoPraticaRiesame',
  },
  {
    name: 'getAmbiti',
    method: 'GET',
    url: apiScrivaniaPath + '/ambito/{uuid}',
  },
  {
    name: 'getListAttivitaAssociate',
    method: 'GET',
    url: apiScrivaniaPath + '/ambito/ambitiPraticaDettaglio/{uuidPratica}',
  },
  {
    name: 'getListAutodichiarazioniAssociate',
    method: 'GET',
    url:
      apiScrivaniaPath + '/dichiarazioni/getDichiarazioniPratica/{uuidPratica}',
  },
  {
    name: 'listRichiesteParereInviate',
    method: 'GET',
    url:
      apiScrivaniaPath +
      '/comunicazioni/entiEsterni/{uuidPratica}/{uuidTask}/list',
  },
  {
    name: 'detailComuEntiEsterni',
    method: 'GET',
    url:
      apiScrivaniaPath + '/comunicazioni/entiEsterni/{uuidPratica}/{uuidCom}',
  },
  {
    name: 'listTipoElencoValori',
    method: 'GET',
    url: apiScrivaniaPath + '/config/staticList/tipoElencoValori',
  },
];

export const backendConfig: Omit<BackendConfig, 'getApiConfig' | 'prepareUrl'> =
  {
    baseUrl: Adsp.configs.get('baseApiUrl'),
    api: backendApi,
  };
